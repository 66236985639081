import { Component, OnInit, Input, Directive, ViewChild, OnChanges } from '@angular/core';
import { Router } from '@angular/router'
import { RouterService } from '../../services/routerService'
import { ContextService } from '../../services/contextService'
import { ConstantsService } from '../../services/constantsService'
import { TranslateService } from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'sub-card',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

export class SubCardComponent {
  @Input() title:string;
  @Input() icon:boolean=false;
  isOpen:boolean=true;
  constructor() {
   }
   close(){
     this.isOpen=false;
   }
}
