import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'page-header',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class PageHeader {
  @ViewChild('PageHeaderElement') inputElement: ElementRef;
  @Input() header: string;
 
  constructor() {}

}
