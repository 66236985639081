
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Headers, RequestOptions, Http, URLSearchParams, ResponseContentType } from '@angular/http';
import 'rxjs/Rx';

import { ContextService } from './contextService';
import * as _ from "lodash";
import { Key } from 'protractor';

// let BASE_IP = "http://localhost:3000/";
//  let BASE_IP = "https://banyantree-ruby-staging.herokuapp.com/"
// let BASE_IP = "https://banyan-prod.herokuapp.com/"
// let BASE_IP = "https://agile-wave-89560.herokuapp.com/";
// let BASE_IP = "https://sleepy-shore-74756.herokuapp.com/";
// let BASE_IP = "https://ruby-banyantree-prod.herokuapp.com/";
// Below one is latest app URL
let BASE_IP = "https://ruby-banyantree-production.herokuapp.com/";
let BASE_URL = BASE_IP + "v1/";

@Injectable()
export class HttpService {
  loader: any;
  minimumTime: any;
  beforeTime: any;
  afterTime: any;
  constructor(private http: Http, private contextService: ContextService) {
  }
  get(...args: any[]) {
    var url = args[0];
    let params: URLSearchParams = new URLSearchParams();
    var header = new Headers({ 'Authorization': this.contextService.getLoginToken() });
    var getOptions = {
      headers: header
    };
    if (typeof args[1] !== "undefined") {
      _.forOwn(args[1], function (value, key) {
        params.set(key, value);
      });
      getOptions["search"] = params;
    }
    this.minimumTime = 100;
    this.beforeTime = new Date();
    var that = this;
    return this.http.get(BASE_URL + url, getOptions).pipe(
      map(res => {
        return res.json();
      }),
      catchError(function (error) {
        return that.handleError(error);
      }));
  }
  post(url, data) {
    this.minimumTime = 100;
    this.beforeTime = new Date();
    var that = this;
    let body = JSON.stringify(data);
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.contextService.getLoginToken() });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(BASE_URL + url, body, options).pipe(
      map(res => {
        return res.json();
      }),
      catchError(function (error, result) {
        return that.handleError(error);
      }));
  }

  getV2(...args: any[]) {
    var url = args[0];
    let params: URLSearchParams = new URLSearchParams();
    var header = new Headers({ 'Authorization': this.contextService.getLoginToken() });
    var getOptions = {
      headers: header
    };
    if (typeof args[1] !== "undefined") {
      _.forOwn(args[1], function (value, key) {
        params.set(key, value);
      });
      getOptions["search"] = params;
    }
    this.minimumTime = 100;
    this.beforeTime = new Date();
    var that = this;
    return this.http.get(BASE_IP + "v2/" + url, getOptions).pipe(
      map(res => {
        return res.json();
      }),
      catchError(function (error) {
        return that.handleError(error);
      }));
  }
  postV2(url, data) {
    this.minimumTime = 100;
    this.beforeTime = new Date();
    var that = this;
    let body = JSON.stringify(data);
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': this.contextService.getLoginToken() });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(BASE_IP + "v2/" + url, body, options).pipe(
      map(res => {
        return res.json();
      }),
      catchError(function (error, result) {
        return that.handleError(error);
      }));
  }

  uploadFile(url, fileToUpload: File, metadata) {

    var formData = new FormData()
    Object.keys(metadata).forEach(Key => {
      formData.append(Key, metadata[Key])
    })
    formData.append('file', fileToUpload)

    let headers = new Headers({ 'Authorization': this.contextService.getLoginToken() });
    headers.delete('Content-Type')
    let options = new RequestOptions({ headers: headers });
    return this.http.post(BASE_URL + url, formData, options)
      .map((res) => res.json())
  }

  downloadFile(url, result: any) {

    let headers = new Headers({ 'Authorization': this.contextService.getLoginToken() });
    headers.delete('Content-Type')

    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(BASE_URL + url, result, options)
      .map(res => res.blob())
      .catch(this.handleError)

  }

  downloadFileV2(url, result: any) {

    let headers = new Headers({ 'Authorization': this.contextService.getLoginToken() });
    headers.delete('Content-Type')

    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(BASE_IP + "v2/" + url, result, options)
      .map(res => res.blob())
      .catch(this.handleError)

  }

  handleError(error) {
    var errorMessage = "";
    try {
      var errorJson = error.json();
      errorMessage = errorJson.error;
      if (typeof errorMessage === "undefined") {
        errorMessage = errorJson.error_message;
      }
    } catch (e) {
      if (error.status === 500) {
        errorMessage = "Internal Server Error. Please Contact System Administrator.";
      }
    }
    if (typeof errorMessage === "undefined") {
      errorMessage = "Connection Error.";
    }
    return observableThrowError({ errorText: errorMessage, errorCode: error.status });
  }
}
