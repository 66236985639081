import { Component, Input, ElementRef, ViewChild, AfterViewInit, OnInit, TemplateRef, OnChanges, HostListener, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { FormField } from 'app/services/formService';
import { Validators as V } from "app/services/validations";
import * as _ from "lodash";
import { ContextService } from 'app/services/contextService';
declare var $: any;

@Component({
  selector: 'table-field',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})

export class TableComponent implements AfterViewInit, OnInit {
  @ViewChild('table') table: DatatableComponent;
  @Input() tableGridData: any = {};
  @Input() label: string = "";
  @Input() searchRequired: boolean = false;
  @Input() downloadRequired: boolean = false;
  @Input() radioRequired: boolean = false;
  @Input() checkboxRequired: boolean = false;
  @Input() focusRequired: boolean = false;
  @Input() paginationRequired: boolean = true;
  @Input() selectionType: string = "none";
  @Input() totalRequired: boolean = false;
  @Output() rowClickHandler = new EventEmitter<any>();
  @Output() percentage = new EventEmitter<any>();
  @Output() downloadClickHandler = new EventEmitter<any>();
  @Output() inputValueChanged = new EventEmitter<any>();
  buttonClickHandle: ReplaySubject<any> = new ReplaySubject(1);
  rowChangedHandle: BehaviorSubject<any> = new BehaviorSubject({});
  tableRowData: any[];
  tableHeaders = [];
  inputform: FormField = new FormField();
  rowCountValue: FormField = new FormField();
  tableMetaData: any = {};
  tableFilterData: any[];
  selected = [];
  pageList: Array<any> = [];
  goToPageNumber: number = 1;
  public recordsPerPage: number = 0;
  randomNumber: number = 0;
  target: any = "";
  rows = [];
  columns = [];
  selectedRow: number = -1;
  observer: any;
  isMutationChange: boolean = false;
  masterCheckbox: FormField = new FormField(false);
  changeRequired: boolean = true;
  // totalRequired: boolean = true;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,private contextService: ContextService) {
    this.randomNumber = Math.floor(Math.random() * 10000);
    this.masterCheckbox.reset();
    this.masterCheckbox.value = false;
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      var isResizeRequired = false;
      for (var i = 0; i < mutations.length; i++) {
        if (mutations[i].attributeName === 'class')
          isResizeRequired = true;
        break;
      }
      if (isResizeRequired) {
        this.isMutationChange = true;
        this.formTable();
      }
    });
    this.observer.observe(document.body, { attributes: true });

    this.table.sort.subscribe(data => {
      // console.log("hiiii")
      if (this.table.rows.length > 0) {
        // console.log(this.table._internalRows[0])
        this.selectedRow = this.table._internalRows[0].$$index;
        this.table.selected[0] = this.table._internalRows[0];
        this.rowChangedHandle.next(this.table._internalRows[0]);
        this.goToPageNumber = 1;
      }
    });

    this.masterCheckbox.valueChangeListener().subscribe(values => {
      if (values.oldValue !== values.newValue && this.changeRequired) {
        for (var i = 0; i < this.tableRowData.length; i++) {
          for (var j = 0; j < this.tableHeaders.length; j++) {
            var header = this.tableHeaders[j].name;
            var headerIndex = this.tableHeaders[j].index;
            var headerType = this.tableHeaders[j].type;
            if (headerType == "checkbox") {
              // console.log(values)
              this.tableRowData[i][header] = values.newValue;
              this.tableGridData.data[i][headerIndex] = values.newValue;
              this.updateTable();
            }
          }
        }
      }
    })
  }

  onSort(event){
    // console.log(event,"sorting");
  }

  updateRow(){
    this.recordsPerPage = this.rowCountValue.value;
  }

  ngOnInit() {
    this.formTable();
  }

  getInternalRows(){
    return this.table._internalRows
  }

  formTable(isFormingTableHeaderRequired = true) {
    if (!this.isMutationChange) {
      this.goToPageNumber = 1;
    }
    this.tableMetaData = this.tableGridData["metaData"];
    this.formingTableHeaderData(isFormingTableHeaderRequired, this.tableMetaData, function (that) {
      that.recordsPerPage = parseInt(that.tableMetaData['numberOfRecordPerPage']);
      that.updateRows();
    });
  }


  public updateTable() {
    this.formingTableHeaderData(true, this.tableMetaData, function (that) {
      that.recordsPerPage = parseInt(that.tableMetaData['numberOfRecordPerPage']);
    });
  }

 
  public updateRows() {
    // console.log(this.tableGridData)
    var data = this.formingTableRowData(this.tableGridData);
    this.tableRowData = data;
    this.table.rows = data;
    this.tableFilterData = [...data];
    this.table.recalculate();
    // this.updateFilter();
  }

  // getRowClass() { 
  //   console.log(this.tableRowData) 
  //   return 'row-color1'
    
  //  }

  private formingTableHeaderData(isFormingTableHeaderRequired, dataObject, callback) {
    if (isFormingTableHeaderRequired) {
      var tempHeaders = [];
      if (this.tableHeaders.length > 0) {
        tempHeaders = this.tableHeaders;
      }
      this.tableHeaders = [];
      for (var i = 0; i < dataObject.column_names.length; i++) {
        var searchValue = "";
        if (tempHeaders.length > 0) {
          if (this.isMutationChange && typeof tempHeaders[i] !== "undefined" && typeof tempHeaders[i].searchValue !== "undefined") {
            searchValue = tempHeaders[i].searchValue;
          }
        }
        let header = {
          index: i,
          name: dataObject.column_names[i].replace(/ /g, '').toLowerCase(),
          text: dataObject.column_names[i],
          width: dataObject.column_widths[i],
          type: typeof dataObject.column_types !== "undefined" ? dataObject.column_types[i] : "text",
          search: typeof dataObject.column_search !== "undefined" ? dataObject.column_search[i] : 0,
          hidden: typeof dataObject.column_hidden !== "undefined" ? dataObject.column_hidden[i] : 0,
          sort: typeof dataObject.column_sort !== "undefined" ? dataObject.column_sort[i] : false,
          searchValue: searchValue
        }
        this.tableHeaders.push(header);
      }
      callback(this);
    } else {
      var tableHeaders = _.cloneDeep(this.tableHeaders);
      this.tableHeaders = []
      setTimeout(() => {
        this.isMutationChange = true;
        this.tableHeaders = tableHeaders;
        callback(this);
      });
    }
  }

  isinputTypeText(th) {
    if (th.type == 'text') {
      return true
    }
  }

  isinputTypeEdit(th) {
    if (th.type == 'edit') {
      return true
    }
  }

  getColumnWidth(colName) {
    let colWidth = 0;
    if (colName.indexOf("name") !== -1 || colName.indexOf("description") !== -1) {
      colWidth = 180;
    } else if (colName.indexOf("sl") !== -1 || colName.indexOf("Serail") !== -1) {
      colWidth = 60;
    } else {
      colWidth = 90;
    }
    return colWidth;
  }

  getColumnType(colType) {
    return 'text';
  }

  isSearchRequired(tableHeader) {
    if (!V.isEmpty(tableHeader.search) && typeof tableHeader.search === "boolean") {
      return tableHeader.search;
    } else {
      return false;
    }
  }

  updatevalue(event, tableHeader, index ,row) {
    // console.log(event, tableHeader, index,row);
    // console.log(this.tableRowData);
    // console.log(this.tableRowData.findIndex(x => x['$$index'] == row['$$index']));
    // console.log(this.tableGridData.data);
    var actual_row_index = this.tableRowData.findIndex(x => x['$$index'] == row['$$index']);
    if(actual_row_index != -1){
      this.tableRowData[actual_row_index][tableHeader.name] = event.target.value;
      this.tableGridData.data[this.tableRowData[actual_row_index].$$index][tableHeader.index] = this.tableRowData[index][tableHeader.name]
      // this.tableGridData.data[index][tableHeader.index] = this.tableRowData[index][tableHeader.name]
      var data = this.tableGridData.data[actual_row_index]
      var Total = 0
      var grade = ""
      var screenData = this.contextService.getFieldObjectData()
      var classIndex = screenData.findIndex(x => x.hasOwnProperty('classroom'))
      var academicIndex = screenData.findIndex(x => x.hasOwnProperty('academicYear'))
      var subjectIndex = screenData.findIndex(x => x.hasOwnProperty('subject'))
      if(this.totalRequired && classIndex != -1 && academicIndex != -1 && subjectIndex != -1){

        for(var i=0;i<screenData[subjectIndex].subjectList.length;i++){
          if(String(screenData[subjectIndex].subjectList[i].value) == String(screenData[subjectIndex].subject)){
            var subject_type_id = screenData[subjectIndex].subjectList[i].subject_type_id
          }
        }

        for(var l=0;l<screenData[classIndex].classroomList.length;l++){
          if(String(screenData[classIndex].classroom) == String(screenData[classIndex].classroomList[l].value)){
            var level_id = screenData[classIndex].classroomList[l].level_id
          }
        }

        var academicYear = screenData[academicIndex].academicYear
        for(var i=0;i<data.length;i++){
          if(i>1 && i != data.length - 2){
            if(data[i] != null && data[i] != "" && (/^[0-9]+$/.test(data[i].trim().replace(/ /g, '')))){
              Total = Total + Number(data[i])
            }
          }
        }
        if(academicYear == "2019-2020" && subject_type_id == 5 && (/^[0-7]$/).test(level_id)){
          if(Total <= 40 && Total>=36){
            grade = "A1"
          }else if(Total <=35 && Total >=32){
            grade = "A2"
          }else if(Total <=31 && Total >=28){
            grade = "B1"
          }else if(Total <=27 && Total >=24){
            grade = "B2"
          }else if(Total <=23 && Total >=20){
            grade = "C1"
          }else if(Total <=19 && Total >=16){
            grade = "C2"
          }else if(Total <=15 && Total >=12){
            grade = "D"
          }else if(Total <=11 && Total >0){
            grade = "E"
          }
        }else{
          if(Total <= 100 && Total>=91){
            grade = "A1"
          }else if(Total <=90 && Total >=81){
            grade = "A2"
          }else if(Total <=80 && Total >=71){
            grade = "B1"
          }else if(Total <=70 && Total >=61){
            grade = "B2"
          }else if(Total <=60 && Total >=51){
            grade = "C1"
          }else if(Total <=50 && Total >=41){
            grade = "C2"
          }else if(Total <=40 && Total >=33){
            grade = "D"
          }else if(Total <=32 && Total >0){
            grade = "E"
          }
        }
        if(Total == 0){
          this.tableGridData.data[actual_row_index][data.length - 2] = ""
          this.tableGridData.data[actual_row_index][data.length - 1] = grade
        }else if(Total != 0){
          this.tableGridData.data[actual_row_index][data.length - 2] = String(Total)
          this.tableGridData.data[actual_row_index][data.length - 1] = grade
        }
      }
    }
    this.inputValueChanged.emit(actual_row_index)
    // this.updateRows()
  }



  gettableRowData() {
    return this.tableRowData;
  }

  getTableGirdData(){
    return this.tableGridData.data
  }

  isinputTypeEditDisable(th) {
    if (th.type == 'disableEdit') {
      return true
    }
  }
  private formingTableRowData(dataObject) {
    let rowData = [];

    for (var i = 0; i < dataObject.data.length; i++) {
      let rowDataObj = {};
      for (var j = 0; j < this.tableHeaders.length; j++) {
        var header = this.tableHeaders[j].name;
        rowDataObj[header] = dataObject.data[i][j];
      }
      rowDataObj["$$index"] = i;
      rowData.push(rowDataObj);
    }
    if (dataObject.data.length > 0) {
      this.formPageList(dataObject.data.length);
    }
    return rowData;

  }

  formPageList(totalRecords) {
    var pageList = [];
    var incrementPageCount = (totalRecords % this.recordsPerPage) > 0 ? 1 : 0;
    var pages = parseInt((totalRecords / this.recordsPerPage).toString()) + incrementPageCount
    for (var i = 1; i <= pages; i++) {
      let page = {
        name: i.toString(),
        value: i.toString()
      };
      pageList.push(page);
    }
    this.pageList = pageList;
  }

  private updateFilter() {
    let tempData = this.tableFilterData;
    for (var i = 0; i < this.tableHeaders.length; i++) {
      var searchValue = (this.tableHeaders[i]["searchValue"]).toString().toLowerCase();
      
        // console.log("I am filtering")
        // console.log(tempData)
        let that = this;
        var headerName = this.tableHeaders[i]["name"].toLowerCase();
        tempData = tempData.filter(function (result) {
          // return (result[headerName]).toString().toLowerCase().indexOf(searchValue) !== -1 || !searchValue;
          return ((new RegExp(`^${searchValue}`, 'gm')).test((result[headerName]).toString().toLowerCase())) !== false || !searchValue;
        })

      this.tableRowData = tempData;
      this.table.rows = tempData;
      // this.tableGridData.data = tempData;
      // this.updateRows()
      // this.updateTable()
    }

    var incrementPageCount = ((this.tableRowData.length) % this.recordsPerPage) > 0 ? 1 : 0;
    var page = parseInt(((this.tableRowData.length) / this.recordsPerPage).toString()) + incrementPageCount;
    let pageEvent = {};
    if (typeof this.goToPageNumber === "undefined" || this.goToPageNumber > page) {
      pageEvent["page"] = 1;
    } else {
      pageEvent["page"] = this.goToPageNumber;
    }
    this.onFooterPage(pageEvent);
  }

  getSelectedRow() {
    return this.selectedRow;
  }

  handleButtonClick(rowData: any, columnName: any, tableHeader: any) {
    if (tableHeader.type === 'button-primary' || tableHeader.type === 'button-danger' || tableHeader.type === 'button-success') {
      this.buttonClickHandle.next({ rowData: rowData, columnName: columnName });
    }
  }

  doRowSelect(event: any) {
    // console.log(event)
    this.doRowActivate(event)
    if (typeof event.selected !== "undefined" && event.selected.length > 0) {
      this.selectedRow = event.selected[0].$$index;
      this.rowChangedHandle.next(event.selected[0]);
      for (var j = 0; j < this.tableHeaders.length; j++) {
        var header = this.tableHeaders[j].name;
        var headerIndex = this.tableHeaders[j].index;
        var headerType = this.tableHeaders[j].type;
        if (headerType == "checkbox") {
          // this.tableRowData[this.selectedRow][header] = !this.tableRowData[this.selectedRow][header];
          this.tableGridData.data[this.selectedRow][headerIndex] = !this.tableGridData.data[this.selectedRow][headerIndex];
          this.changeRequired = true;
          this.masterCheckbox.value = false;
          // this.masterCheckbox.valueChanged();
          setTimeout(() => {
            this.changeRequired = true;
          })
        }
      }
    }
  }

  doRowActivate(event: any) {
    if ((event.type === "keydown" && event.event.keyCode == 13)) {
      this.rowClickHandler.emit(event.row);
    } else if (event.type == "click") {
      this.rowClickHandler.emit(event.row);
    } else if (event.type === "keydown" && event.event.keyCode == "38") {
      var rowNum = (this.recordsPerPage * (this.table.offset));
      if (rowNum > 0 && (parseInt(event.row.$$index) === rowNum)) {
        let pageEvent = {};
        pageEvent["page"] = this.table.offset;
        this.onFooterPage(pageEvent, "upKey");
      }
    } else if (event.type === "keydown" && event.event.keyCode == "40") {
      var rowNum = (this.recordsPerPage * (this.table.offset + 1));
      if (rowNum < this.tableRowData.length - 1 && (parseInt(event.row.$$index) === (rowNum - 1))) {
        let pageEvent = {};
        pageEvent["page"] = this.table.offset + 2;
        this.onFooterPage(pageEvent, "downKey");
      }
    }
  }

  selectRow(index) {
    if (typeof this.table.rows[index] !== "undefined") {
      this.table.selected = [];
      this.table.selected.push(this.table.rows[index]);
      this.selectedRow = index;
      this.rowChangedHandle.next(this.table.rows[index]);
    }
  }

  onFooterPage(event: any, key?) {
    this.table.onFooterPage(event);
    this.goToPageNumber = event.page;
    if (typeof event.page === "undefined" || event.page === null) {
      event.page = 1;
    }
    var rowNum = (this.recordsPerPage * (parseInt(event.page) - 1));
    let rowFocusIndex = 0;
    if (typeof key !== "undefined" && key === "upKey") {
      rowNum = rowNum + this.recordsPerPage - 1;
      rowFocusIndex = this.recordsPerPage - 1;
    }
    var rowIndex = 0;
    if (this.tableRowData.length > 0) {
      rowIndex = this.tableRowData[rowNum].$$index;
    }
    if (this.isMutationChange) {
      this.isMutationChange = false;
      rowIndex = this.selectedRow;
      for (var i = 0; i < this.tableRowData.length; i++) {
        if (this.tableRowData[i].$$index === rowIndex) {
          rowNum = i;
          break;
        }
      }
    }
    this.selectedRow = rowIndex;
    this.table.selected = [];
    this.table.selected.push(this.tableRowData[rowNum]);
    this.rowChangedHandle.next(this.tableRowData[rowNum]);
    setTimeout(() => {
      this.focusOnGridRow(rowFocusIndex);
    });
  }

  getStyleClass(tableHeader) {
    var styleClass = "";
    var badgeClass = "text-center badge ";
    var buttonClass = "btn text-center btn-row ";
    var tableHeaderType = (tableHeader.type).replace(/ /g, '').toLowerCase()
    if (tableHeaderType === 'text') {
      styleClass = 'text-left';
    } else if (tableHeaderType === 'number' || tableHeaderType === 'decimal') {
      styleClass = 'text-right';
    } else if (tableHeaderType === 'date' || tableHeaderType === 'select' || tableHeaderType === 'checkbox') {
      styleClass = 'text-center';
    } else if (tableHeaderType === 'button-primary') {
      styleClass = buttonClass + 'btn-primary';
    } else if (tableHeaderType === 'button-success') {
      styleClass = buttonClass + 'btn-success';
    } else if (tableHeaderType === 'button-danger') {
      styleClass = buttonClass + 'btn-danger';
    } else if (tableHeaderType === 'badge-green') {
      styleClass = badgeClass + 'bg-color-greenLight';
    } else if (tableHeaderType === 'badge-red') {
      styleClass = badgeClass + 'bg-color-red';
    } else if (tableHeaderType === 'badge-blueLight') {
      styleClass = badgeClass + 'bg-color-blueLight';
    } else if (tableHeaderType === 'badge') {
      styleClass = badgeClass;
    }
    return styleClass;
  }
  isColumnCheckbox(tableHeader) {
    var tableHeaderType = (tableHeader.type).replace(/ /g, '').toLowerCase()
    return tableHeaderType === "checkbox";
  }
  focusOnGridRow(rowNum) {
    if (this.focusRequired) {
      var tableRow = $('.datatable-row-wrapper');
      if (typeof tableRow != "undefined" && tableRow.length > 0) {
        tableRow[rowNum].firstElementChild.focus();
      }
    }
  }

  clearRows() {
    this.tableRowData = [];
    this.tableFilterData = [];
    this.selectedRow = -1;
    this.rowChangedHandle = new BehaviorSubject({});
    this.goToPageNumber = 1;
    for (var i = 0; i < this.tableHeaders.length; i++) {
      if (typeof this.tableHeaders[i]["searchValue"] !== "undefined") {
        this.tableHeaders[i]["searchValue"] = "";
      }
    }
  }

  isColumnHidden(tableHeader: any) {
    if ((tableHeader.type === 'hidden') || (typeof tableHeader.hidden !== "undefined" && !V.isEmpty(tableHeader.hidden) && parseInt(tableHeader.hidden) === 1)) {
      return false;
    } else {
      return true;
    }
  }

  getRandonNumber(rowIndex, columnIndex) {
    return (this.randomNumber + ((rowIndex) * 100) + ((columnIndex) * 10))
  }

  updateToggleChange(event, rowIndex, columnIndex) {
    this.tableGridData.data[rowIndex][columnIndex] = event;//this.tableRowData[rowIndex][columnName];
  }

  goToPage(pageNumber) {
    if (parseInt(pageNumber) > 0) {
      let pageEvent = {};
      pageEvent["page"] = parseInt(pageNumber);
      this.onFooterPage(pageEvent);
    }
  }

  download() {
    var data = {
      "column_names": this.tableGridData.metaData.column_names,
      "data": this.tableRowData
    }
    this.downloadClickHandler.emit(data)
  }
}
