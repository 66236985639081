import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule, FormGroupDirective } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { TranslateStore } from "@ngx-translate/core/src/translate.store"

import { AlertModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { ReCaptchaModule } from 'angular2-recaptcha';
import { SidebarModule } from 'ng-sidebar';
import { LocalStorageModule } from 'angular-2-local-storage';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { RouterService } from '../services/routerService';
import { ContextService } from '../services/contextService';
import { i18nService } from '../services/i18nService'
import { HttpService } from '../services/httpService'
import { StorageService } from '../services/storageService'
import { ConstantsService } from '../services/constantsService'
import { UtilityService } from '../services/utilityService'
import { ReportGenerateService } from '../services/reportGenerateService'
import { UploadFileService } from 'app/services/uploadFileService'

import {MatTableModule} from '@angular/material/table';


import { MatPaginatorModule } from '@angular/material/paginator';

import { MatSortModule } from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';





import { MatToolbarModule, MatCardModule, MatSelectModule,MatIconModule,MatExpansionModule, MatSidenavModule, MatInputModule, MatRadioModule, MatButtonModule, MatCheckboxModule, MatProgressSpinnerModule, MatDividerModule } from '@angular/material';
import { ExcelGenerateService } from 'app/services/excelGenerateService';
import { PdfReceiptGenerateService } from 'app/services/pdfReceiptGenerateService';
import { pdfCertifcateGenerateService } from 'app/services/pdfCertifcateGenerateService';
import { StoredDataService } from 'app/services/storedDataService';
// import {MatTreeModule} from '@angular/material/tree';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [],
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatExpansionModule,
    MatSidenavModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    MatSortModule,
 MatTableModule,
    MatDividerModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,

    MatCheckboxModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    DatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    PaginationModule.forRoot(),
    LocalStorageModule.withConfig({
      prefix: 'banyan-tree',
      storageType: 'localStorage'
    }),
    ReCaptchaModule,
    TextMaskModule,
    NgxDatatableModule,
    DragDropModule,
    BsDropdownModule.forRoot(),
    MatButtonToggleModule,
    MatTooltipModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    MatToolbarModule,
    MatCardModule,
    MatExpansionModule,
    MatSidenavModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AlertModule,
    MatSortModule,
    ModalModule,
    DatepickerModule,
    CollapseModule,
    HttpModule,
    SidebarModule,
    PaginationModule,
    LocalStorageModule,
    ReCaptchaModule,
    TextMaskModule,
    NgxDatatableModule,
    BsDropdownModule,
    MatButtonModule,
    MatCheckboxModule,
    DragDropModule,
    MatButtonToggleModule,
    MatTooltipModule
  ],
  schemas: []
})
export class BaseModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: BaseModule,
      providers: [HttpClientModule,RouterService, ContextService, i18nService, StorageService, HttpService, ConstantsService, UtilityService, ReportGenerateService, UploadFileService, ExcelGenerateService, PdfReceiptGenerateService,pdfCertifcateGenerateService,StoredDataService]
    };
  }
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en_US');
    translate.use('en_US');
  }
}
