import { Injectable } from "@angular/core";
import * as jsPDF from 'jspdf';
import * as $ from 'jquery';
import "jspdf-autotable";
import { gsss } from "assets/images/gsss";
import { rupee_symbol } from "assets/images/rupee-symbol";
import { SCPUC_logo } from "assets/images/SCPUC_logo";
import { SCDCB_logo } from "assets/images/SCDCB_logo";
import { PHA_logo } from "assets/images/PHA_logo";
import { chinmaya } from "assets/images/chinmaya";

@Injectable()
export class PdfReceiptGenerateService {
  doc: any;
  constructor() { }

  downloadReceipt(branch_code: string,Data, filename: string) {
    this.doc = new jsPDF('p', 'pt', 'a4');
    if(branch_code == "GSSS1" || branch_code == "GSSS2" || branch_code == "GSSS3" || branch_code == "GSSS4"){
        this.getGSSSReceipt(Data);
    }else if(branch_code == "SCPUC19"){
        this.getSCPUCReceipt(Data);
    }else if(branch_code == "SCDCB"){
        this.getSCDCBReceipt(Data);
    }else if(branch_code == "PKG1" || branch_code == "PHA1" || branch_code == "PPCC" || branch_code == "PHVSS"){
        this.getPHAReceipt(Data);
    }else if(branch_code == "CNVID"){
      this.getCNVIDReceipt(Data)
    }
    this.doc.save(filename + '.pdf');
  }
  
//GSSS TEMPLATE FORMAT  
  getGSSSReceipt(Data) {
    this.doc.setLineWidth(0.5);
    this.doc.rect(20, 15, 100, 110); // logo  box
    this.doc.addImage(gsss, 'png', 35, 35, 75, 75); // base64 logo
    this.doc.rect(120, 15, 455, 60); // header  box1
    this.doc.rect(120, 75, 455, 50); // header  box2

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 30, 'Geetha Shishu Shikshana Sangha (R)', { align: 'center', width: '595'});

    this.doc.setFontSize(15);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 45, Data['branch_name'], {align: 'center',width: '595'});

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40,57, 'P.B. No. 2, T. Narasipura Main Road, Siddharthanagara, Mysuru - 570 011 ',{ align: 'center', width: '595' });
    if (Data['branch_code'] == 'GSSS3') {
      this.doc.setFontSize(11);
      this.getAlignmentValue(40, 70, 'College Code : NN 0072 ', {align: 'center',width: '595'});
    } else if (Data['branch_code'] == 'GSSS2') {
      this.doc.setFontSize(11);
      this.getAlignmentValue(40, 70, "Accredited with Grade 'A' By KSEEB ", {align: 'center',width: '595'});
    }

    var phone = ' 0821 - 2472452 ';
    const collegeCodeArray = [
      { text: 'Tel. No. : ', size: '12', style: 'bold' },
      { text: phone, size: '12', style: 'normal' }
    ];
    this.putMulti(125, 90, collegeCodeArray, { align: 'left' }, this.doc);

    var diseCode = '';
    if (Data['branch_code'] == 'GSSS1') {
      diseCode = '29261203103';
    } else if (Data['branch_code'] == 'GSSS2') {
      diseCode = '29261203111';
    } else if (Data['branch_code'] == 'GSSS3') {
      diseCode = '29261203118';
    }
    const diseCodeArray = [
      { text: 'DISE Code : ', size: '12', style: 'bold' },
      { text: diseCode, size: '12', style: 'normal' }
    ];
    this.putMulti(125, 105, diseCodeArray, { align: 'left' }, this.doc);

    var email = '';
    if (Data['branch_code'] == 'GSSS1' || Data['branch_code'] == 'GSSS2') {
      email = 'gssshighschool@gmail.com';
    } else if (Data['branch_code'] == 'GSSS3') {
      email = 'gssspu@gmail.com';
    }
    const emailArray = [
      { text: 'Email ID : ', size: '12', style: 'bold' },
      { text: email, size: '12', style: 'normal' }
    ];
    this.putMulti(365, 90,emailArray,{ align: 'left', width: '595' },this.doc);

    var gstNo = '29AAATG6551G1ZL';
    const gstArray = [
      { text: 'GST No. : ', size: '12', style: 'bold' },
      { text: gstNo, size: '12', style: 'normal' }
    ];
    this.putMulti(150,105,gstArray,{ align: 'center', width: '595' },this.doc);
    const webSiteArray = [
      { text: 'Website : ', size: '11.5', style: 'bold' },
      { text: 'www.gsss-school.in', size: '11.5', style: 'normal' }
    ];
    this.putMulti(251,120, webSiteArray,{ align: 'left', width: '595' }, this.doc);

    this.doc.setFontSize(14);
    this.doc.setFontType('bold');
    this.getAlignmentValue(10, 155, 'FEE RECEIPT', { align: 'center',width: '595'});
    this.doc.line(260, 157, 260 + 90, 157); // 'Fee Receipt' underline
    this.getAlignmentValue(10,175,'ACADEMIC YEAR : ' + Data['academic_year'],{ align: 'center', width: '595' });
    this.doc.line(210, 177, 210 + 195, 177);
    this.doc.setFontSize(12);

    const textArray0 = [
      { text: 'Receipt No. : ', size: '13', style: 'bold' },
      {text: Data['receipt_no'],size: '13',style: 'normal'}
    ];
    this.putMulti(40, 230, textArray0, { align: 'left' }, this.doc);

    const textArray1 = [
      { text: 'Student Name : ', size: '13', style: 'bold' },
      { text: Data['student_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 250, textArray1, { align: 'left' }, this.doc);

    const textArray2 = [
      { text: "Father's Name : ", size: '13', style: 'bold' },
      { text: Data['father_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 270, textArray2, { align: 'left' }, this.doc);

    const textArray3 = [
      { text: "Mother's Name : ", size: '13', style: 'bold' },
      { text: Data['mother_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 290, textArray3, { align: 'left' }, this.doc);

    const textArray4 = [
      { text: 'Date : ', size: '13', style: 'bold' },
      { text: Data['paid_on'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,230,textArray4,{ align: 'left', width: '600' },this.doc);

    const textArray5 = [
      { text: 'Student ID : ', size: '13', style: 'bold' },
      { text: Data['student_id'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,250,textArray5,{ align: 'left', width: '600' },this.doc);

    const textArray6 = [
      { text: 'Class : ', size: '13', style: 'bold' },
      { text: Data['level'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,270,textArray6,{ align: 'left', width: '600' }, this.doc);

    const textArray7 = [
      {text: Data['branch_code'] == 'GSSS3' ? 'Stream : ' : 'Section : ',size: '13', style: 'bold'},
      { text: Data['section'], size: '13', style: 'normal' }
    ];
    this.putMulti(385, 290,textArray7,{ align: 'left', width: '600' },this.doc);

    // table
    this.doc.addImage(rupee_symbol, 'png', 533, 323, 8, 8);
    var row = [];
    let column = ['Sl. No. ', 'Particulars', 'Amount (    )'];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([ i + 1,Data['feeTypes'][i].display,Data['feeTypes'][i].amount]);
    }

    row.push(['Total Fees (    )', '', Data['amount_paid']]);
    if ('total_remaining_amount' in Data) {
      row.push(['Balance Fees (    )', '', Data['total_remaining_amount']]);
    }

    var cellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    var headCellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 10
    };

    this.doc.autoTable(column, row, {
      margin: 20,
      startY: 310,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows

        if (!('total_remaining_amount' in Data) && data.row.index === rows.length - 1 && data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if ('total_remaining_amount' in Data && (data.row.index === rows.length - 1 ||data.row.index === rows.length - 2) &&data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }
      },
      columnStyles: {
        0: {cellWidth: 65,fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        1: {halign: 'left',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        2: {cellWidth: 130,halign: 'right',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }}
      }
    });
    //tableY is 'y-position' after table is populated
    let tableY = this.doc.lastAutoTable.finalY;
    if ('total_remaining_amount' in Data) {
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 35, 8, 8);
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    } else {
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    }

    this.doc.setDrawColor(0, 0, 0);
    this.doc.setLineWidth(0.5);
    this.doc.setFontType('bold');

    const textArray8 = [
      { text: 'Amount in words :  ', size: '13', style: 'bold' },
      {text: 'Rupees ' + Data['amount_paid_in_words'].split('Rupees').join(''),size: '13',style: 'normal'}
    ];
    this.putMulti( 40,tableY + 20,textArray8,{ align: 'left', width: '600' },this.doc);

    this.doc.setLineWidth(0.2);
    this.doc.setDrawColor(0, 0, 0);
    this.doc.rect(20,tableY + 55, 555, 20); // mode of payment box
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '13', style: 'bold' },
      {text: Data['payment_mode'].split('Payment').join('').trim(),size: '13',style: 'normal'}
    ];
    this.putMulti(40,tableY + 70, textArray9, { align: "left", width: "600" }, this.doc);
    if (Data["payment_mode"].split('Payment').join('').trim() == "Online" || Data["payment_mode"] == "Cheque" || Data["payment_mode"] == "DD") {
      var id = ""
      if(Data["payment_mode"].split('Payment').join('').trim() == "Online" ){
        id = "Transaction Id : "
      }else if(Data["payment_mode"] == "Cheque"){
        id = "Cheque No : " 
      }else if(Data["payment_mode"] == "DD"){
        id = "DD No : " 
      }
      this.doc.line(287,tableY + 55 , 287, 50 + tableY + 25); // mode of payment box mid line
      const transactionIdArray = [
        { text: id, size: '13', style: 'bold' },
        { text: Data['transaction_id'], size: '13', style: 'normal' }
      ];
      this.putMulti(298,tableY + 70,transactionIdArray,{ align: 'left', width: '600' },this.doc);
    }
    this.doc.setFontSize(14);
    if ('concession_amount' in Data) {
      const concessionArray = [
        { text: '*Overall Concession : ', size: '11.6', style: 'bold' },
        { text: String(Data['concession_amount']), size: '11.6', style: 'normal' }
      ];
      this.putMulti(240,tableY + 90,concessionArray, { align: 'left', width: '600' },this.doc);
      this.doc.setFontSize(11.6);
      this.doc.setFontType('bold');
      this.getAlignmentValue(40,tableY + 107,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' });
    } else {
      this.doc.setFontSize(11.6);
      this.doc.setFontType('bold');
      this.getAlignmentValue(40,tableY + 90,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' });
    }
    this.doc.setFontSize(12);
    this.getAlignmentValue(40, tableY + 160, 'Date: ' + Data['paid_on'], {align: 'left',width: '595'});
    this.doc.rect(20, 15, 555, tableY + 155); // outer box
  }


  //GSSS TEMPLATE FORMAT  
  getCNVIDReceipt(Data) {
    this.doc.setLineWidth(0.5);
    this.doc.rect(20, 15, 100, 110); // logo  box
    this.doc.addImage(chinmaya, 'png', 35, 35, 75, 75); // base64 logo
    this.doc.rect(120, 15, 455, 60); // header  box1
    this.doc.rect(120, 75, 455, 50); // header  box2

    this.doc.setFontSize(18);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 40, Data['branch_name'], {align: 'center',width: '595'});

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40,57, 'Jayalakshmipura, Mysuru - 570 012 ',{ align: 'center', width: '595' });
    
    this.doc.setFontSize(11);
    this.getAlignmentValue(40, 70, 'Code : 40384', {align: 'center',width: '595'});
    

    var phone = ' 0821 241 4913 ';
    const collegeCodeArray = [
      { text: 'Tel. No. : ', size: '12', style: 'bold' },
      { text: phone, size: '12', style: 'normal' }
    ];
    this.putMulti(125, 98, collegeCodeArray, { align: 'left' }, this.doc);

    var email = 'chinmayavidyalaya.mys@gmail.com';
    const emailArray = [
      { text: 'Email ID : ', size: '12', style: 'bold' },
      { text: email, size: '12', style: 'normal' }
    ];
    this.putMulti(318, 98,emailArray,{ align: 'left', width: '595' },this.doc);
    
    const webSiteArray = [
      { text: 'Website : ', size: '11.5', style: 'bold' },
      { text: 'chinmayavidyalayamys.com', size: '11.5', style: 'normal' }
    ];
    this.putMulti(251,120, webSiteArray,{ align: 'left', width: '595' }, this.doc);

    this.doc.setFontSize(14);
    this.doc.setFontType('bold');
    this.getAlignmentValue(10, 155, 'FEE RECEIPT', { align: 'center',width: '595'});
    this.doc.line(260, 157, 260 + 90, 157); // 'Fee Receipt' underline
    this.getAlignmentValue(10,175,'ACADEMIC YEAR : ' + Data['academic_year'],{ align: 'center', width: '595' });
    this.doc.line(210, 177, 210 + 195, 177);
    this.doc.setFontSize(12);

    const textArray0 = [
      { text: 'Receipt No. : ', size: '13', style: 'bold' },
      {text: Data['prefix'] + ' ' + Data['receipt_no'],size: '13',style: 'normal'}
    ];
    this.putMulti(40, 230, textArray0, { align: 'left' }, this.doc);

    const textArray1 = [
      { text: 'Student Name : ', size: '13', style: 'bold' },
      { text: Data['student_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 250, textArray1, { align: 'left' }, this.doc);

    const textArray2 = [
      { text: "Father's Name : ", size: '13', style: 'bold' },
      { text: Data['father_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 270, textArray2, { align: 'left' }, this.doc);

    const textArray3 = [
      { text: "Mother's Name : ", size: '13', style: 'bold' },
      { text: Data['mother_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 290, textArray3, { align: 'left' }, this.doc);

    const textArray4 = [
      { text: 'Date : ', size: '13', style: 'bold' },
      { text: Data['paid_on'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,230,textArray4,{ align: 'left', width: '600' },this.doc);

    const textArray5 = [
      { text: 'Student ID : ', size: '13', style: 'bold' },
      { text: Data['student_id'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,250,textArray5,{ align: 'left', width: '600' },this.doc);

    const textArray6 = [
      { text: 'Class : ', size: '13', style: 'bold' },
      { text: Data['level'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,270,textArray6,{ align: 'left', width: '600' }, this.doc);

    const textArray7 = [
      {text: 'Section : ',size: '13', style: 'bold'},
      { text: Data['section'], size: '13', style: 'normal' }
    ];
    this.putMulti(385, 290,textArray7,{ align: 'left', width: '600' },this.doc);

    // table
    this.doc.addImage(rupee_symbol, 'png', 533, 323, 8, 8);
    var row = [];
    let column = ['Sl. No. ', 'Particulars', 'Amount (    )'];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([ i + 1,Data['feeTypes'][i].display,Data['feeTypes'][i].amount]);
    }

    row.push(['Total Fees (    )', '', Data['amount_paid']]);
    if ('total_remaining_amount' in Data) {
      row.push(['Balance Fees (    )', '', Data['total_remaining_amount']]);
    }

    var cellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    var headCellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 10
    };

    this.doc.autoTable(column, row, {
      margin: 20,
      startY: 310,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows

        if (!('total_remaining_amount' in Data) && data.row.index === rows.length - 1 && data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if ('total_remaining_amount' in Data && (data.row.index === rows.length - 1 ||data.row.index === rows.length - 2) &&data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }
      },
      columnStyles: {
        0: {cellWidth: 65,fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        1: {halign: 'left',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        2: {cellWidth: 130,halign: 'right',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }}
      }
    });
    //tableY is 'y-position' after table is populated
    let tableY = this.doc.lastAutoTable.finalY;
    if ('total_remaining_amount' in Data) {
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 35, 8, 8);
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    } else {
      this.doc.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    }

    this.doc.setDrawColor(0, 0, 0);
    this.doc.setLineWidth(0.5);
    this.doc.setFontType('bold');

    const textArray8 = [
      { text: 'Amount in words :  ', size: '13', style: 'bold' },
      {text: 'Rupees ' + Data['amount_paid_in_words'].split('Rupees').join(''),size: '13',style: 'normal'}
    ];
    this.putMulti( 40,tableY + 20,textArray8,{ align: 'left', width: '600' },this.doc);

    this.doc.setLineWidth(0.2);
    this.doc.setDrawColor(0, 0, 0);
    this.doc.rect(20,tableY + 55, 555, 20); // mode of payment box
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '13', style: 'bold' },
      {text: Data['payment_mode'].split('Payment').join('').trim(),size: '13',style: 'normal'}
    ];
    this.putMulti(40,tableY + 70, textArray9, { align: "left", width: "600" }, this.doc);
    if (Data["payment_mode"].split('Payment').join('').trim() == "Online" || Data["payment_mode"] == "Cheque" || Data["payment_mode"] == "DD") {
      var id = ""
      if(Data["payment_mode"].split('Payment').join('').trim() == "Online" ){
        id = "Transaction Id : "
      }else if(Data["payment_mode"] == "Cheque"){
        id = "Cheque No : " 
      }else if(Data["payment_mode"] == "DD"){
        id = "DD No : " 
      }
      this.doc.line(287,tableY + 55 , 287, 50 + tableY + 25); // mode of payment box mid line
      const transactionIdArray = [
        { text: id, size: '13', style: 'bold' },
        { text: Data['transaction_id'], size: '13', style: 'normal' }
      ];
      this.putMulti(298,tableY + 70,transactionIdArray,{ align: 'left', width: '600' },this.doc);
    }
    this.doc.setFontSize(14);
    if ('concession_amount' in Data) {
      const concessionArray = [
        { text: '*Overall Concession : ', size: '11.6', style: 'bold' },
        { text: String(Data['concession_amount']), size: '11.6', style: 'normal' }
      ];
      this.putMulti(240,tableY + 90,concessionArray, { align: 'left', width: '600' },this.doc);
      this.doc.setFontSize(11.6);
      this.doc.setFontType('bold');
      this.getAlignmentValue(40,tableY + 107,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' });
    } else {
      this.doc.setFontSize(11.6);
      this.doc.setFontType('bold');
      this.getAlignmentValue(40,tableY + 90,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' });
    }
    this.doc.setFontSize(12);
    this.getAlignmentValue(40, tableY + 160, 'Date: ' + Data['paid_on'], {align: 'left',width: '595'});
    this.doc.rect(20, 15, 555, tableY + 155); // outer box
  } 
  
  //SC PUC TEMPLATE FORMAT
  getSCPUCReceipt(Data) {
    console.log(Data,"Pdf-data")
    this.doc.setLineWidth(0.5);
    this.doc.rect(20, 30, 100, 70); // logo  box
    this.doc.addImage(SCPUC_logo, "png", 35, 32 , 75, 65); // base64 logo
    this.doc.rect(120, 30, 455, 70); // header  box

    this.doc.setFontSize(12);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 48, Data['branch_name'], {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(
      40,
      61,
      'Kumar Nursery New Bank Colony, Konanakunte',
      { align: 'center', width: '595' }
    );

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 72, 'Karnataka Bengaluru - 560 062', {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(8);
    const contactDetails = [
      { text: 'Website : siliconcitypu.org, ', size: '8', style: 'normal' },
      { text: 'Email Id: siliconpu.kk@gmail.com, ', size: '8', style: 'normal' },
      { text: 'Contact Number : 7026132790', size: '8', style: 'bold' }
    ];
    this.putMulti(180, 82, contactDetails, { align: 'left' }, this.doc);

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 93, 'Fee Receipt', {
      align: 'center',
      width: '595'
    });

    this.doc.line(309, 94, 311 + 56, 94);

    this.doc.setFontSize(10);
    this.getAlignmentValue(
      10,
      115,
      'ACADEMIC YEAR : ' + Data['academic_year'],
      { align: 'center', width: '595' }
    );
    this.doc.line(237, 119, 235 + 144, 119);

    // left side data displaying
    this.doc.setFontSize(8);
    const textArray1 = [
      { text: 'Student Name : ', size: '10', style: 'bold' },
      { text: Data['student_name'], size: '10', style: 'normal' }
    ];
    this.putMulti(40, 145, textArray1, { align: 'left' }, this.doc);

    const textArray2 = [
      { text: 'Class : ', size: '10', style: 'bold' },
      {
        text: Data['level'] + ' ' + Data['section'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      40,
      160,
      textArray2,
      { align: 'left', width: '600' },
      this.doc
    );
    // Rigth side data displaying
    const textArray3 = [
      { text: 'Date : ', size: '10', style: 'bold' },
      { text: Data['paid_on'], size: '10', style: 'normal' }
    ];
    this.putMulti(
      420,
      145,
      textArray3,
      { align: 'left', width: '600' },
      this.doc
    );

    const textArray4 = [
      { text: 'Receipt No. : ', size: '10', style: 'bold' },
      {
        text: Data['prefix'] + ' ' + Data['receipt_no'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(420, 160, textArray4, { align: 'left' }, this.doc);

    this.doc.line(20, 170, 575, 170);
    this.doc.setFontType('bold');
    this.getAlignmentValue(27, 183, 'Fees Category : ' + Data['fee_category'], {
      align: 'left',
      width: '280'
    });

    this.getAlignmentValue(
      313,
      183,
      'Total Category Feee(      ) : ' + Data['total_amount'],
      { align: 'right', width: '255' }
    );
    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(
      543 -
        (this.doc.getStringUnitWidth(String(Data['total_amount'])) * 10) /
          this.doc.internal.scaleFactor,
      183,
      'Rs.',
      { align: 'left', width: '20' }
    );
    this.doc.setFontSize(10);
    // table
    var row = [];
    let column = [
      'Sl. No. ',
      'Particulars',
      'Payable (         )',
      'Paid (         )'
    ];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([
        i + 1,
        Data['feeTypes'][i].display,
        Data['feeTypes'][i].payable,
        Data['feeTypes'][i].amount
      ]);
    }

    row.push(['Total Fees (Rs.)', '',Data['total_amount'], Data['amount_paid']]);
    // if ('remaining_amount' in Data) {
    //   row.push(['Balance Fees (Rs.)', '', '', Data['remaining_amount']]);
    // }

    var cellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 2
    };

    var headCellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    this.doc.autoTable(column, row, {
      margin: 20,
      startY: 190,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows
        // if (
        //   !('remaining_amount' in Data) &&
        //   data.row.index === rows.length - 1 &&
        //   data.column.index == 0
        // ) {
        //   data.cell.styles.minCellHeight = 10;
        //   data.cell.colSpan = 2;
        //   data.cell.styles.halign = 'right';
        // }
        //new if after removing balance fee code
        if (data.row.index === rows.length - 1 && data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        // if (
        //   'remaining_amount' in Data &&
        //   data.row.index === rows.length - 2 &&
        //   data.column.index == 0
        // ) {
        //   data.cell.styles.minCellHeight = 10;
        //   data.cell.colSpan = 2;
        //   data.cell.styles.halign = 'right';
        // }

        // if (
        //   'remaining_amount' in Data &&
        //   data.row.index === rows.length - 1 &&
        //   data.column.index == 0
        // ) {
        //   data.cell.styles.minCellHeight = 10;
        //   data.cell.colSpan = 3;
        //   data.cell.styles.halign = 'right';
        // }
      },
      columnStyles: {
        0: { cellWidth: 50, fontStyle: 'bold', cellPadding: {left: 4, right: 4, top: 2, bottom: 2} },
        1: { halign: 'left', fontStyle: 'bold', cellPadding: {left: 4, right: 4, top: 2, bottom: 2} },
        2: { cellWidth: 130, halign: 'right', fontStyle: 'bold', cellPadding: {left: 0, right: 4, top: 2, bottom: 2} },
        3: { cellWidth: 130, halign: 'right', fontStyle: 'bold', cellPadding: {left: 0, right: 4, top: 2, bottom: 2} }
      }
    });
    //tableY is 'y-position' after table is populated
    let tableY = this.doc.lastAutoTable.finalY;
    
    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(389, 204, 'in Rs.', { align: 'left', width: '20' });

    this.getAlignmentValue(510, 204, 'in Rs.', { align: 'left', width: '20' });

    this.doc.setLineWidth(0.2);
    this.doc.setDrawColor(0, 0, 0);
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '10', style: 'bold' },
      {
        text: Data['payment_mode']
          .split('Payment')
          .join('')
          .trim(),
        size: '10',
        style: 'normal'
      }
    ];
    // this.putMulti(
    //   30,
    //   'remaining_amount' in Data ? tableY - 20 : tableY - 5,
    //   textArray9,
    //   { align: 'left', width: '600' },
    //   this.doc
    // );
    var lastYValue = tableY
    if (
      Data['payment_mode']
        .split('Payment')
        .join('')
        .trim() == 'Online' ||
      Data['payment_mode'] == 'Cheque' ||
      Data['payment_mode'] == 'DD'
    ) {
      var id = '';
      if (
        Data['payment_mode']
          .split('Payment')
          .join('')
          .trim() == 'Online'
      ) {
        id = 'Transaction Id : ';
      } else if (Data['payment_mode'] == 'Cheque') {
        id = 'Cheque No : ';
      } else if (Data['payment_mode'] == 'DD') {
        id = 'DD No : ';
      }

      const transactionIdArray = [
        { text: id, size: '10', style: 'bold' },
        { text: Data['transaction_id'], size: '10', style: 'normal' }
      ];
      this.putMulti(
        30,
        tableY + 12,
        transactionIdArray,
        { align: 'left', width: '528' },
        this.doc
      );

      this.doc.line(20, tableY + 18, 575, tableY + 18)
      lastYValue = tableY + 18;
      if('bank_name' in Data && Data['bank_name'].trim() != ''){
        const bankNameArray = [
          { text: 'Bank Name : ', size: '10', style: 'bold' },
          { text: Data['bank_name'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 30,
          bankNameArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 35, 575, tableY + 35)
        lastYValue = tableY + 35;
      }

      if('bank_branch' in Data && Data['bank_branch'].trim() != ''){
        const bankBranchArray = [
          { text: 'Bank Branch : ', size: '10', style: 'bold' },
          { text: Data['bank_branch'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 47,
          bankBranchArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 54, 575, tableY + 54)
        lastYValue = tableY + 54;
      }
    }
    this.doc.setFontType('bold');

    const textArray8 = [
      { text: 'Amount in words :  ', size: '10', style: 'bold' },
      {
        text: Data['amount_paid_in_words'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      30,
      lastYValue + 12,
      textArray8,
      { align: 'center', width: '528' },
      this.doc
    );
  
    this.doc.line(20, lastYValue + 17, 575, lastYValue + 17)
    this.doc.setFontSize(11.5)
    this.doc.setFontStyle('bold')
    this.getAlignmentValue(30, lastYValue + 29, '*Fee once paid will not be refunded.', { align: 'center', width: '528' });
    this.getAlignmentValue(30, lastYValue + 43, `Total Amount : ${Data["total_assigned_amount"]}`, { align: 'center', width: '528' });
    this.getAlignmentValue(30, lastYValue + 56, `Total Paid Amount : ${Data["total_paid_amount"]}`, { align: 'center', width: '528' });
    this.getAlignmentValue(30, lastYValue + 69, `Total Remaining Amount : ${Data["total_remaining_amount"]}`, { align: 'center', width: '528' });
    var latestYvalue = lastYValue + 69
    if('concession_amount' in Data){
      this.getAlignmentValue(30, latestYvalue + 14, '*OverAll Concession(Rs.) : '+ Data['concession_amount'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }
    if('reference_no' in Data){
      this.getAlignmentValue(30, latestYvalue + 14, '* '+ Data['reference_no'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }
    if('due_date' in Data){
      this.getAlignmentValue(30, latestYvalue + 14, '* '+ Data['due_date'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }

    this.doc.setFontSize(10);
    this.doc.setFontStyle('bold');
    this.getAlignmentValue(30, lastYValue + 132, 'Authorized Signature', {
      align: 'right',
      width: '539'
    });
    
    this.doc.rect(20, 30, 555, lastYValue + 110);
    // outer box
  }

  //SC DEGREE TEMPLATE FORMAT
  getSCDCBReceipt(Data) {
    this.doc.setLineWidth(0.5);
    this.doc.rect(20, 30, 100, 70); // logo  box
    this.doc.addImage(SCDCB_logo, "png", 35, 32 , 75, 65); // base64 logo
    this.doc.rect(120, 30, 455, 70); // header  box

    this.doc.setFontSize(12);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 48, Data['branch_name'], {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(
      40,
      61,
      'Kumar Nursery New Bank Colony, Konanakunte',
      { align: 'center', width: '595' }
    );

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 72, 'Karnataka Bengaluru - 560 062', {
      align: 'center',
      width: '595'
    });

    const contactDetails = [
      { text: 'Website : www.siliconcitysccmc.in, ', size: '8', style: 'normal' },
      { text: 'Email Id: sccmc.kkbglr@gmail.com, ', size: '8', style: 'normal' },
      { text: 'Contact Number : 6361819194', size: '8', style: 'bold' }
    ];
    this.putMulti(170, 82, contactDetails, { align: 'left' }, this.doc);

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 94, 'Fee Receipt', {
      align: 'center',
      width: '595'
    });

    this.doc.line(309, 95, 311 + 56, 95);

    this.doc.setFontSize(10);
    this.getAlignmentValue(
      10,
      115,
      'ACADEMIC YEAR : ' + Data['academic_year'].split('-ODD').join('').split('-EVEN').join('').trim(),
      { align: 'center', width: '595' }
    );
    this.doc.line(237, 119, 235 + 144, 119);

    // left side data displaying
    this.doc.setFontSize(8);
    const textArray1 = [
      { text: 'Student Name : ', size: '10', style: 'bold' },
      { text: Data['student_name'], size: '10', style: 'normal' }
    ];
    this.putMulti(40, 145, textArray1, { align: 'left' }, this.doc);

    const textArray2 = [
      { text: 'Class : ', size: '10', style: 'bold' },
      {
        text: Data['level'] + ' ' + Data['section'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      40,
      160,
      textArray2,
      { align: 'left', width: '528' },
      this.doc
    );

    const textArray3 = [
      { text: 'Course : ', size: '10', style: 'bold' },
      {
        text: Data['department_name'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      40,
      175,
      textArray3,
      { align: 'left', width: '600' },
      this.doc
    );
    // Rigth side data displaying
    const textArray4 = [
      { text: 'Date : ', size: '10', style: 'bold' },
      { text: Data['paid_on'], size: '10', style: 'normal' }
    ];
    this.putMulti(
      420,
      145,
      textArray4,
      { align: 'left', width: '600' },
      this.doc
    );

    const textArray5 = [
      { text: 'Receipt No. : ', size: '10', style: 'bold' },
      {
        text: String(Data['receipt_no']),
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(420, 160, textArray5, { align: 'left' }, this.doc);

    this.doc.line(20, 185, 575, 185);
    this.doc.setFontType('bold');

    this.getAlignmentValue(
      313,
      197,
      'Total Fee(      ) : ' + Data['total_fee_amount'],
      { align: 'right', width: '255' }
    );
    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(
      543 -
        (this.doc.getStringUnitWidth(String(Data['total_amount'])) * 10) /
          this.doc.internal.scaleFactor,
      197,
      'Rs.',
      { align: 'left', width: '20' }
    );
    this.doc.setFontSize(10);
    // table
    var row = [];
    let column = [
      'Sl. No. ',
      'Particulars',
      'Payable (         )',
      'Paid (         )'
    ];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([
        i + 1,
        Data['feeTypes'][i].name,
        Data['feeTypes'][i].payable,
        Data['feeTypes'][i].fee_paid
      ]);
    }

    row.push(['Total(Rs.)', '',Data['total_amount'], Data['amount_paid']]);
    if ('remaining_amount' in Data) {
      row.push(['Balance(Rs.)', '', '', Data['remaining_amount']]);
    }

    var cellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 2
    };

    var headCellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    this.doc.autoTable(column, row, {
      margin: 20,
      startY: 205,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows
        if (
          !('remaining_amount' in Data) &&
          data.row.index === rows.length - 1 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if (
          'remaining_amount' in Data &&
          data.row.index === rows.length - 2 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if (
          'remaining_amount' in Data &&
          data.row.index === rows.length - 1 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 3;
          data.cell.styles.halign = 'right';
        }
      },
      columnStyles: {
        0: { cellWidth: 50, fontStyle: 'bold', cellPadding: {left: 4, right: 4, top: 2, bottom: 2}  },
        1: { halign: 'left', fontStyle: 'bold', cellPadding: {left: 4, right: 4, top: 2, bottom: 2} },
        2: { cellWidth: 130, halign: 'right', fontStyle: 'bold', cellPadding: {left: 0, right: 4, top: 2, bottom: 2} },
        3: { cellWidth: 130, halign: 'right', fontStyle: 'bold', cellPadding: {left: 0, right: 4, top: 2, bottom: 2}}
      }
    });
    // tableY is 'y-position' after table is populated
    let tableY = this.doc.lastAutoTable.finalY;
    
    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(389, 219, 'in Rs.', { align: 'left', width: '20' });

    this.getAlignmentValue(510, 219, 'in Rs.', { align: 'left', width: '20' });

    this.doc.setLineWidth(0.2);
    this.doc.setDrawColor(0, 0, 0);
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '10', style: 'bold' },
      {
        text: Data['payment_mode']
          .split('Payment')
          .join('')
          .trim(),
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      30,
      'remaining_amount' in Data ? tableY - 20 : tableY - 5,
      textArray9,
      { align: 'left', width: '600' },
      this.doc
    );
    var lastYValue = tableY
    if (
      Data['payment_mode']
        .split('Payment')
        .join('')
        .trim() == 'Online' ||
      Data['payment_mode'] == 'Cheque' ||
      Data['payment_mode'] == 'DD'
    ) {
      var TransactionName = '';
      var TransactionDateName = '' 
      if (
        Data['payment_mode']
          .split('Payment')
          .join('')
          .trim() == 'Online'
      ) {
        TransactionName = 'Transaction No : ';
        TransactionDateName = 'Transaction Date : ';
      } else if (Data['payment_mode'] == 'Cheque') {
        TransactionName = 'Cheque No : ';
        TransactionDateName = 'Cheque Date : ';
      } else if (Data['payment_mode'] == 'DD') {
        TransactionName = 'DD No : ';
        TransactionDateName = 'DD Date : ';
      }

      const transactionIdArray = [
        { text: TransactionName, size: '10', style: 'bold' },
        { text: Data['transaction_number'], size: '10', style: 'normal' }
      ];
      this.putMulti(
        30,
        tableY + 12,
        transactionIdArray,
        { align: 'left', width: '270' },
        this.doc
      );

      const transactionDateArray = [
        { text: TransactionDateName, size: '10', style: 'bold' },
        { text: Data['date_of_transaction'], size: '10', style: 'normal' }
      ];
      this.putMulti(
        320,
        tableY + 12,
        transactionDateArray,
        { align: 'left', width: '243' },
        this.doc
      );

      this.doc.line(20, tableY + 18, 575, tableY + 18)
      lastYValue = tableY + 18;

      // correct
      if('bank_name' in Data && Data['bank_name'].trim() != ''){
        const bankNameArray = [
          { text: 'Bank Name : ', size: '10', style: 'bold' },
          { text: Data['bank_name'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 30,
          bankNameArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 35, 575, tableY + 35)
        lastYValue = tableY + 35;
      }
      // correct
      if('bank_branch' in Data && Data['bank_branch'].trim() != ''){
        const bankBranchArray = [
          { text: 'Bank Branch : ', size: '10', style: 'bold' },
          { text: Data['bank_branch'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 47,
          bankBranchArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 54, 575, tableY + 54)
        lastYValue = tableY + 54;
      }
    }
    this.doc.setFontType('bold');
    // correct
    const textArray8 = [
      { text: 'Amount in words :  ', size: '10', style: 'bold' },
      {
        text: Data['amount_paid_in_words'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      30,
      lastYValue + 12,
      textArray8,
      { align: 'center', width: '528' },
      this.doc
    );
      // correct
    this.doc.line(20, lastYValue + 17, 575, lastYValue + 17)
    this.doc.setFontSize(8)
    this.doc.setFontStyle('bold')
    this.getAlignmentValue(30, lastYValue + 29, '* Fee once paid will not be refunded.', { align: 'center', width: '528' });
    var latestYvalue = lastYValue + 29
    // correct
    if('concession_amount' in Data){
      this.getAlignmentValue(30, latestYvalue + 14, '* Concession(Rs.) : '+ Data['concession_amount'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }
    // correct
    if('reference_no' in Data && Data['reference_no'] != "" && 'reference_date' in Data && Data['reference_date'] != ""){
      this.getAlignmentValue(30, latestYvalue + 14, '* '+ Data['reference_no'] + ' ' + Data['reference_date'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }
    // correct
    if('due_date' in Data){
      this.getAlignmentValue(30, latestYvalue + 14, '* '+ Data['due_date'], { align: 'center', width: '528' });
      latestYvalue = latestYvalue + 13
    }

    this.doc.setFontSize(10);
    this.doc.setFontStyle('bold');
    this.getAlignmentValue(30, lastYValue + 132, 'Authorized Signature', {
      align: 'right',
      width: '539'
    });
    
    this.doc.rect(20, 30, 555, lastYValue + 110);
    // outer box
  }

  // PHA TEMPLATE FORMAT
  getPHAReceipt(Data) {
    this.doc.setLineWidth(0.5);
    this.doc.rect(20, 30, 100, 70); // logo  box
    this.doc.addImage(PHA_logo, 'png', 35, 32, 75, 65); // base64 logo
    this.doc.rect(120, 30, 455, 70); // header  box

    this.doc.setFontSize(12);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 48, Data['branch_name'], {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 61, 'C.A No 2 Udayaravi Road ,', {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(8);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 72, 'Kuvempunagar, Mysore 570023', {
      align: 'center',
      width: '595'
    });

    this.doc.setFontSize(10);
    this.doc.setFontType('bold');
    this.doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(40, 88, 'Fee Receipt', {
      align: 'center',
      width: '595'
    });

    this.doc.line(309, 90, 311 + 56, 90);

    this.doc.setFontSize(10);
    this.getAlignmentValue(
      10,
      115,
      'ACADEMIC YEAR : ' + Data['academic_year'],
      { align: 'center', width: '595' }
    );
    this.doc.line(237, 119, 235 + 144, 119);

    // left side data displaying
    this.doc.setFontSize(8);
    const textArray1 = [
      { text: 'Student Name : ', size: '10', style: 'bold' },
      { text: Data['student_name'], size: '10', style: 'normal' }
    ];
    this.putMulti(40, 145, textArray1, { align: 'left' }, this.doc);

    const textArray2 = [
      { text: 'Class : ', size: '10', style: 'bold' },
      {
        text: Data['level'] + ' ' + Data['section'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      40,
      160,
      textArray2,
      { align: 'left', width: '600' },
      this.doc
    );
    // Rigth side data displaying
    const textArray3 = [
      { text: 'Date : ', size: '10', style: 'bold' },
      { text: Data['paid_on'], size: '10', style: 'normal' }
    ];
    this.putMulti(
      420,
      145,
      textArray3,
      { align: 'left', width: '600' },
      this.doc
    );

    const textArray4 = [
      { text: 'Receipt No. : ', size: '10', style: 'bold' },
      {
        text: Data['prefix'] + ' ' + Data['receipt_no'],
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(420, 160, textArray4, { align: 'left' }, this.doc);

    this.doc.line(20, 170, 575, 170);
    this.doc.setFontType('bold');
    this.getAlignmentValue(27, 183, 'Fees Category : ' + Data['fee_category'], {
      align: 'left',
      width: '280'
    });

    this.getAlignmentValue(
      313,
      183,
      'Total Category Feee(      ) : ' + Data['total_amount'],
      { align: 'right', width: '255' }
    );
    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(
      543 -
        (this.doc.getStringUnitWidth(String(Data['total_amount'])) * 10) /
          this.doc.internal.scaleFactor,
      183,
      'Rs.',
      { align: 'left', width: '20' }
    );
    this.doc.setFontSize(10);
    // table
    var row = [];
    let column = [
      'Sl. No. ',
      'Particulars',
      'Payable (         )',
      'Paid (         )'
    ];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([
        i + 1,
        Data['feeTypes'][i].display,
        Data['feeTypes'][i].payable,
        Data['feeTypes'][i].amount
      ]);
    }

    row.push([
      'Total Fees (Rs.)',
      '',
      Data['total_amount'],
      Data['amount_paid']
    ]);
    if ('remaining_amount' in Data) {
      row.push(['Balance Fees (Rs.)', '', '', Data['remaining_amount']]);
    }

    var cellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 2
    };

    var headCellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    this.doc.autoTable(column, row, {
      margin: 20,
      startY: 190,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows
        if (
          !('remaining_amount' in Data) &&
          data.row.index === rows.length - 1 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if (
          'remaining_amount' in Data &&
          data.row.index === rows.length - 2 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if (
          'remaining_amount' in Data &&
          data.row.index === rows.length - 1 &&
          data.column.index == 0
        ) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 3;
          data.cell.styles.halign = 'right';
        }
      },
      columnStyles: {
        0: {
          cellWidth: 50,
          fontStyle: 'bold',
          cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }
        },
        1: {
          halign: 'left',
          fontStyle: 'bold',
          cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }
        },
        2: {
          cellWidth: 130,
          halign: 'right',
          fontStyle: 'bold',
          cellPadding: { left: 0, right: 4, top: 2, bottom: 2 }
        },
        3: {
          cellWidth: 130,
          halign: 'right',
          fontStyle: 'bold',
          cellPadding: { left: 0, right: 4, top: 2, bottom: 2 }
        }
      }
    });
    //tableY is 'y-position' after table is populated
    let tableY = this.doc.lastAutoTable.finalY;

    this.doc.setFontSize(8);
    this.doc.setFontType('italic');
    this.getAlignmentValue(389, 204, 'in Rs.', { align: 'left', width: '20' });

    this.getAlignmentValue(510, 204, 'in Rs.', { align: 'left', width: '20' });

    this.doc.setLineWidth(0.2);
    this.doc.setDrawColor(0, 0, 0);
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '10', style: 'bold' },
      {
        text: Data['payment_mode']
          .split('Payment')
          .join('')
          .trim(),
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      30,
      'remaining_amount' in Data ? tableY - 20 : tableY - 5,
      textArray9,
      { align: 'left', width: '600' },
      this.doc
    );
    var lastYValue = tableY;
    if (
      Data['payment_mode']
        .split('Payment')
        .join('')
        .trim() == 'Online' ||
      Data['payment_mode'] == 'Cheque' ||
      Data['payment_mode'] == 'DD'
    ) {
      var id = '';
      if (
        Data['payment_mode']
          .split('Payment')
          .join('')
          .trim() == 'Online'
      ) {
        id = 'Transaction Id : ';
      } else if (Data['payment_mode'] == 'Cheque') {
        id = 'Cheque No : ';
      } else if (Data['payment_mode'] == 'DD') {
        id = 'DD No : ';
      }

      const transactionIdArray = [
        { text: id, size: '10', style: 'bold' },
        { text: Data['transaction_id'], size: '10', style: 'normal' }
      ];
      this.putMulti(
        30,
        tableY + 12,
        transactionIdArray,
        { align: 'left', width: '528' },
        this.doc
      );

      this.doc.line(20, tableY + 18, 575, tableY + 18);
      lastYValue = tableY + 18;
      if ('bank_name' in Data && Data['bank_name'].trim() != '') {
        const bankNameArray = [
          { text: 'Bank Name : ', size: '10', style: 'bold' },
          { text: Data['bank_name'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 30,
          bankNameArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 35, 575, tableY + 35);
        lastYValue = tableY + 35;
      }

      if ('bank_branch' in Data && Data['bank_branch'].trim() != '') {
        const bankBranchArray = [
          { text: 'Bank Branch : ', size: '10', style: 'bold' },
          { text: Data['bank_branch'], size: '10', style: 'normal' }
        ];
        this.putMulti(
          30,
          tableY + 47,
          bankBranchArray,
          { align: 'left', width: '528' },
          this.doc
        );
        this.doc.line(20, tableY + 54, 575, tableY + 54);
        lastYValue = tableY + 54;
      }
    }
    this.doc.setFontType('bold');

    const textArray8 = [
      { text: 'Amount in words :  ', size: '10', style: 'bold' },
      {
        text: 'Rupees ' + Data['amount_paid_in_words'].split('Rupees').join(''),
        size: '10',
        style: 'normal'
      }
    ];
    this.putMulti(
      30,
      lastYValue + 12,
      textArray8,
      { align: 'center', width: '528' },
      this.doc
    );

    this.doc.line(20, lastYValue + 17, 575, lastYValue + 17);
    this.doc.setFontSize(8);
    this.doc.setFontStyle('bold');
    this.getAlignmentValue(
      30,
      lastYValue + 29,
      '*Fee once paid will not be refunded.',
      { align: 'center', width: '528' }
    );
    var latestYvalue = lastYValue + 29;
    if ('concession_amount' in Data) {
      this.getAlignmentValue(
        30,
        latestYvalue + 14,
        '*OverAll Concession(Rs.) : ' + Data['concession_amount'],
        { align: 'center', width: '528' }
      );
      latestYvalue = latestYvalue + 13;
    }
    if ('reference_no' in Data) {
      this.getAlignmentValue(
        30,
        latestYvalue + 14,
        '* ' + Data['reference_no'],
        { align: 'center', width: '528' }
      );
      latestYvalue = latestYvalue + 13;
    }
    if ('due_date' in Data) {
      this.getAlignmentValue(30, latestYvalue + 14, '* ' + Data['due_date'], {
        align: 'center',
        width: '528'
      });
      latestYvalue = latestYvalue + 13;
    }

    this.doc.setFontSize(10);
    this.doc.setFontStyle('bold');
    this.getAlignmentValue(30, lastYValue + 132, 'Authorized Signature', {
      align: 'right',
      width: '539'
    });

    this.doc.rect(20, 30, 555, lastYValue + 110);
    // outer box
  }



  getGSSSOtherFeeReceipt(Data, filename: string) {
    var tableStart = 0
    var headerRupeeSymbol = 0
    var docs = new jsPDF('p', 'pt', 'a4');
    docs.setLineWidth(0.5);
    docs.rect(20, 15, 100, 110); // logo  box
    docs.addImage(gsss, 'png', 35, 35, 75, 75); // base64 logo
    docs.rect(120, 15, 455, 60); // header  box1
    docs.rect(120, 75, 455, 50); // header  box2

    docs.setFontSize(10);
    docs.setFontType('bold');
    docs.setTextColor(0, 0, 0);
    this.getAlignmentValueForDocs(40, 30, 'Geetha Shishu Shikshana Sangha (R)', { align: 'center', width: '595'}, docs);

    docs.setFontSize(15);
    docs.setFontType('bold');
    docs.setTextColor(0, 0, 0);
    this.getAlignmentValueForDocs(40, 45, Data['branch_name'], {align: 'center',width: '595'}, docs);

    docs.setFontSize(10);
    docs.setFontType('bold');
    docs.setTextColor(0, 0, 0);
    this.getAlignmentValueForDocs(40,57, 'P.B. No. 2, T. Narasipura Main Road, Siddharthanagara, Mysuru - 570 011 ',{ align: 'center', width: '595' }, docs);
    if (Data['branch_code'] == 'GSSS3') {
      docs.setFontSize(11);
      this.getAlignmentValueForDocs(40, 70, 'College Code : NN 0072 ', {align: 'center',width: '595'}, docs);
    } else if (Data['branch_code'] == 'GSSS2') {
      docs.setFontSize(11);
      this.getAlignmentValueForDocs(40, 70, "Accredited with Grade 'A' By KSEEB ", {align: 'center',width: '595'}, docs);
    }

    var phone = ' 0821 - 2472452 ';
    const collegeCodeArray = [
      { text: 'Tel. No. : ', size: '12', style: 'bold' },
      { text: phone, size: '12', style: 'normal' }
    ];
    this.putMulti(125, 90, collegeCodeArray, { align: 'left' }, docs);

    var diseCode = '';
    if (Data['branch_code'] == 'GSSS1') {
      diseCode = '29261203103';
    } else if (Data['branch_code'] == 'GSSS2') {
      diseCode = '29261203111';
    } else if (Data['branch_code'] == 'GSSS3') {
      diseCode = '29261203118';
    }
    const diseCodeArray = [
      { text: 'DISE Code : ', size: '12', style: 'bold' },
      { text: diseCode, size: '12', style: 'normal' }
    ];
    this.putMulti(125, 105, diseCodeArray, { align: 'left' }, docs);

    var email = '';
    if (Data['branch_code'] == 'GSSS1' || Data['branch_code'] == 'GSSS2') {
      email = 'gssshighschool@gmail.com';
    } else if (Data['branch_code'] == 'GSSS3') {
      email = 'gssspu@gmail.com';
    }
    const emailArray = [
      { text: 'Email ID : ', size: '12', style: 'bold' },
      { text: email, size: '12', style: 'normal' }
    ];
    this.putMulti(365, 90,emailArray,{ align: 'left', width: '595' },docs);

    var gstNo = '29AAATG6551G1ZL';
    const gstArray = [
      { text: 'GST No. : ', size: '12', style: 'bold' },
      { text: gstNo, size: '12', style: 'normal' }
    ];
    this.putMulti(150,105,gstArray,{ align: 'center', width: '595' },docs);
    const webSiteArray = [
      { text: 'Website : ', size: '11.5', style: 'bold' },
      { text: 'www.gsss-school.in', size: '11.5', style: 'normal' }
    ];
    this.putMulti(251,120, webSiteArray,{ align: 'left', width: '595' }, docs);

    docs.setFontSize(14);
    docs.setFontType('bold');
    this.getAlignmentValueForDocs(10, 155, 'FEE RECEIPT', { align: 'center',width: '595'}, docs);
    docs.line(260, 157, 260 + 90, 157); // 'Fee Receipt' underline
    this.getAlignmentValueForDocs(10,175,'ACADEMIC YEAR : ' + Data['academic_year'],{ align: 'center', width: '595' }, docs);
    docs.line(210, 177, 210 + 195, 177);
    docs.setFontSize(12);

    const textArray0 = [
      { text: 'Receipt No. : ', size: '13', style: 'bold' },
      {text: Data['receipt_no'],size: '13',style: 'normal'}
    ];
    this.putMulti(40, 230, textArray0, { align: 'left' }, docs);

    const textArray1 = [
      { text: 'Student Name : ', size: '13', style: 'bold' },
      { text: Data['student_name'], size: '13', style: 'normal' }
    ];
    this.putMulti(40, 250, textArray1, { align: 'left' }, docs);

    if(Data['existing_student']){
      const textArray2 = [
        { text: "Father's Name : ", size: '13', style: 'bold' },
        { text: Data['father_name'], size: '13', style: 'normal' }
      ];
      this.putMulti(40, 270, textArray2, { align: 'left' }, docs);

      const textArray3 = [
        { text: "Mother's Name : ", size: '13', style: 'bold' },
        { text: Data['mother_name'], size: '13', style: 'normal' }
      ];
      this.putMulti(40, 290, textArray3, { align: 'left' }, docs);
    }
    const textArray4 = [
      { text: 'Date : ', size: '13', style: 'bold' },
      { text: Data['paid_on'], size: '13', style: 'normal' }
    ];
    this.putMulti(385,230,textArray4,{ align: 'left', width: '600' },docs);

    if(Data['existing_student']){
      const textArray5 = [
        { text: 'Student ID : ', size: '13', style: 'bold' },
        { text: Data['student_id'], size: '13', style: 'normal' }
      ];
      this.putMulti(385,250,textArray5,{ align: 'left', width: '600' },docs);

      const textArray6 = [
        { text: 'Class : ', size: '13', style: 'bold' },
        { text: Data['level'], size: '13', style: 'normal' }
      ];
      this.putMulti(385,270,textArray6,{ align: 'left', width: '600' }, docs);

      const textArray7 = [
        {text: Data['branch_code'] == 'GSSS3' ? 'Stream : ' : 'Section : ',size: '13', style: 'bold'},
        { text: Data['section'], size: '13', style: 'normal' }
      ];
      this.putMulti(385, 290,textArray7,{ align: 'left', width: '600' },docs);
      tableStart = 310
      headerRupeeSymbol = 323;
    }

    if(!Data['existing_student']){
      const textArray6 = [
        { text: 'Class : ', size: '13', style: 'bold' },
        { text: Data['level'], size: '13', style: 'normal' }
      ];
      this.putMulti(385,250,textArray6,{ align: 'left', width: '600' }, docs);
      tableStart = 270;
      headerRupeeSymbol = 283;
    }

    // table
    docs.addImage(rupee_symbol, 'png', 533, headerRupeeSymbol, 8, 8);
    var row = [];
    let column = ['Sl. No. ', 'Particulars', 'Amount (    )'];
    for (var i = 0; i < Data['feeTypes'].length; i++) {
      row.push([ i + 1,Data['feeTypes'][i].display,Data['feeTypes'][i].amount]);
    }

    row.push(['Total Fees (    )', '', Data['amount_paid']]);
    if ('total_remaining_amount' in Data) {
      row.push(['Balance Fees (    )', '', Data['total_remaining_amount']]);
    }

    var cellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    var headCellStyles = {
      fontSize: 13,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 10
    };

    docs.autoTable(column, row, {
      margin: 20,
      startY: tableStart,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        //colspan for last 2 rows

        if (!('total_remaining_amount' in Data) && data.row.index === rows.length - 1 && data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }

        if ('total_remaining_amount' in Data && (data.row.index === rows.length - 1 ||data.row.index === rows.length - 2) &&data.column.index == 0) {
          data.cell.styles.minCellHeight = 10;
          data.cell.colSpan = 2;
          data.cell.styles.halign = 'right';
        }
      },
      columnStyles: {
        0: {cellWidth: 65,fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        1: {halign: 'left',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }},
        2: {cellWidth: 130,halign: 'right',fontStyle: 'bold',cellPadding: { left: 4, right: 4, top: 2, bottom: 2 }}
      }
    });
    //tableY is 'y-position' after table is populated
    let tableY = docs.lastAutoTable.finalY;
    if ('total_remaining_amount' in Data) {
      docs.addImage(rupee_symbol, 'png', 426, tableY - 35, 8, 8);
      docs.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    } else {
      docs.addImage(rupee_symbol, 'png', 426, tableY - 14, 8, 8);
    }

    docs.setDrawColor(0, 0, 0);
    docs.setLineWidth(0.5);
    docs.setFontType('bold');

    const textArray8 = [
      { text: 'Amount in words :  ', size: '13', style: 'bold' },
      {text: 'Rupees ' + Data['amount_paid_in_words'].split('Rupees').join(''),size: '13',style: 'normal'}
    ];
    this.putMulti( 40,tableY + 20,textArray8,{ align: 'left', width: '600' },docs);

    docs.setLineWidth(0.2);
    docs.setDrawColor(0, 0, 0);
    docs.rect(20,tableY + 55, 555, 20); // mode of payment box
    const textArray9 = [
      { text: 'Mode of Payment : ', size: '13', style: 'bold' },
      {text: Data['payment_mode'].split('Payment').join('').trim(),size: '13',style: 'normal'}
    ];
    this.putMulti(40,tableY + 70, textArray9, { align: "left", width: "600" }, docs);
    if (Data["payment_mode"].split('Payment').join('').trim() == "Online" || Data["payment_mode"] == "Cheque" || Data["payment_mode"] == "DD") {
      var id = ""
      if(Data["payment_mode"].split('Payment').join('').trim() == "Online" ){
        id = "Transaction Id : "
      }else if(Data["payment_mode"] == "Cheque"){
        id = "Cheque No : " 
      }else if(Data["payment_mode"] == "DD"){
        id = "DD No : " 
      }
      docs.line(287,tableY + 55 , 287, 50 + tableY + 25); // mode of payment box mid line
      const transactionIdArray = [
        { text: id, size: '13', style: 'bold' },
        { text: Data['transaction_id'], size: '13', style: 'normal' }
      ];
      this.putMulti(298,tableY + 70,transactionIdArray,{ align: 'left', width: '600' },docs);
    }
    docs.setFontSize(14);
    if ('concession_amount' in Data) {
      const concessionArray = [
        { text: '*Overall Concession : ', size: '11.6', style: 'bold' },
        { text: String(Data['concession_amount']), size: '11.6', style: 'normal' }
      ];
      this.putMulti(240,tableY + 90,concessionArray, { align: 'left', width: '600' },docs);
      docs.setFontSize(11.6);
      docs.setFontType('bold');
      this.getAlignmentValueForDocs(40,tableY + 107,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' }, docs);
    } else {
      docs.setFontSize(11.6);
      docs.setFontType('bold');
      this.getAlignmentValueForDocs(40,tableY + 90,'Note: This is a Computerised Fee Receipt and does not required manual signature.',{ align: 'left', width: '595' }, docs);
    }
    docs.setFontSize(12);
    this.getAlignmentValueForDocs(40, tableY + 160, 'Date: ' + Data['paid_on'], {align: 'left',width: '595'}, docs);
    docs.rect(20, 15, 555, tableY + 155); // outer box
    docs.save(filename + '.pdf');
  }



  getAlignmentValueForDocs(x: any, y: any, text: string, options: any, doc) {
    var options = options || {};
    var defaults = {align: "left",width: doc.internal.pageSize.width };
    var settings = $.extend({}, defaults, options);
    var fontSize = doc.internal.getFontSize();
    var text_size =
      (doc.getStringUnitWidth(text) * fontSize) /
      doc.internal.scaleFactor;
    if (settings.align === "center") x += (settings.width - text_size) / 2;
    else if (settings.align === "right") x += settings.width - text_size;
    doc.text(text, x, y);
  }

  /**
     * fuction used to align a text in the pdf doc
     * @param x x-position
     * @param y y-position
     * @param text the text to be added
     * @param options used for alignment: center | left | right
     */
    getAlignmentValue(x: any, y: any, text: string, options: any) {
        var options = options || {};
        var defaults = {align: "left",width: this.doc.internal.pageSize.width };
        var settings = $.extend({}, defaults, options);
        var fontSize = this.doc.internal.getFontSize();
        var text_size =
          (this.doc.getStringUnitWidth(text) * fontSize) /
          this.doc.internal.scaleFactor;
        if (settings.align === "center") x += (settings.width - text_size) / 2;
        else if (settings.align === "right") x += settings.width - text_size;
        this.doc.text(text, x, y);
      }
      /**
       * Puts a line of text in a document
       * @param x X-coordinate in a page
       * @param y Y-coordinate in a page
       * @param text String to be printed
       * @param options Styling options
       * @param doc The document in which the texts must be printed
       */
      putText(x: any, y: any, text: string, options: any, doc: any) {
        var options = options || {};
        var defaults = {
          align: "left",
          width: doc.internal.pageSize.width,
          size: 8,
          style: "normal"
        };
        var settings = $.extend({}, defaults, options);
        var fontSize = settings.size;
        doc.setFontSize(fontSize);
        doc.setFontStyle(settings.style);
        var text_size =
          (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
        if (settings.align === "center") {
          x += (settings.width - text_size) / 2;
        } else if (settings.align === "right") x += settings.width - text_size;
        doc.text(text, x, y);
      }
      /**
       * Puts A line of text with multiple styles
       * @param x X-coordinate in a page
       * @param y Y-coordinate in a page
       * @param textArray Array of strings to be printed
       * @param options Styling option
       * @param doc The document in which the texts must be printed
       */
      putMulti(x: any, y: any, textArray: Array<any>, options: any, doc: any) {
        // putText(x,y,doc){
        var options = options || {};
        var defaults = {
          align: "left",
          width: doc.internal.pageSize.width
        };
        var text = "";
        textArray.forEach(e => {
          text += e.text;
        });
        var settings = $.extend({}, defaults, options);
        var fontSize = doc.internal.getFontSize();
        var text_size =
          (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
        if (settings.align === "center") x += (settings.width - text_size) / 2;
        else if (settings.align === "right") x += settings.width - text_size;
    
        textArray.forEach(e => {
          doc.setFontSize(e.size);
          doc.setFontStyle(e.style);
          doc.text(e.text, x, y);
          x += (doc.getStringUnitWidth(e.text) * e.size) / doc.internal.scaleFactor;
        });
      }
}