import { Component, ViewChild, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RouterService } from '../../services/routerService'
import { ContextService } from '../../services/contextService'
import { ConstantsService } from '../../services/constantsService'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'alert-modal',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class AlertModal {
  @ViewChild('lgModal') public alertModal: ModalDirective;
  message: string = "";
  alertType: string = "";
  alertCallback: Function = function() { };
  modalType: any;
  confirmResult: boolean;
  isShown: boolean = false;
  commonName:any = {};
  constructor(private routerService: RouterService, private contextService: ContextService, private fB: FormBuilder) {
    this.commonName["cancel"] = "Cancel";
    this.commonName["ok"] = "Ok"
  }
  private show = function(message) {
    this.message = message;
    this.alertModal.show();
    this.isShown = true;
  }
  hide = function() {
    this.alertModal.hide();
    setTimeout(()=>{
      this.isShown = false;
    },250);
  }
  showError = function(...args: any[]) {
    this.modalType = ConstantsService.MODAL_ALERT;
    var message = args[0];
    this.alertType = ConstantsService.ALERT_ERROR;
    if (typeof args[1] !== "function") {
      this.alertCallback = function() { };
    } else {
      this.alertCallback = args[1];
    }
    this.show(message);
  }
  showSuccess = function(...args: any[]) {
    this.modalType = ConstantsService.MODAL_ALERT;
    var message = args[0];
    if (typeof args[1] !== "function") {
      this.alertCallback = function() { };
    } else {
      this.alertCallback = args[1];
    }
    this.alertType = ConstantsService.ALERT_SUCCESS;
    this.show(message);
  }
  setYesOrNoButton(){
    this.commonName["cancel"]= 'No'
    this.commonName["ok"] = 'Yes'
  }
  resetYesOrNoButton(){
    this.commonName["cancel"] = "Cancel";
    this.commonName["ok"] = "Ok";
  }

  isSuccess() {
    return this.alertType === ConstantsService.ALERT_SUCCESS;
  }
  isError() {
    return this.alertType === ConstantsService.ALERT_ERROR;
  }
  alertHidden() {
    setTimeout(()=>{
      this.isShown = false;
    },250);
    this.alertCallback(this.confirmResult);
    this.alertCallback = function(){};
  }
  showConfirm(message, callback) {
    this.alertCallback = callback;
    this.alertType = ConstantsService.ALERT_NEUTRAL;
    this.modalType = ConstantsService.MODAL_CONFIRM;
    this.show(message);
  }
  isConfirm() {
    return this.modalType === ConstantsService.MODAL_CONFIRM;
  }
  isAlert() {
    return this.modalType === ConstantsService.MODAL_ALERT;
  }
  confirmPositive(){
    this.confirmResult = true;
    this.hide();
  }
  confirmNegative(){
    this.confirmResult = false;
    this.hide();
  }
  handleBackdropClick($event){
    $event.stopPropagation();
    $event.preventDefault();
  }
}
