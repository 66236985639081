import { Injectable } from "@angular/core";
import { HttpService } from "./httpService";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as fs from 'file-saver';
import * as $ from 'jquery';
import * as jsPDF from 'jspdf';
import "jspdf-autotable";
import { gsss } from "assets/images/gsss";
import { rupee_symbol } from "assets/images/rupee-symbol";
const Attendance = {
  TABLE_HEADER_NAMES: ["sl_no","Student ID","Student Name"],
  TABLE_ROWS: [ ],
  HEADER: [ 
    {loc:"A1",value:"Consolidated Attendance", align: 'center', fontSize:16}
  ],
  MERGES: [
    'A4:A5','B4:B5','C4:C5'
  ],
  HEADER_DATA: {
    "A2":"branch",
  }
}
let FileSaver = require('file-saver')
export interface ExcelFormt {
  FileName: string
  SheetName: string
  Header: Array<{loc: string, value: any, align: string, fontSize: number}>
  StaticTableHeader: Array<{id: string,name: string}>
  DynamicTableHeader: Array<{id: string,name: string, sub_header: Array<{id: string,name: string}>}>
  TableData: Array<any>
}

export interface ExcelTemplate {
  fixedHeader: Array<{loc: string, value: any, style: ExcelProper.Style}>
  dynamicHeader: Array<{loc: string, dynamicVar: string, prefix: string, suffix: string, style: ExcelProper.Style}>
  // tableArray: string
  tableStart:number
  tableValues: Array<{key:string, width:number,hidden?:boolean,outlineLevel?: number, style?: ExcelProper.Style, header?: string}>
  tableHeader: Array<string>
  tableHeaderStyle?: ExcelProper.Style
  tableStyle: {rowHeight:number}
  merges: Array<string>
  colWidths: Array<number>
  fixedFooter: Array<{loc: Array<number>, value: any, style: ExcelProper.Style}>
  dynamicFootfer: Array<{loc: Array<number>, dynamicVar: string, prefix: string, suffix: string, style: ExcelProper.Style}>
  footerMerges: Array<Array<number>>
  footerRowHeights: Array<number>
}

@Injectable()
export class ExcelGenerateService {
  doc: any;
  constructor(private httpService: HttpService) { }

  createExcel(data,filename){
    // let workbook = new Workbook();
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    for(var i=0;i<data.length;i++){
        var key = Object.keys(data[i])[0]
        var sheet = workbook.addWorksheet(key,{pageSetup:{paperSize: 9, orientation:'portrait',fitToPage: true}});
        this.addRows(sheet,data[i][key])
    }

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, filename + '.xlsx');
    });
  }

  addRows(worksheet,data){
    for(var i=0;i<data["row_data"].length;i++){
        if(i>0){
            worksheet.addRows([[]])
        }
        if(i==0 && data.hasOwnProperty('initial_data')){
            this.addInitialData(worksheet,data,i)
        }
        if(!Array.isArray(data["row_data"][i][0])){
            this.addObjectRowData(worksheet,data["row_data"][i])
        }else if(Array.isArray(data["row_data"][i][0])){
            this.addArrayRowData(worksheet,data["row_data"][i],data,i)
        }
        if(data.hasOwnProperty('data_validation')){
            this.addDataValidation(worksheet,data)
        }
        worksheet.columns.forEach(function (column, i) {
          var maxLength = 0;
          column["eachCell"]({ includeEmpty: true }, function (cell) {
              var columnLength = cell.value ? cell.value.toString().length : 10;
              if (columnLength > maxLength ) {
                  maxLength = columnLength;
              }
          });
          column.width = maxLength < 10 ? 10 : maxLength;
        });
    }
  }

  addInitialData(worksheet,data,j){
    var col = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    for(var i=0;i<data["initial_data"].length;i++){
        worksheet.addRow(data["initial_data"][i])
        var start = `'A${worksheet.rowCount}'`
        if(data.hasOwnProperty('table_columns')){
            var end = `'${col[data["table_columns"][j].length-1]}${worksheet.rowCount}'`
        }else{
          if(Array.isArray(data["row_data"][j][0])){
            var end = `'${col[data["row_data"][j][0].length-1]}${worksheet.rowCount}'`
          }else{
            var end = `'${col[Object.keys(data["row_data"][j][0]).length-1]}${worksheet.rowCount}'`
          }
        }
        worksheet.mergeCells(`'${start}:${end}'`)
        worksheet.getRow(worksheet.rowCount).alignment = { vertical: 'top', horizontal: 'center' ,wrapText: true};
        worksheet.getRow(worksheet.rowCount).font = {bold: true };


    }
  }

  addObjectRowData(worksheet,arrayObject){
    this.addObjectTableHeader(worksheet,arrayObject[0])
    var keys = Object.keys(arrayObject[0])
    var finalData = []
    for(var i=0;i<arrayObject.length;i++){
        var row_data = []
        for(var j=0;j<keys.length;j++){
          if(keys[j] != '$$index'){
            row_data.push(arrayObject[i][keys[j]])
          }
        }
        finalData.push(row_data)
    }
    worksheet.addRows(finalData)
  }

  addObjectTableHeader(worksheet,object){
    var headers = Object.keys(object)
    headers = headers.map(el=> el.split(' ').map(el => el[0].toUpperCase() + el.substr(1).toLowerCase()).join(' '))
    headers = headers.map(el=> el.split('_').map(el => el[0].toUpperCase() + el.substr(1).toLowerCase()).join(' '))
    if(headers.includes("$$index")){
      const index = headers.indexOf("$$index");
      if (index !== -1) {
        headers.splice(index, 1);
      }
    }
    worksheet.addRow(headers)
    worksheet.getRow(worksheet.rowCount).font = {bold: true };
    worksheet.getRow(worksheet.rowCount).alignment = {vertical: 'top', horizontal: 'center' ,wrapText: true}
    worksheet.addRows([[]])
  }

  addArrayRowData(worksheet,arrayObject,data,k){
    if(data.hasOwnProperty('table_columns')){
        this.addArrayTableHeader(worksheet,data["table_columns"][k])
    }
    worksheet.addRows(arrayObject)
  }

  addArrayTableHeader(worksheet,arrayHeaders){
    worksheet.addRow(arrayHeaders)
    worksheet.getRow(worksheet.rowCount).font = {bold: true };
    worksheet.getRow(worksheet.rowCount).alignment = {vertical: 'top', horizontal: 'center' ,wrapText: true}
  }

  addDataValidation(worksheet,data){      
    for(var i=0;i<data["data_validation"].length;i++){
        var temp = {type: data["data_validation"][i]["config"]['type'],allowBlank: false,formulae: data["data_validation"][i]["config"]["formulae"]}
        worksheet.getCell(data["data_validation"][i]["at"]).dataValidation = temp
    }
  }

// Data Format Needed
// [
//     {
//         "Sheet Name": {
//             "initial_data": [["contains row data"]],
//             "row_data": [ [[]] or [{}] ],
//             "table_columns": [[table column is needed if the row_data is ArrayOfArray]],
//             "data_validation": [
//                 {
//                     at: "where data validation should be assigned",
//                     config: {type: '',formulae: '',allowBlank: true/false}
//                 }
//             ]
//         }
//     }
// ]

// ExcelCode For GSSS Dynamic Table Header Data
excelFromTemplate(data, filename) {
  var workBook: ExcelProper.Workbook = new Excel.Workbook();
  for(var i=0;i<data.length;i++){
    var key = Object.keys(data[i])[0]
    var sheet = workBook.addWorksheet(key,{pageSetup:{paperSize: 9, orientation:'portrait',fitToPage: true},properties: {defaultRowHeight: 100}});
    this.addDataRows(sheet,data[i][key]["sheetTemplate"],data[i][key])
  }

  workBook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, filename+ '.xlsx');
  });
}

addDataRows(worksheet,template,data){
  var tableData = data["row_data"];
  worksheet.columns = template.tableValues;
  var headerRow = worksheet.getRow(template.tableStart)
  headerRow.values = template.tableHeader;
  if(template.tableHeaderStyle != undefined){
    headerRow.eachCell(cell =>{
      cell.style = template.tableHeaderStyle
    });
  }
  // Fixed Header
  template["fixedHeader"].forEach(e=>{
    var cell = worksheet.getCell(e.loc);
    cell.style = e.style;
    cell.value = e.value;
  });
  //Dynamic header
  template["dynamicHeader"].forEach(d=>{
    var cell = worksheet.getCell(d.loc);
    cell.value = d.prefix + data[d.dynamicVar] + d.suffix;
    cell.style = d.style
  });
  // Table Data
  worksheet.addRows(tableData[0]);
  for(var i=0;i<=tableData.length;i++){
    worksheet.getRow(template.tableStart+i).height = template.tableStyle.rowHeight;
  }
  // Merges
  template.merges.forEach(m=>{
    worksheet.mergeCells(m);
  });
  // Columns
  var cols = worksheet.columns
  template.colWidths.forEach((c,i)=>{
    cols[i].width = c
  });
  var footerStart = worksheet.rowCount;
  // Fixed Footer
  template.fixedFooter.forEach( f =>{
    var cell = worksheet.getRow(f.loc[0]+footerStart).getCell(f.loc[1]);
    cell.value = f.value;
    cell.style = f.style;
  });
  // Dynamic Footer
  template.dynamicFootfer.forEach( f =>{
    var cell = worksheet.getRow(f.loc[0]+footerStart).getCell(f.loc[1]);
    cell.value = f.prefix + data[f.dynamicVar] + f.suffix;
    cell.style = f.style;
  });
  // Footer Merges
  template.footerMerges.forEach(range=>{
    worksheet.mergeCells(
      range[0]+footerStart,
      range[1],
      range[2]+footerStart,
      range[3]
    );
  });
  template.footerRowHeights.forEach((row,i) =>{
    worksheet.getRow(footerStart+i+1).height = row
  });
}

excelConsolidated(data: ExcelFormt){
  var workBook: ExcelProper.Workbook = new Excel.Workbook();
  var workSheet = workBook.addWorksheet(data.SheetName,{pageSetup:{paperSize: 9, orientation:'portrait',fitToPage: true},properties: {defaultRowHeight: 100}});
  data.Header.forEach(Header => {
    let cell = workSheet.getCell(Header.loc)
    cell.value = Header.value;
    cell.style.alignment = {horizontal : "center"}
    cell.style.font = {size: Header.fontSize,bold:true}
  });

  let rowStart = data.Header.length + 2;
  let colStart = 1;
  let header_row_end = data.Header.length + 2
  var column_key = []
  for(var i=0;i<data.StaticTableHeader.length;i++){
    workSheet.getRow(rowStart).getCell(colStart).value = data.StaticTableHeader[i].name
    workSheet.getRow(rowStart).font = {bold:true}
    workSheet.getRow(rowStart).alignment = {horizontal : "center"}
    column_key.push(
      { key: data.StaticTableHeader[i].id.toString(), width: 30 }
    )
    colStart = colStart + 1;
  }

  for(var j=0;j<data.DynamicTableHeader.length;j++){
    workSheet.getRow(rowStart).getCell(colStart).value = data.DynamicTableHeader[j].name
    if(data.DynamicTableHeader[j].sub_header.length == 0){
      column_key.push(
        { key: data.DynamicTableHeader[j].id.toString(), width: 30 }
      )
    }else{
      header_row_end = 4
      for(var k=0;k<data.DynamicTableHeader[j].sub_header.length;k++){
        workSheet.getRow(rowStart+1).getCell(colStart+k).value = data.DynamicTableHeader[j].sub_header[k].name;
        workSheet.getRow(rowStart+1).getCell(colStart+k).style.alignment = {horizontal: "center", vertical:"middle"};
        workSheet.getRow(rowStart+1).getCell(colStart+k).style.font = {bold: true};
        column_key.push(
          { key: data.DynamicTableHeader[j].id.toString()+data.DynamicTableHeader[j].sub_header[k].id.toString(), width: 30 }
        )
      }
    }
    workSheet.mergeCells(rowStart,colStart,rowStart,colStart+(data.DynamicTableHeader[j].sub_header.length-1));
    workSheet.getRow(rowStart).getCell(colStart).style.alignment = {horizontal: "center", vertical:"middle", };
    workSheet.getRow(rowStart).getCell(colStart).style.font = {bold: true};
    if(data.DynamicTableHeader[j].sub_header.length > 0 && data.DynamicTableHeader[j].sub_header.length > 1){
      colStart = colStart + (data.DynamicTableHeader[j].sub_header.length)
    }else{
      colStart = colStart + 1;
    }
  }

  workSheet.columns = column_key;

  for(var m=0;m<data.TableData.length;m++){
    Object.keys(data.TableData[m]).forEach(student_id=>{
      let student_data = {"sl_no": m+1}
      for(var q=0;q<data.StaticTableHeader.length;q++){
        if(data.StaticTableHeader[q]['id'] != "sl_no"){
          student_data[data.StaticTableHeader[q]['id']] = data.TableData[m][student_id][data.StaticTableHeader[q]['id']]
        }
      }
      var table_header_data = data.TableData[m][student_id]["header_data"]
      for(var n=0;n<table_header_data.length;n++){
        Object.keys(table_header_data[n]).forEach(header_id=>{
          var value = table_header_data[n][header_id]
          for(var p=0;p<value.length;p++){
            Object.keys(value[p]).forEach(sub_header_id=>{
              student_data[header_id.toString()+sub_header_id.toString()] = value[p][sub_header_id]
            })
          }
        });
      }
      workSheet.addRow(student_data);
    })
  }

  let lastRowNum = workSheet.lastRow.number;
  const lastTableRowNum = lastRowNum;
  for (let i=rowStart; i<= lastTableRowNum; i++) {
    const row = workSheet.getRow(i);
    row.eachCell({includeEmpty: true}, (cell => {
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: false };
    }));
  }

  var actual_col_count = workSheet.actualColumnCount
  for(var k=0;k<data.Header.length;k++){
    workSheet.mergeCells(k+1,1,k+1,actual_col_count);
  }

  for(var l=0;l<data.StaticTableHeader.length;l++){
    workSheet.mergeCells(rowStart,l+1,header_row_end,l+1);
  }

  // workSheet.columns.forEach(function (column, i) {
  //   var maxLength = 0;
  //   column["eachCell"]({ includeEmpty: true }, function (cell) {
  //       var columnLength = cell.value ? cell.value.toString().length : 10;
  //       if (columnLength > maxLength ) {
  //           maxLength = columnLength;
  //       }
  //   });
  //   column.width = maxLength < 10 ? 10 : maxLength;
  // });
  var filename = data.FileName
  workBook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    fs.saveAs(blob, filename + '.xlsx');
  })
}


    /**
    * Downloads receipt(GSSS)
    * @param data input data for the receipt
    * @param filename The name of the file to be downloaded
    */

    downloadReceipt(Data,filename: string) {
      this.doc = new jsPDF('p', 'pt', 'a4');
      this.getReceipt(Data);
      var output = this.doc.output();
      this.doc.save(filename + '.pdf');
      return output;
    }
  
    getReceipt(Data) {
      this.doc.setLineWidth(0.5);
      this.doc.rect(20, 15 , 100, 110); // logo  box
      this.doc.addImage(gsss, "png", 35, 35 , 75, 75); // base64 logo
      this.doc.rect(120, 15, 455, 60); // header  box
      this.doc.rect(120, 75, 455, 50); // header  box
  
      this.doc.setFontSize(10);
      this.doc.setFontType("bold");
      this.doc.setTextColor(0, 0, 0);
      this.getAlignmentValue(40, 30 , "Geetha Shishu Shikshana Sangha (R)", {align: "center",width: "595"});
  
      this.doc.setFontSize(15);
      this.doc.setFontType("bold");
      this.doc.setTextColor(0, 0, 0);
      this.getAlignmentValue(40,45 ,Data["branch_name"],{ align: "center", width: "595" });
  
      this.doc.setFontSize(10);
      this.doc.setFontType("bold");
      this.doc.setTextColor(0, 0, 0);
      this.getAlignmentValue(40,58 ,"P.B. No. 2, T. Narasipura Main Road, Siddharthanagara, Mysuru - 570 011 ",{ align: "center", width: "595" });
      if(Data["branch_code"]=="GSSS3"){
        this.doc.setFontSize(11);
        this.getAlignmentValue(40, 69.5, "College Code : NN 0072 ", {align: "center",width: "595"});
      }else if(Data["branch_code"]=="GSSS2"){
        this.doc.setFontSize(11);
        this.getAlignmentValue(40, 69.5, "Accredited with Grade 'A' By KSEEB ", {align: "center",width: "595"});
      }
  
      var phone = " 0821 - 2472452 ";
      const collegeCodeArray = [
        { text: "Tel. No. : ", size: "12", style: "bold" },
        { text: phone, size: "12", style: "normal" }
      ];
      this.putMulti(125, 90, collegeCodeArray, { align: "left" }, this.doc);
  
      var diseCode = "";
      if(Data["branch_code"]=="GSSS1"){
        diseCode = "29261203103"
      }else if(Data["branch_code"]=="GSSS2"){
        diseCode = "29261203111"
      }else if(Data["branch_code"]=="GSSS3"){
        diseCode = "29261203118"
      }
      const diseCodeArray = [
        { text: "DISE Code : ", size: "12", style: "bold" },
        { text: diseCode, size: "12", style: "normal" }
      ];
      this.putMulti(125, 105, diseCodeArray, { align: "left" }, this.doc);
  
      var email = "";
      if(Data["branch_code"]=="GSSS1" || Data["branch_code"]=="GSSS2"){
        email = "gssshighschool@gmail.com"
      }else if(Data["branch_code"]=="GSSS3"){
        email = "gssspu@gmail.com"
      }
      const emailArray = [
        { text: "Email ID : ", size: "12", style: "bold" },
        { text: email, size: "12", style: "normal" }
      ];
      this.putMulti(365,90,emailArray,{ align: "left", width: "595" },this.doc);
  
      var gstNo = "29AAATG6551G1ZL";
      const gstArray = [
        { text: "GST No. : ", size: "12", style: "bold" },
        { text: gstNo, size: "12", style: "normal" }
      ];
      this.putMulti(150,105,gstArray,{ align: "center", width: "595" },this.doc);
      const webSiteArray = [
        { text: "Website : ", size: "11.5", style: "bold" },
        { text: "www.gsss-school.in", size: "11.5", style: "normal" }
      ]
      this.putMulti(251,120,webSiteArray,{ align: "left", width: "595" },this.doc);

      this.doc.setFontSize(14);
      this.doc.setFontType("bold");
      this.getAlignmentValue(10, 150 , "FEE RECEIPT", {align: "center", width: "595"});
      this.doc.line(260, 152, 260 + 90, 152);
      this.getAlignmentValue(10,165 ,"ACADEMIC YEAR : " + Data["academic_year"],{ align: "center", width: "595" });
      this.doc.line(210, 167, 210 + 195, 167);
      this.doc.setFontSize(12);
  
      const textArray0 = [
        { text: "Receipt No. : ", size: "13", style: "bold" },
        { text: Data["receipt_no"], size: "13", style: "normal" }
      ];
      this.putMulti(40, 185, textArray0, { align: "left" }, this.doc);
  
      const textArray1 = [
        { text: "Student Name : ", size: "13", style: "bold" },
        { text: Data["student_name"], size: "13", style: "normal" }
      ];
      this.putMulti(40, 202, textArray1, { align: "left" }, this.doc);
  
      const textArray2 = [
        { text: "Father's Name : ", size: "13", style: "bold" },
        { text: Data["father_name"], size: "13", style: "normal" }
      ];
      this.putMulti(40, 219, textArray2, { align: "left" }, this.doc);
  
      const textArray3 = [
        { text: "Mother's Name : ", size: "13", style: "bold" },
        { text: Data["mother_name"], size: "13", style: "normal" }
      ];
      this.putMulti(40, 236, textArray3, { align: "left" }, this.doc);
  
      const textArray4 = [
        { text: "Date : ", size: "13", style: "bold" },
        { text: Data["paid_on"], size: "13", style: "normal" }
      ];
      this.putMulti(385,185, textArray4,{ align: "left", width: "600" }, this.doc);
  
      const textArray5 = [
        { text: "Student ID : ", size: "13", style: "bold" },
        { text: Data["student_id"], size: "13", style: "normal" }
      ];
      this.putMulti( 385, 202, textArray5,{ align: "left", width: "600" },this.doc);
  
      const textArray6 = [
        { text: "Class : ", size: "13", style: "bold" },
        { text: Data["level"], size: "13", style: "normal" }
      ];
      this.putMulti(385,219,textArray6,{ align: "left", width: "600" },this.doc);
  
      const textArray7 = [
        {text: Data["branch_code"] == "GSSS3" ? "Stream : " : "Section : ",size: "13",style: "bold"},
        { text: Data["section"], size: "13", style: "normal" }
      ];
      this.putMulti(385,236,textArray7,{ align: "left", width: "600" },this.doc);
  
      // table
      this.doc.addImage(rupee_symbol, "png", 533, 273 , 8, 8);
      var row = [];
      let column = ["Sl. No. ", "Particulars", "Amount (    )"];
      for (var i = 0; i < Data["feeTypes"].length; i++) {
        row.push([ i + 1,Data["feeTypes"][i].display,Data["feeTypes"][i].amount]);
      }

  
      row.push(["Total Fees (    )","", Data["amount_paid"]]);
      if ("remaining_amount" in Data) {
        row.push(["Balance Fees (    )","", Data["remaining_amount"]]);
      }
  
      var cellStyles = {
        fontSize: 13,
        fillColor: false,
        textColor: 0,
        halign: "center",
        valign: "middle",
        lineWidth: 0.3,
        lineColor: 0,
        minCellHeight: 0,
        cellPadding: 5
      };
  
      var headCellStyles = {
        fontSize: 13,
        fillColor: false,
        textColor: 0,
        halign: "center",
        valign: "middle",
        lineWidth: 0.3,
        lineColor: 0,
        minCellHeight: 0,
        cellPadding: 10
      };
  
      this.doc.autoTable(column, row, {
        margin: 20,
        startY: 260,
        headStyles: headCellStyles,
        bodyStyles: cellStyles,
        styles: cellStyles,
        alternateRowStyles: cellStyles,
        didParseCell: function(data) {
          var rows = data.table.body;
          //colspan for last 2 rows

          if(!("remaining_amount" in Data) && (data.row.index === rows.length - 1) && data.column.index == 0){
            data.cell.styles.minCellHeight = 10;
            data.cell.colSpan = 2;
            data.cell.styles.halign = "right"
          }

          if(("remaining_amount" in Data) && (data.row.index === rows.length - 1 || data.row.index === rows.length - 2) && data.column.index == 0){
            data.cell.styles.minCellHeight = 10;
            data.cell.colSpan = 2;
            data.cell.styles.halign = "right"
          }
        },
        columnStyles: {
          0: {cellWidth: 65,fontStyle: "bold"},
          1: {halign:'left', fontStyle: "bold"},
          2: {cellWidth: 130,halign:'right' , fontStyle: "bold"},
        }
      });
      //tableY is 'y-position' after table is populated
      let tableY = this.doc.lastAutoTable.finalY;
      if(("remaining_amount" in Data)){
        this.doc.addImage(rupee_symbol, "png", 426, tableY-43 , 8, 8);
        this.doc.addImage(rupee_symbol, "png", 426, tableY-17 , 8, 8);
      }else{
        this.doc.addImage(rupee_symbol, "png", 426, tableY-17 , 8, 8);
      }

      this.doc.setDrawColor(0, 0, 0);
      this.doc.setLineWidth(0.5);
      this.doc.setFontType("bold");
  
      const textArray8 = [
        { text: "Amount in words :  ", size: "13", style: "bold" },
        { text: "Rupees " + Data["amount_paid_in_words"].split('Rupees').join(''), size: "13", style: "normal" }
      ];
      this.putMulti(40,tableY + 20,textArray8,{ align: "left", width: "600" },this.doc);
  
      this.doc.setLineWidth(0.2);
      this.doc.setDrawColor(0, 0, 0);
      this.doc.rect(20, tableY + 55, 555, 20); // mode of payment box
      const textArray9 = [
        { text: "Mode of Payment : ", size: "13", style: "bold" },
        { text: Data["payment_mode"].split('Payment').join('').trim(), size: "13", style: "normal" }
      ];
      this.putMulti(40,tableY + 70, textArray9, { align: "left", width: "600" }, this.doc);
      if (Data["payment_mode"].split('Payment').join('').trim() == "Online" || Data["payment_mode"] == "Cheque" || Data["payment_mode"] == "DD") {
        var id = ""
        if(Data["payment_mode"].split('Payment').join('').trim() == "Online" ){
          id = "Transaction Id : "
        }else if(Data["payment_mode"] == "Cheque"){
          id = "Cheque No : " 
        }else if(Data["payment_mode"] == "DD"){
          id = "DD No : " 
        }

        this.doc.line(287, 60 + tableY, 287, 50 + tableY + 25); // mode of payment box mid line
        const transactionIdArray = [
          { text: id, size: "13", style: "bold" },
          { text: Data["transaction_id"], size: "13", style: "normal" }
        ];
        this.putMulti(298,tableY + 70,transactionIdArray,{ align: "left", width: "600" },this.doc);
      }
      this.doc.setFontSize(14);
      if ("concession_amount" in Data) {
        const concessionArray = [
          { text: "*Overall Concession : ", size: "13", style: "bold" },
          { text: String(Data["concession_amount"]), size: "13", style: "normal" }
        ];
        this.putMulti(240,tableY + 90,concessionArray, { align: "left", width: "600" },this.doc);
        this.doc.setFontType("bold");
        this.doc.setFontSize(11.6);
        this.getAlignmentValue(40,tableY + 107,"Note: This is a Computerised Fee Receipt and does not required manual signature.",{ align: "left", width: "595" });
      }else{
        this.doc.setFontType("bold");
        this.doc.setFontSize(11.6);
        this.getAlignmentValue(40,tableY + 90,"Note: This is a Computerised Fee Receipt and does not required manual signature.",{ align: "left", width: "595" });
      }
      this.doc.setFontSize(12);
      this.getAlignmentValue(40, tableY + 160, "Date: " + Data["paid_on"], { align: "left", width: "595"});
      this.doc.rect(20, 15, 555, tableY + 155); // outer box
    }
    /**
     * fuction used to align a text in the pdf doc
     * @param x x-position
     * @param y y-position
     * @param text the text to be added
     * @param options used for alignment: center | left | right
     */
    getAlignmentValue(x: any, y: any, text: string, options: any) {
      var options = options || {};
      var defaults = {align: "left",width: this.doc.internal.pageSize.width };
      var settings = $.extend({}, defaults, options);
      var fontSize = this.doc.internal.getFontSize();
      var text_size =
        (this.doc.getStringUnitWidth(text) * fontSize) /
        this.doc.internal.scaleFactor;
      if (settings.align === "center") x += (settings.width - text_size) / 2;
      else if (settings.align === "right") x += settings.width - text_size;
      this.doc.text(text, x, y);
    }
    /**
     * Puts a line of text in a document
     * @param x X-coordinate in a page
     * @param y Y-coordinate in a page
     * @param text String to be printed
     * @param options Styling options
     * @param doc The document in which the texts must be printed
     */
    putText(x: any, y: any, text: string, options: any, doc: any) {
      var options = options || {};
      var defaults = {
        align: "left",
        width: doc.internal.pageSize.width,
        size: 8,
        style: "normal"
      };
      var settings = $.extend({}, defaults, options);
      var fontSize = settings.size;
      doc.setFontSize(fontSize);
      doc.setFontStyle(settings.style);
      var text_size =
        (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
      if (settings.align === "center") {
        x += (settings.width - text_size) / 2;
      } else if (settings.align === "right") x += settings.width - text_size;
      doc.text(text, x, y);
    }
    /**
     * Puts A line of text with multiple styles
     * @param x X-coordinate in a page
     * @param y Y-coordinate in a page
     * @param textArray Array of strings to be printed
     * @param options Styling option
     * @param doc The document in which the texts must be printed
     */
    putMulti(x: any, y: any, textArray: Array<any>, options: any, doc: any) {
      // putText(x,y,doc){
      var options = options || {};
      var defaults = {
        align: "left",
        width: doc.internal.pageSize.width
      };
      var text = "";
      textArray.forEach(e => {
        text += e.text;
      });
      var settings = $.extend({}, defaults, options);
      var fontSize = doc.internal.getFontSize();
      var text_size =
        (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
      if (settings.align === "center") x += (settings.width - text_size) / 2;
      else if (settings.align === "right") x += settings.width - text_size;
  
      textArray.forEach(e => {
        doc.setFontSize(e.size);
        doc.setFontStyle(e.style);
        doc.text(e.text, x, y);
        x += (doc.getStringUnitWidth(e.text) * e.size) / doc.internal.scaleFactor;
      });
    }

    createTimeTableExcel(data, filename, headers){
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      var sheet = workbook.addWorksheet("Time Table",{pageSetup:{paperSize: 9, orientation:'portrait',fitToPage: true}});

      for(var i=0;i<data.length;i++){
        sheet.getCell(data[i]["row"],data[i]["column"]).value = data[i]["value"];
        sheet.getCell(data[i]["row"],data[i]["column"]).style.alignment = {horizontal: "center", vertical:"middle", wrapText: true};
        sheet.getCell(data[i]["row"],data[i]["column"]).border = {
          top: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        if( data[i]["row"] == 3 || data[i]["column"] == 1){
          sheet.getCell(data[i]["row"],data[i]["column"]).font = {bold: true};
        }
        if(data[i]["cell_merged"]){
          sheet.mergeCells(data[i]["start_row"],data[i]["start_col"],data[i]["end_row"],data[i]["end_col"])
        }
      }

      sheet.columns.forEach(function (column, i) {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength ) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });
      
      for(var i=0;i<headers.length;i++){
        var row = (i+1)
        sheet.getCell(row,1).value = headers[i];
        sheet.getCell(row,1).style.alignment = {horizontal: "center", vertical:"middle", wrapText: true};
        sheet.getCell(row,1).font = {size: 15,bold:true}
        sheet.mergeCells(row,1,row,sheet.columnCount)
      }

      workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, filename + '.xlsx');
      });
    }
}