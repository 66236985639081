import { Component, OnInit, Input, Directive, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router'
import { RouterService } from '../../services/routerService'
import { ContextService } from '../../services/contextService'
import { ConstantsService } from '../../services/constantsService'
import { TranslateService } from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'main-card',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

export class MainCardComponent implements AfterViewInit {
  sidenav: Array<any> = ["List Institutions","Manage Institutions"];
  @Input() cardHeader:string= ''; 
  @Input() subHeader:string = '';
  hide: Boolean = false;
  constructor() {
   }

   ngAfterViewInit(){
    if( this.cardHeader == '' || this.cardHeader == undefined){
    this.hide=true
    }else if (this.subHeader.length > 0  ){
      this.hide= false
    }
   }

}
