import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

@Injectable()

export class AppSpinner {
  spinnerText: any;
  isShown: any = false;
  startTime: Date;
  constructor() {
    this.spinnerText = "Loading...";
  }
  show(message) {
    if (typeof message !== "undefined" && message !== "") {
      this.spinnerText = message;
    } else {
      this.spinnerText = "Loading...";
    }
    this.startTime = new Date();
    this.isShown = true;
  }
  hide() {
    let currentTime: Date = new Date();
    var timeDifference = (currentTime.getMilliseconds() - this.startTime.getMilliseconds());
    setTimeout(() => {
      this.isShown = false;
    }, timeDifference < 1000 ? 1000 : timeDifference);
  }
}
