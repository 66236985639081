import { Injectable } from "@angular/core";
import { HttpService } from "./httpService";
import * as $ from 'jquery';
import * as jsPDF from 'jspdf';
import "jspdf-autotable";
import { gsss } from "assets/images/gsss";
import {BenneRegular} from 'assets/fonts/jspdf_font/Benne-Regular-normal.js';
import {BalooTamma2} from 'assets/fonts/jspdf_font/BalooTamma2-Regular-normal.js';
import {BalooTammaBold} from 'assets/fonts/jspdf_font/BalooTamma2-Bold-normal.js';
import {Kedage} from 'assets/fonts/jspdf_font/Kedage-normal.js'
import {KedageBold} from 'assets/fonts/jspdf_font/Kedage_Bold-normal.js'
import {kannada} from 'assets/fonts/jspdf_font/Kannada.js'
import { SCPUC_logo } from "assets/images/SCPUC_logo";
import {prataRegular} from 'assets/fonts/jspdf_font/Prata-Regular-normal.js';
import { SCCMC_logo } from "assets/images/SCCMC_logo";
import { SCDCB_logo } from "assets/images/SCDCB_logo";

@Injectable()
export class pdfCertifcateGenerateService {
  constructor(private httpService: HttpService) { }


  downloadCertificates(data){
    let keyName = Object.keys(data)
    // console.log(keyName[0],"key")

    switch(keyName[0]) {
        case 'SCPHAS1':
            this.downloadPramatiStudyCertificate(data[keyName[0]]);
            break;
        case 'SCPHAE1':
            this.downloadPramatiYearEndCertificate(data[keyName[0]]);
            break;
        case 'TCPHA1':
            this.downloadPramatiTC(data[keyName[0]]);
            break;
        case 'TCSCPUC19-1':
            this.downloadSiliconTransferCertificate(data[keyName[0]]);
            break;
        case 'SCGSSS1-1':
            this.downloadGsssYearEndCertificate(data[keyName[0]],keyName[0]);
            break;
        case 'TCGSSS1-1':
            this.downloadGsss1Tc(data[keyName[0]],keyName[0]);
            break;
        case 'SCGSSS2-1':
            this.downloadGsssYearEndCertificate(data[keyName[0]],keyName[0]);
            break;
        case 'TCGSSS2-1':
            this.downloadGsss1Tc(data[keyName[0]],keyName[0]);
            break;
        case 'SCGSSS3-1':
            this.downloadGSSS3StudyCertificateHeader(data[keyName[0]]);
            break;
        case 'TCSCDCB19-1':
            this.downloadSiliconCollegeTransferCertificate(data[keyName[0]]);
            break;
        case 'SCSCDCB19-1':
            this.downloadSCDCBStudyCertificateHeader(data[keyName[0]]);
            break;
        // case 'SCGSSS1-3':
        //     this.downloadGSSS1ANDGSSS2studyCertificate(data[keyName[0]],keyName[0]);
        //     break;
        // case 'TCGSSS3-1':
        //     this.downloadGsss3Tc(data[keyName[0]]);
        //     break;
        // case 'SCGSSS2':
        //     this.downloadGSSS2studyCertificate(data[keyName[0]],keyName[0]);
        //     break;
      default:
        break;
    }
  }

  downloadPramatiStudyCertificate(data){
    // let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.2, });
    let doc = new jsPDF('p', 'pt', 'a4');
    this.getPramatiStudyCertificateHeader(data,doc);
    doc.save("Study Certificate")
   }

   getPramatiStudyCertificateHeader(Data, doc) {
    doc.setLineWidth(0.5);
    //headers
    doc.setFontSize(14);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(doc, 20, 140, 'STUDY CERTIFICATE', { align: 'center', width: '595' });
    doc.line(245, 142, 390, 142);

    doc.setFontSize(12);
    doc.setFontType('normal');
    var certificateContent1 = `       This is to certify that, **${Data['Student Name']}** ${Data['Relationship']}`;
    if (Data['Father Name'] != '' && Data['Mother Name'] != '') {
      certificateContent1 = certificateContent1 + ` **Mr. ${Data['Father Name']}** and **Mrs. ${Data['Mother Name']}** is studying in`;
    } else if (Data['Father Name'] != '') {
      certificateContent1 = certificateContent1 + ` **Mr. ${Data['Father Name']}** is studying in`;
    } else if (Data['Mother Name'] != '') {
      certificateContent1 = certificateContent1 + ` **Mrs. ${Data['Mother Name']}** is studying in`;
    }
    // if level name is 1th
    if (/^\d\s*(th|TH|tH|Th)$/.test(Data['Current Class'])) {
      var array = Data['Current Class'].split(/(?<=\d)(?=[A-Za-z])/);
      if (array.length > 1) {
        certificateContent1 = certificateContent1 + ` **${array[0]}**`;
      }
    } else {
      certificateContent1 = certificateContent1 + ` **${Data['Current Class']}**`;
    }
    if (Data['Current Class'] == 'LKG' || Data['Current Class'] == 'PREKG' || Data['Current Class'] == 'UKG' || Data['Current Class'] == 'Prekg' || Data['Current Class'] == 'Lkg' || Data['Current Class'] == 'Ukg') {
      certificateContent1 = certificateContent1 + ` Std`;
    }
    certificateContent1 = certificateContent1 + ` in our institution. ${String(Data['pronouns'][0]).charAt(0).toUpperCase() + String(Data['pronouns'][0]).slice(1)} Date of Birth is **${Data['Date of birth']}** as per the admission register of the Institution.`;
    
    
    if ('Address' in Data) {
        certificateContent1 = certificateContent1 + '\n\n'+`       ${String(Data['pronouns'][0]).charAt(0).toUpperCase() + String(Data['pronouns'][0]).slice(1)} residential address is **${Data['Address']}**.`;
    }
    
    if ('SC ST' in Data) {
        certificateContent1 = certificateContent1 + '\n\n' +`       ${String(Data['pronouns'][2]).charAt(0).toUpperCase() + String(Data['pronouns'][2]).slice(1)} belongs to **${Data['Caste']}**`;
        if(Data['SC ST'] != null){
            certificateContent1 = certificateContent1 + ` **(${Data['SC ST']})**`
        }
        certificateContent1 = certificateContent1 + ` caste.`
    }

    if ('Purpose' in Data) {
        certificateContent1 = certificateContent1 + '\n\n' + `       This is purely for **${Data['Purpose']}**.`;
    }

    if ('SATS No' in Data) {
        certificateContent1 = certificateContent1 + '\n\n' +`       ${String(Data['pronouns'][0]).charAt(0).toUpperCase() + String(Data['pronouns'][0]).slice(1)} SATS number is **${Data['SATS No']}**.`;
    }
    this.multiLineNormalAndBoldText(12,20,75,240,540,certificateContent1,doc);
    let lastYvalue = 0 + doc.getTextDimensions(certificateContent1).h;
    //footer
    this.putText(485,425 + lastYvalue + 45,'PRINCIPAL',{ align: 'left', size: 12, style: 'bold' },doc);
    this.putText(45,425 + lastYvalue + 45,`Date : ${Data['Today Date']}`,{ align: 'left', size: 12, style: 'bold' },doc);
    this.putText(45,425 + lastYvalue + 70,`Place : ${Data['Place']}`,{ align: 'left', size: 12, style: 'bold' },doc);
  }

    /*
    * Pramati year End Certificate
    */
    downloadPramatiYearEndCertificate(data){
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.2 });
        this.getPramatiYearEndCertificateHeader(data,doc);
        doc.save("Year End Certificate")
    }

    getPramatiYearEndCertificateHeader(Data, doc) {
        doc.setLineWidth(0.5);
        //headers
        doc.setFontSize(14);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        this.getAlignmentValue(doc, 20, 140, "STUDY CERTIFICATE", {align: 'center',width: '595'});
        doc.line(245, 142, 390, 142);

        doc.setFontSize(12);
        doc.setFontType('normal');
        var certificateContent1 = `       This is to certify that, **${Data['Student Name']}** ${Data['Relationship']}`
        if(Data['Father Name'] != '' && Data['Mother Name'] != ''){
        certificateContent1 = certificateContent1 + `** Mr. ${Data['Father Name']}** and **Mrs. ${Data['Mother Name']}**`;
        }
        else if (Data['Father Name'] != '') {
        certificateContent1 = certificateContent1 + ` **Mr. ${Data['Father Name']}**`;
        }
        else if (Data['Mother Name'] != '') {
        certificateContent1 = certificateContent1 + ` **Mrs. ${Data['Mother Name']}**`;
        }

        if (Data['Academic Year'] != Data['Admitted Year']) {
            certificateContent1 = certificateContent1 + ` studied from **${Data['Admitted Class']}** to **${ Data['Current Class']}** in our institution during the academic years from **${Data['Admitted Year'].slice(0,4)}** - **${Data['Academic Year'].slice(0,4)}**.`
        }
        if (Data['Academic Year'] == Data['Admitted Year']) {
            certificateContent1 = certificateContent1 + ` studied **${ Data['Current Class']}** in our institution during academic year **${Data['Academic Year']}**.`
        }

        certificateContent1 = certificateContent1 + "\n\n" + `       The mother tongue of the candidate is **${Data["Mother Tongue"]}** as per the admission register of the Institution.`

        //need to achieve bold style for dynamic values
        this.multiLineNormalAndBoldText(12,17,90,200,570,certificateContent1,doc);
        // doc.text(certificateContent1,45, 200,{align:'justify',maxWidth:500});
        // doc.text(certificateContent2,75, 255,{align:'justify',maxWidth:500});

        //footer
        this.putText(485,450,"PRINCIPAL",{align:'left',size: 10,style:'bold'},doc);
        this.putText(90,450,`Date : ${Data["Today Date"]}`,{align:'left',size: 10,style:'bold'},doc);
        this.putText(90,480,`Place : ${Data["Place"]}`,{align:'left',size: 10,style:'bold'},doc);
        
    }

    /*
     *  Pramati Transfer certificate
     */
    downloadPramatiTC(data){
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.2 });
        this.getPramatiTransferCertificate(data,doc);
        doc.save("Transfer Certificate")
    }

    getPramatiTransferCertificate(Data,doc){
        //headers
        doc.setLineWidth(1.5);
        doc.roundedRect(135, 25 , 345, 30,3,3,'S'); // title  box
        doc.setFontSize(18);
        doc.setFontType("bold");
        doc.setTextColor(0, 0, 0);
        var certificateName = "TRANSFER CERTIFICATE"
        if(Data["Duplicate"]){
        var certificateName = "DUPLICATE TRANSFER CERTIFICATE"
        }
        this.getAlignmentValue(doc,5, 45 , `${certificateName}`, {align: "center",width: "595"});

        const tcNumber = [
            { text: 'TC No. : ', size: '12', style: 'normal' },
            { text: `${Data["Certificate No"]}/ ${Data["Certificate Postfix"]}`, size: '12', style: 'bold' }
        ];
        this.putMulti(40, 90, tcNumber, { align: 'left',size:12 }, doc);

        const admissionNumber = [
            { text: 'Admission No. : ', size: '12', style: 'normal' },
            { text: `${Data["Admission No"]}`, size: '12', style: 'bold' }
        ];
        this.putMulti(40, 115, admissionNumber, { align: 'left',size:12 }, doc);

        const date = [
            { text: 'Date : ', size: '12', style: 'normal' },
            { text: `${Data["Today Date"]}`, size: '12', style: 'bold' }
        ];
        this.putMulti(400, 90, date, { align: 'left',size:12 }, doc);
        const diseCode = [
            { text: 'Dise Code : ', size: '12', style: 'normal' },
            { text: `${Data["DISE Code"]}`, size: '12', style: 'bold' }
        ];
        this.putMulti(400, 115, diseCode, { align: 'left',size:12 }, doc);
        var startY = 115 + 10; // table starting y-coordinate
        this.getPramatiTcDataTable(Data,doc,startY);

    }

    getPramatiTcDataTable(Data,doc,yAxis){
        // table
        var row = [];
        let column = ["",""];
        row.push([ "1. Name of the Pupil",`${Data["1. Name of the pupil"]}`]);
        row.push(["2. STS No",`${Data["2. STS No"]}`]);
        row.push(["3. Father's Name",`${Data["3. Father Name"]}`]);
        row.push(["4. Mother's Name",`${Data["4. Mother Name"]}`]);
        // Titlesize the right side value
        row.push([`5. Date of Birth (in figures) : ${Data["5. Date of Birth (in figures)"]} `,`(in words) : ${Data["Date of Birth In Words"]}`]);
        row.push(["6. Gender",`${Data["6. Gender"]}`]);
        row.push(["7. Nationality",`${Data["7. Nationality"]}`]);
        row.push(["8. Religion",`${Data["8. Religion"]}`]);
        row.push(["9. Caste",`${Data["9. Caste"]}`]);
        row.push(["10. Whether the candidate belongs to Scheduled Caste or Scheduled Tribe",`${Data["10. Whether the candidate belongs to Scheduled Caste or Scheduled Tribe"]}`]);
        row.push(["11. Class in which the student last studied",`${Data["11. Class in which the student last studied"]}`]);
        row.push(["12. Medium of Instruction",`${Data["12. Medium of Instruction"]}`]);
        var languages_studied = ''
        for(var i=0;i< Data["13. Languages studied"].length;i++){
            languages_studied = languages_studied + ` ${Data["13. Languages studied"][i]}`
            if(i != Data["13. Languages studied"].length-1){
                languages_studied = languages_studied + ',';
            }
        }
        row.push(["13. Languages studied",languages_studied]);
        var subjects_studied = ''
        for(var i=0;i<Data["14. Subjects studied"].length;i++){
            subjects_studied = subjects_studied + `${i+1}. ${Data["14. Subjects studied"][i]}\n`
        }
        row.push(["14. Subjects studied",subjects_studied]);
        row.push(["15. Whether qualified for promotion to a higher class",`${Data["15. Whether qualified for promotion to higher class"]}`]);
        row.push(["16. Total No of working days",`${Data["16. Total No of working days"]}`]);
        row.push(["17. Total no of working days attended",`${Data["17. Total no of working days attended"]}`]);
        row.push(["18. Date of pupils last attendance at school",`${Data["18. Date of pupils last attendance at school"]}`]);
        row.push(["19. Date of Application for T C",`${Data["19. Date of Application for TC"]}`]);
        row.push(["20. Date of Issue of T C",`${Data["20. Date of Issue of TC"]}`]);
        row.push(["21. Character and conduct",`${Data["21. Character and Conduct"]}`]);   
        
    
        var cellStyles = {
            fontSize: 10,
            fillColor: false,
            textColor: 0,
            halign: "left",
            valign: "middle",
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 6
        };
    
        var headCellStyles = {
            fontSize: 5,
            fillColor: false,
            textColor: 0,
            halign: "center",
            valign: "middle",
            lineWidth: 0,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 0
        };
        doc.autoTable(column, row, {
        margin: 45,
        startY: yAxis,
        headStyles: headCellStyles,
        bodyStyles: cellStyles,
        styles: cellStyles,
        alternateRowStyles: cellStyles,
        didParseCell: function(data) {
            var rows = data.table.body;
            rows[12].cells[1].styles.fontStyle = 'bold';
            rows[13].cells[1].styles.fontStyle='bold';
            rows[20].cells[1].styles.fontStyle='bold';
            
        },
        columnStyles: {
            0: {cellWidth: 280,},
            1: {cellWidth: 220,halign:'left'}
        }
        });
        //tableY is 'y-position' after table is populated
        let tableY = doc.lastAutoTable.finalY;
        this.putText(500,tableY+50,`Principal`,{align:'left',size: 12,style:'bold'},doc);
    }


    /*
    * Silicon TC certificate
    */
    downloadSiliconTransferCertificate(data) {
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.2 });
        this.getSiliconTcCertificateHeader(data, doc); 
        // doc.addPage(); // adding page for Office copy
        // this.getSiliconTcCertificateHeader(data, doc); 
        doc.save("Transfer certificate");
    }
    getSiliconTcCertificateHeader(Data, doc) {
        var logo = new Image();
        doc.setTextColor(0, 0, 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(1.5);
        // Need to Chnage logo
        doc.addImage(SCPUC_logo, 'png', 35, 15, 75, 70); // base64 logo

        //headers
        doc.setFontSize(16);
        doc.setFontType('bold');
        this.getAlignmentValue(doc, 20, 45, Data["branch_name"], {align: 'center',width: '595'});

        doc.setFontSize(12);
        this.getAlignmentValue(doc, 20, 60, Data["institue_address"], { align: 'center',width: '595' });
        doc.line(40, 90, 560, 90);

        doc.roundedRect(155, 105, 310, 25, 3, 3, 'S'); // title  box
        doc.setFontSize(16);
        var certificateName = 'TRANSFER CERTIFICATE'
        if(Data["Duplicate"]){
            certificateName = 'DUPLICATE TRANSFER CERTIFICATE';
        }
        
        this.getAlignmentValue(doc, 5, 120, `${certificateName}`, {align: 'center',width: '595'});

        var startY = 120 + 30;
        this.getSiliconTcDataTable(Data, doc, startY);
        this.putText(45, startY - 5,`T.C. No. : ${Data["Certificate No"]}/${Data["Certificate Postfix"]} `,{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(450,startY - 5,`Date: ${Data["19. Today Date"]}`,{ align: 'left', size: 10, style: 'bold' },doc);
    }

    getSiliconTcDataTable(Data, doc, yAxis) {
        // table
        var row = [];
        let column = ['', ''];

        row.push(['1. Name of the student in full (in capital letters)',Data['3. Name of the Student in full (in capital letter)']]);
        row.push(['2. Admission No. & Date of Admission',`${Data["2. Admission No & Date of Admission"]}\n${Data["Date of Admission"]}`]);
        // Titlesize the right side value
        row.push(['3. Date of Birth', Data['4. date of Birth']]);
        row.push(["4. Father's Name", Data["5. Father's Name"]]);
        row.push(["5. Mother's Name", Data["6. Mother's Name"]]);
        row.push(['6. Sex', Data['7. sex']]);
        row.push(['7. Nationality', Data['8. Nationality']]);
        row.push(['8. Religion', Data['9. Religion']]);
        row.push(['9. Caste', Data['10. Caste']]);
        row.push(['10. Whether the candidate belongs to Scheduled\nCaste or Scheduled Tribe',Data['11. Whether the Candidate belongs to Scheduled caste/Scheduled Tribe']]);
        row.push(['11. Whether qualified for promotion to a higher standard',Data['12. Whether qualified for promotion for higher Studies']]);
        row.push(['12. Part - I: Standard in which the student was studying at the time of leaving the college',Data['13. Part-1: Standard in which the student was studying at the time of leaving the college']]);
        row.push(['Part - II: Exam details', `Reg No.: ${Data["exam_reg_no"]}, Month: ${Data["exam_month"]}, Year: ${Data["exam_year"]}`]);
        row.push(['13. Date of Leaving the College',Data['14. date of leaving the College']]);
        row.push(['14. Subjects:\n Part - I: Languages',Data['15. Subject PART - I: Language']]);
        row.push(['Part - II: Optionals', Data['PART - II: Optionals']]);
        row.push(['15. Medium of Instruction', Data['16. Medium of Instruction']]);
        row.push(['16. Fee concession, \nif any ( nature and period to be specified )',Data['17. Fee Concession, if any ( nature and period to be specified )']]);
        row.push(['17. Character and Conduct', Data['18. Character and Conduct']]);

        var cellStyles = {
            fontSize: 10,
            fillColor: false,
            textColor: 0,
            halign: 'left',
            valign: 'middle',
            lineWidth: 0.1,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 5,
            rowHeight: 1
        };

        var headCellStyles = {
            fontSize: 5,
            fillColor: false,
            textColor: 0,
            halign: 'left',
            valign: 'middle',
            lineWidth: 0,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 0,
            rowHeight: 0
        };

        doc.autoTable(column, row, {
            margin: 45,
            startY: yAxis,
            headStyles: headCellStyles,
            bodyStyles: cellStyles,
            styles: cellStyles,
            alternateRowStyles: cellStyles,
            didParseCell: function(data) {
                var rows = data.table.body;
                //style each cells
                rows[0].cells[1].styles.fontStyle = 'bold';
                rows[1].cells[1].styles.fontStyle = 'bold';
                rows[2].cells[1].styles.fontStyle = 'bold';
                rows[3].cells[1].styles.fontStyle = 'bold';
                rows[4].cells[1].styles.fontStyle = 'bold';
                rows[5].cells[1].styles.fontStyle = 'bold';
                rows[6].cells[1].styles.fontStyle = 'bold';
                rows[7].cells[1].styles.fontStyle = 'bold';
                rows[8].cells[1].styles.fontStyle = 'bold';
                rows[9].cells[1].styles.fontStyle = 'bold';
                rows[10].cells[1].styles.fontStyle = 'bold';
                rows[11].cells[1].styles.fontStyle = 'bold';
                rows[14].cells[1].styles.fontStyle = 'bold';
                rows[15].cells[1].styles.fontStyle = 'bold';
                rows[16].cells[1].styles.fontStyle = 'bold';
                rows[17].cells[1].styles.fontStyle = 'bold';
                rows[18].cells[1].styles.fontStyle = 'bold';
            },
            columnStyles: {
                0: { cellWidth: 280 },
                1: { cellWidth: 220, halign: 'left' }
            }
        });

        //tableY is 'y-position' after table is populated
        let tableY = doc.lastAutoTable.finalY;
        this.putText(400,tableY + 35,'Seal & Signature of the Principal',{ align: 'left', size: 10, style: 'bold'},doc);
        this.putText(50,tableY + 35,'Verified By',{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(50,tableY + 65,'Signature',{ align: 'left', size: 10, style: 'bold' },doc);
        // var copyName = 'Student Copy';
        // var pageCount = doc.internal.getNumberOfPages();
        // if (pageCount == 2) { 
        //     // if document page is 2 then its office copy
        //     copyName = 'Office Copy';
        // }
        // this.putText(50,tableY + 95,`${copyName}`,{ align: 'left', size: 8, style: 'bold' },doc);
    }

/**
 *  Gsss2 & GSSS1 study certificate
 */

    downloadGSSS1ANDGSSS2studyCertificate(data,templateCode){  
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 2 });
        // add the font to jsPDF
        doc.addFileToVFS('BalooTamma2-Bold.ttf', BalooTammaBold);
        doc.addFont('BalooTamma2-Bold.ttf', 'BalooTamma2', 'bold');
        this.getGsss2StudyCertificateHeader(data,templateCode,doc);
        doc.save(data["filename"])
    }
    
    getGSSS1ANDGSSS2StudyCertificateHeader(Data,templateCode,doc) {
        doc.setLineWidth(0.5);
        // var logo = new Image();
        // logo.src = 'assets/images/bms.png';
        doc.addImage(gsss, "png", 45, 35 , 75, 70); // logo
        
        doc.setFontSize(8);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
    
        
        var presidentName = 'Dr. M. JAGANNATHA SHENOI';
        this.putText(35,125,`${presidentName}`,{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(80,138,`President`,{ align: 'left', size: 10, style: 'bold' }, doc);
    
        var secretaryName = 'Smt. VANAJA B. PANDIT';
        this.putText( 430,125,`${secretaryName}`, { align: 'left', size: 10, style: 'bold' }, doc);
        this.putText( 450,138,`Hon. Secretary`,{ align: 'left', size: 10, style: 'bold' }, doc);
    
        
        this.getAlignmentValue(doc, 10, 45, `S.NO. 29/75-76`, { align: 'center', width: '595'});
        doc.setFontSize(12);
        this.getAlignmentValue(doc, 10, 55, Data["institute_name"], { align: 'center', width: '595' });
        if(templateCode == 'SCGSSS1-3'){
            doc.setFontSize(16);
        }
        if(templateCode == 'SCGSSS2'){
            doc.setFontSize(12);
        }
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        this.getAlignmentValue(doc, 10, 70, Data["branch_name"], {align: 'center',width: '595'});
    
        doc.setFont('BalooTamma2')
        if(templateCode == 'SCGSSS1-3'){
            this.getAlignmentValue(doc,10,85,'ಗೀತಾ ಭಾರತಿ ಶಾಲೆ',{ align: 'center',width: '595'});
        }
        if(templateCode == 'SCGSSS2'){
            this.getAlignmentValue(doc,10,85,'ಬಂಟ್ವಾಳ್ ಮಾಧವ ಶೆಣೈ ಪ್ರೌಢಶಾಲೆ',{ align: 'center',width: '595'});
        }
        
        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        
        this.getAlignmentValue(doc, 10, 100, Data["institue_address"], { align: 'center',width: '595'});
    
        var phone = '(0821) 2472452';
        var fax = '0821 - 2476893 ';
        var email1 = 'geethashishu@yahoo.co.in';
        var email2 = 'geethashishuoffice@gmail.com';
        const phoneNumberArray = [
            { text: 'Phone No. : ', size: '8', style: 'bold' },
            { text: phone, size: '8', style: 'normal' },
            
        ];
        this.putMulti(440, 30, phoneNumberArray, { align: 'left' }, doc);
    
        const faxArray = [
            { text: 'Fax No.: ', size: '8', style: 'bold' },
            { text: fax, size: '8', style: 'normal' }
        ];
        this.putMulti(440, 40, faxArray, { align: 'left' }, doc);
    
        const emailArray = [
            { text: 'E-mail : ', size: '8', style: 'bold' },
            { text: email1, size: '8', style: 'normal' }
        ];
        this.putMulti(440, 50, emailArray, { align: 'left' }, doc);
        this.putText( 470,60,email2, { align: 'left', size: 8 },doc);
    
    //double line after header
        doc.setLineWidth(1.5);
        doc.line(40, 150, 560, 150);
        doc.setLineWidth(0.5);
        doc.line(40, 153, 560, 153);
    
        var RefNumber = '';
        if(templateCode == 'SCGSSS1-3'){ 
            RefNumber = 'GBS/2021-22/'
        }
        if(templateCode == 'SCGSSS2'){ 
            RefNumber = 'BMSHS/2021-22/'
        }
        
        this.putText(45,185, `Ref No. ${RefNumber}`, { align: 'left', size: 10, style: 'bold' }, doc);
        this.getAlignmentValue(doc, -40, 185, `Date: ${Data["Today Date"]}`, {align: 'right',width: '595'});
        doc.setFontSize(14);
        var certificateName = 'STUDY CERTIFICATE';
        this.getAlignmentValue(doc, 5, 240, `${certificateName}`, {align: 'center',width: '595'});
    
    
        doc.setFontSize(12);
        doc.setFontType('normal');
    
        let content1 = `              This is to certify that, **${Data["Student Name"]}** ${Data["Relationship"]} `
        if(Data["Father Name"] !='' && Data["Mother Name"] !=''){
        content1 = content1 + `**Mr. ${Data["Father Name"]}** and **Mrs. ${Data["Mother Name"]}** is studying in`
        }else if(Data["Father Name"] !=''){
        content1 = content1 + `**Mr. ${Data["Father Name"]}** is studying in`
        }else if(Data["Mother Name"] !=''){
        content1 = content1 + `**Mrs. ${Data["Mother Name"]}** is studying in`
        }

        if (/^\d\s*(th|TH|tH|Th)$/.test(Data['Current Class'])) {
            var array = Data['Current Class'].split(/(?<=\d)(?=[A-Za-z])/);
            if (array.length > 1) {
                content1 = content1 + ` **${array[0]}**`;
            }
        } else {
            content1 = content1 + ` **${Data['Current Class']}**`;
        }

        if (Data['Current Class'] != 'LKG' ||Data['Current Class'] != 'PREKG' ||Data['Current Class'] != 'UKG' ||Data['Current Class'] != 'Prekg' ||Data['Current Class'] != 'Lkg' ||Data['Current Class'] != 'Ukg') {
            content1 = content1 +` Std in the academic year **${Data["Academic Year"]}** in our institution.`;
        }

        if (Data['Current Class'] == 'LKG' ||Data['Current Class'] == 'PREKG' ||Data['Current Class'] == 'UKG' ||Data['Current Class'] == 'Prekg' ||Data['Current Class'] == 'Lkg' ||Data['Current Class'] == 'Ukg') {
            content1 =content1 + ` in the academic year **${Data["Academic Year"]}** in our institution. `;
        }
        
        content1 = content1 + `As per our records ${String(Data["pronouns"][0]).charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)} date of birth is **${Data["Date of birth"]}**. `
        content1 =  content1 +`${String(Data["pronouns"][0]).charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)} admission number is **${Data["Admission No"]}**. `
        
        if ('Address' in Data) {
            content1 = content1 + `${Data["pronouns"][0].charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)}  residential address is **${Data["Address"]}**.`;
        }
        
        if ('SC ST' in Data) {
            content1 = content1 + ` ${Data["pronouns"][2].charAt(0).toUpperCase() + String(Data["pronouns"][1]).slice(1)} belongs to `
            if(Data['SC ST'] != null){
                content1 = content1 + `**${Data["Caste"]} (${Data['SC ST']})** caste.`
            }else{
                content1 = content1 + `**${Data["Caste"]}** caste.`
            }
        }
    
        if ('SATS No' in Data) {
            content1 = content1 + ` ${Data["pronouns"][0].charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)} SATS number is `
            if(Data['SATS No'] != null && Data['SATS No'] != ""){
                content1 = content1 + `**${Data["SATS No"]}**.`
            }
        }
        doc.setFontType('normal');
        content1 = content1 + '\n\n' + `              This is purely for ${Data["purpose"]}`;
        //need to achieve bold style for dynamic values
        doc.setFontSize(12);
        this.multiLineNormalAndBoldText(12,20,75,450,540,content1,doc);
        // doc.text(certificateContent, 45, 450, { align: 'justify', maxWidth: 450 });
        // doc.text(certificatePurpose, 45, 595, { align: 'justify', maxWidth: 450 });
    
        var instituteHeadFacultyName = "Hemalatha S R"
        var designation = "Principal";
        if(templateCode == 'SCGSSS1-3'){ 
        instituteHeadFacultyName = "Sumana Priyavratha";
        designation = "Headmistress";
        }
        if(templateCode == 'SCGSSS2'){ 
        instituteHeadFacultyName = "Hemalatha S R";
        designation = "Principal";
        }
        this.putText(455,700,`(${instituteHeadFacultyName})`,{ align: 'left', size: 12, style: 'bold' },doc);
        this.putText(475, 715, `${designation}`, { align: 'left', size: 12, style: 'bold' }, doc);
        
    }
/**
 *  Gsss1 Year End Certificate 
 */
  downloadGsssYearEndCertificate(data,templateCode){
    let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.5 });
    this.getYearEndGsss1CertificateHeader(data, doc, templateCode);
    doc.save("Year End Certificate")
  }
  getYearEndGsss1CertificateHeader(Data, doc, templateCode) {
    doc.setFont("helvetica")
    doc.setLineWidth(0.5);
    // var logo = new Image();
    // logo.src = 'assets/images/bms.png';
    doc.addImage(gsss, 'png', 45, 45, 65, 60); // logo

    //headers
    doc.setFontSize(9);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);

    doc.setLineWidth(2.5);
    doc.rect(20, 30, 555, 760); // outer box
    doc.setLineWidth(0.5);
    doc.rect(25, 35, 545, 750); // inner-outer box

    this.getAlignmentValue(doc, 10, 65, Data["institute_name"], {align: 'center',width: '595'});
    if(templateCode == "SCGSSS1-1"){
        doc.setFontSize(15);
        this.getAlignmentValue(doc, 10, 80, Data["branch_name"], {align: 'center', width: '595'});
    }
    if(templateCode == "SCGSSS2-1"){
        doc.setFontSize(12);
        this.getAlignmentValue(doc, 10, 80, Data["branch_name"], {align: 'center', width: '595'});
    }
    
    doc.setFontSize(9);
    this.getAlignmentValue(doc, 10, 95, Data["institue_address"], {align: 'center', width: '595'});
    doc.setFontSize(11);
    this.getAlignmentValue(doc, -65, 185, `Date: ${Data["Today Date"]}`, {align: 'right',width: '595'});

    doc.setFontSize(14);
    var certificateName1 = 'STUDY CERTIFICATE';
    this.getAlignmentValue(doc, 230, 160, `${certificateName1}`, {align: 'left',width: '595'});
    const textWidth = doc.getTextWidth(certificateName1); // To calculate the underline length
    doc.line(230, 163, 230 + textWidth, 163);

    doc.setFontSize(11);
    doc.setFontType('normal');
    var certificateContent = `      This is to certify that, ${Data["Student Gender"]} <b>${Data["Student Name"]}</b> ${Data["Relationship"]}`;
    if(Data['Father Name'] != '' && Data['Mother Name'] != ''){
      certificateContent = certificateContent +  ` Sri. <b>${Data["Father Name"]}</b> and Smt. <b>${Data["Mother Name"]}</b> has studied`
    }
    if(Data["Father Name"] != "" && Data['Mother Name'] == ''){
      certificateContent = certificateContent +  ` Sri. <b>${Data["Father Name"]}</b> has studied`
    }
    if(Data["Mother Name"] != "" && Data["Father Name"] == "") {
      certificateContent = certificateContent + ` Smt. <b>${Data["Mother Name"]}</b> has studied`;
    }

    if(Data['Admitted Year'] != Data['Academic Year']){
      certificateContent = certificateContent + ` <b>${Data['Admitted Class']}</b> to <b>${Data['Current Class']}</b> in our institution from <b>${Data['Admitted Year']}</b> to <b>${Data['Academic Year']}</b>.`;
    }
    if(Data['Admitted Year'] == Data['Academic Year']){
      certificateContent =certificateContent +` class <b>${Data['Current Class']}</b> in our institution during academic year <b>${Data['Academic Year']}</b>. `;
    }
    var certificateContent1 = `      ${Data["pronouns"][1]} belongs to <b>${Data["Caste"]}</b> Caste and mother tongue of the candidate is <b>${Data["Mother Tongue"]}</b> as per the admission register of the Institution.`;

    var certificateContent2 = `      The above details are true and correct to the best of my knowledge.`;

    doc.setFontSize(12);
    doc.fromHTML(
        '<p style ="line-height: 1.6rem;font-family: helvetica;white-space: pre">'+certificateContent+'</p> <p style ="line-height: 1.6rem;font-family: helvetica;">'+certificateContent1+'</p> <p style ="line-height: 1.6rem;font-family: helvetica;">'+certificateContent2+'</p>', 65, 195, { 'width': 460 });
    // this.multiLineWordStyle(certificateContent,12,20,65,220,500,doc);

    this.putText(365,410,`Signature of Head of the Institution`,{ align: 'left', size: 10, style: 'normal' },doc);
    this.putText( 250,440,`Name in BLOCK letters`,{ align: 'left', size: 10, style: 'normal' },doc);
    doc.line(365, 440, 560, 440);
    this.putText( 430,470,`Institution Seal`,{ align: 'left', size: 10, style: 'normal' },doc);

    doc.setFontSize(14);
    doc.setFontType('bold');
    var certificateNameHeader2 = 'CHARACTER CERTIFICATE';
    this.getAlignmentValue(doc, 210, 540, `${certificateNameHeader2}`, {align: 'left',width: '595'});
    const textLength = doc.getTextWidth(certificateNameHeader2);
    doc.line(210, 543, 210 + textLength, 543);

    doc.setFontSize(11);
    doc.setFontType('normal');

    var certificateContent4 = `      This is to certify that, <b>${Data["Student Gender"] + Data["Student Name"]}</b> ${Data["Relationship"]} `;
    if(Data['Father Name'] != '' && Data['Mother Name'] != ''){
      certificateContent4 = certificateContent4 +  `Sri. <b>${Data["Father Name"]}</b> and Smt. <b>${Data["Mother Name"]}</b>`
    }
    if(Data["Father Name"] != "" && Data['Mother Name'] == ''){
      certificateContent4 = certificateContent4 +  `Sri. <b>${Data["Father Name"]}</b> `
    }
    if(Data["Mother Name"] != "" && Data["Father Name"] == "") {
      certificateContent4 = certificateContent4 + `Smt. <b>${Data["Mother Name"]}</b> `;
    }

    if(Data['Academic Year'] == Data['Admitted Year']){
      certificateContent4 =certificateContent4 +` was student of this school during the years <b>${Data['Academic Year']}</b>.`;
    }
    if(Data['Academic Year'] != Data['Admitted Year']){
      certificateContent4 =certificateContent4 +` was student of this school during the years <b>${Data['Admitted Year']}</b> to <b>${Data['Academic Year']}</b>.`;
    }
    var certificateContent3 = '\n \n'+ `        ${Data["pronouns"][0].charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)} character and conduct was satisfactory during ${Data["pronouns"][0]} stay in this school.`;
    
    
    // console.log(doc.getFontList());
    doc.fromHTML(
        '<p style ="line-height: 1.6rem;font-family: helvetica;">'+certificateContent4+'</p> <p style ="line-height: 1.6rem;font-family: helvetica;">'+certificateContent3+'</p>', 65, 565, { 'width': 460 });
    // this.multiLineWordStyle(certificateContent4,12,20,65,580,510,doc);   

    this.putText(45,730,`Date : ${Data["Today Date"]}`,{ align: 'left', size: 11, style: 'bold' },doc);
    this.putText(485,730,`Principal`,{ align: 'left', size: 11, style: 'bold' },doc);
  }
  /*
 * Transfer Certificate (Gsss1)
 */
  downloadGsss1Tc(data,templateCode) {
    let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.2 });
    this.getGSSS1TransferCertificateHeader(data,templateCode, doc);
    doc.save("Transfer Certificate");
  }
  getGSSS1TransferCertificateHeader(Data,templateCode, doc) {
    var logo = new Image();
    doc.setLineWidth(0.5);
    doc.addImage(gsss, 'png', 35, 15, 75, 70); // logo
    //headers
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(doc, 10, 30, Data['institute_name'], {align: 'center', width: '595'});

    
    if(templateCode == 'TCGSSS2-1'){
      doc.setFontSize(14);
    }else{
      doc.setFontSize(16);
    }
    this.getAlignmentValue(doc, 10, 45, Data['branch_name'], {align: 'center',width: '595'});

    doc.setFontSize(10);
    this.getAlignmentValue(doc, 10, 55, Data['institue_address'], {align: 'center',width: '595'});
    this.getAlignmentValue(doc, 10, 65, Data['contact_details'], {align: 'center',width: '595'});
    this.getAlignmentValue(doc, 10, 75, Data['institute_state'], {align: 'center',width: '595' });
    this.getAlignmentValue(doc, 10, 85, Data['institute_details'], {align: 'center',width: '595'});

    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.line(40, 95, 560, 95);
    this.getAlignmentValue(doc, 40, 105, `Dise Code : ${Data["institution_code"]}`, {align: 'left',width: '595'});

    this.getAlignmentValue(doc, -39, 105, `SATS No : ${Data["SATS No"]}`, {align: 'right',width: '595'});
    doc.line(40, 110, 560, 110);

    doc.setLineWidth(1.5);
    doc.roundedRect(155, 120, 310, 20, 3, 3, 'S'); // title  box
    doc.setFontSize(16);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);
    var certificateName = "TRANSFER CERTIFICATE";
    if(Data["Duplicate"]){
      certificateName = "DUPLICATE TRANSFER CERTIFICATE"
    }
    this.getAlignmentValue(doc, 5, 135, `${certificateName}`, {align: 'center',width: '595'});

    var startY = 140 + 20;
    this.getDataGSSS1TcTable(Data, doc, startY, templateCode);
  }
  getDataGSSS1TcTable(Data, doc, yAxis, templateCode) {
    // table
    var row = [];
    let column = ["", ""];
    row.push(['1. Name of the pupil(in BLOCK LETTERS)',Data['1. Name of the pupil(in BLOCK LETTERS)']]);
    row.push(['2. Admission No.', Data['2. Admission No.']]);
    row.push(['3. Transfer Certificate No.', `${Data['Certificate No'] + '/' + Data["Certificate Postfix"]}`]);
    row.push(['4. Name of the Father', Data['4. Name of the Father']]);
    row.push(['5. Name of the Mother', Data['5. Name of the Mother']]);
    row.push(['6. Sex', Data['6. Sex']]);
    row.push(['7. Nationality', Data['7. Nationality']]);
    row.push(['8. Religion', Data['8. Religion']]);
    row.push(['9. Caste', Data['9. Caste']]);
    row.push(['10. Date of Birth (in words) (in figure)',Data['10. Date of Birth (in words) (in figure)']]);
    row.push(['11. Whether the candidate belongs to Scheduled Caste or Scheduled Tribe',Data['11. Whether the candidate belongs to Scheduled Caste or Scheduled Tribe']]);
    row.push(['12. Standard in which the pupil was studying at the time of leaving the school (in words)',Data['12. Standard in which the pupil was studying at the time of leaving the school (in words)']]);
    row.push(['13. Whether qualified for promotion to a higher standard',Data['13. Whether qualified for promotion to a higher standard']]);
    var studiedSubjects = '';
    for (var i = 0; i < Data['14. Languages studied'].length; i++) {
      studiedSubjects = studiedSubjects +' ' + `${Data['14. Languages studied'][i].toUpperCase()}` ;
    }
    row.push(['14. Languages studied', studiedSubjects]);
    row.push(['15. Medium of instruction', ` ${Data['15. Medium of instruction'].toUpperCase()}`]);
    row.push(['16. Date of admission to that standard', Data['16. Date of admission to that standard']]);
    row.push(['17. Whether the pupil has paid all the fees due to school',Data['17. Whether the pupil has paid all the fees due to school']]);
    row.push(['18. Fee concessions if any (nature and period to be specified)',Data['18. Fee concessions if any (nature and period to be specified)']]);
    row.push(['19. Scholarship if any (nature and period to be specified)', Data['19. Scholarship if any (nature and period to be specified)']]);
    row.push(['20. Whether Medically examined or not', Data['20. Whether Medically examined or not']]);
    row.push(['21. No.of school days upto the date of leaving', Data['21. No.of school days upto the date of leaving']]);
    row.push(['22. Number of school days attended by the pupil', Data['22. Number of school days attended by the pupil']]);
    row.push(["23. Date of pupils last attendance at school", Data["23. Date of pupils last attendance at school"]]);
    row.push(['24. Date on which the application for the tranfer certificate was received',Data['24. Date on which the application for the tranfer certificate was received']]);
    row.push(['25. Date of issue of transfer certificate',Data['25. Date of issue of transfer certificate']]);
    row.push(['26. Character and conduct', Data["26. Character and conduct"]]);
    var cellStyles = {
      fontSize: 10,
      fillColor: false,
      textColor: 0,
      halign: 'left',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5
    };

    var headCellStyles = {
      fontSize: 5,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 0
    };
    doc.autoTable(column, row, {
      margin: 45,
      startY: yAxis,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function(data) {
        var rows = data.table.body;
        rows[13].cells[1].styles.fontStyle = 'bold';
        rows[14].cells[1].styles.fontStyle = 'bold';
        rows[25].cells[1].styles.fontStyle = 'bold';
      },
      columnStyles: {
        0: { cellWidth: 280 },
        1: { cellWidth: 220, halign: 'left' }
      }
    });

    //tableY is 'y-position' after table is populated
    let tableY = doc.lastAutoTable.finalY;
    this.putText(45,tableY + 45,`Date: ${Data["27. Today Date"]}`,{ align: 'left', size: 10, style: 'bold' },doc);
    if(templateCode == "TCGSSS1-1"){
        this.putText(400,tableY + 45,Data["signature"],{ align: 'left', size: 10, style: 'bold' },doc);
    }else{
        this.putText(440,tableY + 45,Data["signature"],{ align: 'left', size: 10, style: 'bold' },doc);
    }
    this.putText(45,yAxis - 5,`Edn. 80`,{ align: 'left', size: 8 }, doc);
  }
/**
 *  GSSS3 study certificate
 */
 downloadGSSS3StudyCertificateHeader(data) {
    let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.5 });
    this.getGsssStudyCertificateHeader(data, doc);
    doc.save("Study Certificate")
  }
  getGsssStudyCertificateHeader(Data, doc) {
    var logo = new Image();
    doc.setLineWidth(0.5);
    doc.addImage(gsss, 'png', 45, 15, 75, 70); // base64 logo
    //headers
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);
    this.getAlignmentValue(doc, 20, 30, Data["institute_name"], {align: 'center', width: '595' });
  
    doc.setFontSize(16);
    this.getAlignmentValue(doc, 20, 45, Data["branch_name"], { align: 'center', width: '595'});
    doc.setFontSize(8);
    this.getAlignmentValue(doc, 20, 55, Data['institute_state'], { align: 'center', width: '595'});
  
    doc.setFontSize(12);
    this.getAlignmentValue(doc, 20, 70, Data['institue_address'], {align: 'center', width: '595'});
  
    var phone = '(0821) 2472452';
    var fax = '0821 - 2476893 ';
    var email = 'gssshighschool@gmail.com';
    doc.setFontSize(10);
    this.getAlignmentValue(doc, 20, 82, Data['contact_details'], { align: 'center',width: '595'});
  
    doc.setFontSize(10);
    doc.setFontType('bold');
    var collegeCode = 'NN072';
    doc.line(40, 100, 560, 100);
    this.getAlignmentValue(doc, 40, 110, `College Code : ${collegeCode}`, {align: 'left',width: '595'});
  
    var diseCode = '29261203118';
    this.getAlignmentValue(doc, -40, 110, `Dise No : ${diseCode}`, {align: 'right', width: '595'});
    doc.line(40, 115, 560, 115);
  
    this.putText(45,140,`Ref No. GSSS/BPPUC/SC/${Data['Certificate No']}/${Data['Certificate Postfix']}`,{ align: 'left', size: 10, style: 'bold' },doc);
  
    this.getAlignmentValue(doc, -40, 140, `Date: ${Data['Today Date']}`, {align: 'right',width: '595'});
  
    doc.setLineWidth(1.5);
    doc.roundedRect(155, 175, 310, 20, 3, 3, 'S'); // title  box
    doc.setFontSize(16);
    doc.setFontType('bold');
    doc.setTextColor(0, 0, 0);
    var certificateName = 'STUDY / CONDUCT CERTIFICATE';
    this.getAlignmentValue(doc, 5, 190, `${certificateName}`, {align: 'center',width: '595'});
  
    doc.setLineWidth(0.1);
    doc.roundedRect(250, 230, 120, 140, 15, 15); // photo  box
    this.putText(265,300,`PassPort Size Photo`,{ align: 'left', size: 10 },doc);
  
    doc.setFontType('normal');
  
    let certificateContent = `     This is to certify that, **${Data["Student Gender"]}${Data["Student Name"]}** ${Data["Relationship"]} `
    if(Data["Father Name"] !='' && Data["Mother Name"] !=''){
      certificateContent = certificateContent + `Sri. **${Data["Father Name"]}** and Smt. **${Data["Mother Name"]}** has studied`
    }else if(Data["Father Name"] !=''){
      certificateContent = certificateContent + `Sri. **${Data["Father Name"]}** has studied`
    }else if(Data["Mother Name"] !=''){
      certificateContent = certificateContent + `Smt. **${Data["Mother Name"]}** has studied `
    }
    certificateContent = certificateContent + ` **${Data["Admitted Class"]}** to **${Data["Current Class"]}** of the academic year **${Data["Admitted Year"]}** to **${Data["Academic Year"]}** with **${Data["Combination"]}** Combination in our institution. As per our records **${Data["pronouns"][0]}** date of birth is **${Data["Date of birth"]}** and **${Data["pronouns"][0]}** admission No.**${Data["Admission No"]}**. **${Data["pronouns"][1]}** belongs to **${Data["Caste"]}** Caste and **${Data["Category"]}** Category and **${Data["pronouns"][0]}** mother tongue is **${Data["Mother Tongue"]}**.`
  
    certificateContent =certificateContent + '\n \n'+ `     **${Data["pronouns"][0].charAt(0).toUpperCase() + String(Data["pronouns"][0]).slice(1)}** Conduct was satisfactory during **${Data["pronouns"][0]}** stay in this institution.`;
    if(Data["purpose"] != ""){
      certificateContent =certificateContent + '\n \n'+`     This is purely for **${Data["purpose"]}** Purpose.`;
    }else{
      certificateContent =certificateContent + '\n \n'+`     This is purely for "             " Purpose.`;
    }    
    doc.setFontSize(11);
    // this.multiLineNormalAndBoldText(11,20,65,450,540,certificateContent,doc);
    this.multiLineWordStyle(certificateContent,11,20,65,450,540,doc);
    // doc.text(certificateContent, 45, 450, { align: 'justify', maxWidth: 500 });
    // doc.text(certificateContentConduct, 45, 555, {align: 'justify',maxWidth: 500});
    // doc.text(certificatePurpose, 45, 575, { align: 'justify', maxWidth: 500 });
    this.putText(375,700, 'Seal and Signature of the Principal',{ align: 'left', size: 10, style: 'bold' },doc);
  
    // footer
    doc.line(40, 760, 560, 760);
  
    const phoneNumberArray = [
      { text: 'Phone No. : ', size: '8', style: 'bold' },
      { text: phone, size: '8', style: 'normal' },
      { text: ', Fax No.: ', size: '8', style: 'bold' },
      { text: fax, size: '8', style: 'normal' }
    ];
    this.putMulti(45, 770, phoneNumberArray, { align: 'left' }, doc);
  
    const emailArray = [
      { text: 'E-mail : ', size: '8', style: 'bold' },
      { text: "gssspu@gmail.com", size: '8', style: 'normal' }
    ];
    this.putMulti(450, 770, emailArray, { align: 'left' }, doc);
    doc.line(40, 775, 560, 775);
  }


    downloadGSSS2studyCertificate(data,templateCode){  
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 2 });
        // add the font to jsPDF
    
        doc.addFileToVFS('BalooTamma2-Bold.ttf', BalooTammaBold);
        doc.addFont('BalooTamma2-Bold.ttf', 'BalooTamma2', 'bold');
        this.getGsss2StudyCertificateHeader(data,templateCode,doc);
        doc.save("Study Certificate")
        
    }
  
    getGsss2StudyCertificateHeader(Data,templateCode,doc) {
        doc.setLineWidth(0.5);
        // var logo = new Image();
        // logo.src = 'assets/images/bms.png';
        doc.addImage(gsss, "png", 45, 35 , 75, 70); // logo
        
        doc.setFontSize(8);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
    
        var presidentName = 'Dr. M. JAGANNATHA SHENOI';
        this.putText(35,125,`${presidentName}`,{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(80,138,`President`,{ align: 'left', size: 10, style: 'bold' }, doc);
    
        var secretaryName = 'Smt. VANAJA B. PANDIT';
        this.putText( 430,125,`${secretaryName}`, { align: 'left', size: 10, style: 'bold' }, doc);
        this.putText( 450,138,`Hon. Secretary`,{ align: 'left', size: 10, style: 'bold' }, doc);
    
        this.getAlignmentValue(doc, 10, 45, `S.NO. 29/75-76`, { align: 'center', width: '595'});
        doc.setFontSize(12);
        this.getAlignmentValue(doc, 10, 55, Data["institute_name"], { align: 'center', width: '595' });
        if(templateCode == 'SCGSSS1-3'){
            doc.setFontSize(16);
        }
        if(templateCode == 'SCGSSS2'){
            doc.setFontSize(12);
        }
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        this.getAlignmentValue(doc, 10, 70, Data["branch_name"], {align: 'center',width: '595'});
    
        doc.setFont('BalooTamma2')
        if(templateCode == 'SCGSSS1-3'){
            doc.text('ಗೀತಾ ಭಾರತಿ ಶಾಲೆ',310,85);
        }
        if(templateCode == 'SCGSSS2'){
            doc.text('ಬಂಟ್ವಾಳ್ ಮಾಧವ ಶೆಣೈ ಪ್ರೌಢಶಾಲೆ',310,85);
        }
        
        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        
        this.getAlignmentValue(doc, 10, 100, Data["institue_address"], { align: 'center',width: '595'});
    
        var phone = '(0821) 2472452';
        var fax = '0821 - 2476893 ';
        var email1 = 'geethashishu@yahoo.co.in';
        var email2 = 'geethashishuoffice@gmail.com';
        const phoneNumberArray = [
            { text: 'Phone No. : ', size: '8', style: 'bold' },
            { text: phone, size: '8', style: 'normal' },
        ];
        this.putMulti(440, 30, phoneNumberArray, { align: 'left' }, doc);
    
        const faxArray = [
            { text: 'Fax No.: ', size: '8', style: 'bold' },
            { text: fax, size: '8', style: 'normal' }
        ];
        this.putMulti(440, 40, faxArray, { align: 'left' }, doc);
    
        const emailArray = [
            { text: 'E-mail : ', size: '8', style: 'bold' },
            { text: email1, size: '8', style: 'normal' }
        ];
        this.putMulti(440, 50, emailArray, { align: 'left' }, doc);
        this.putText( 470,60,email2, { align: 'left', size: 8 },doc);
    
        //double line after header
        doc.setLineWidth(1.5);
        doc.line(40, 150, 560, 150);
        doc.setLineWidth(0.5);
        doc.line(40, 153, 560, 153);
    
        var RefNumber = '';
        if(templateCode == 'SCGSSS1-3'){ 
            RefNumber = 'GBS/2019-20/'
        }
        if(templateCode == 'SCGSSS2'){ 
            RefNumber = 'BMSHS/2019-20/'
        }
        
        this.putText(45,185, `Ref No. ${RefNumber}`, { align: 'left', size: 10, style: 'bold' }, doc);
        this.getAlignmentValue(doc, -40, 185, `Date: ${Data["Today Date"]}`, {align: 'right',width: '595'});
        doc.setFontSize(14);
        var certificateName = 'STUDY CERTIFICATE';
        this.getAlignmentValue(doc, 5, 240, `${certificateName}`, {align: 'center',width: '595'});
    
        doc.setFontSize(12);
        doc.setFontType('normal');
    
        let content1 = `              This is to certify that, ${Data["Student Name"]} ${Data["Relationship"]} `
        if(Data["Father Name"] !='' && Data["Mother Name"] !=''){
            content1 = content1 + `Mr. ${Data["Father Name"]} and Mrs. ${Data["Mother Name"]}`
        }else if(Data["Father Name"] !=''){
            content1 = content1 + `Mr. ${Data["Father Name"]}`
        }else if(Data["Mother Name"] !=''){
            content1 = content1 + `Mrs. ${Data["Mother Name"]}`
        }
        if (Data['Current Class'] != 'LKG' ||Data['Current Class'] != 'PREKG' ||Data['Current Class'] != 'UKG' ||Data['Current Class'] != 'Prekg' ||Data['Current Class'] != 'Lkg' ||Data['Current Class'] != 'Ukg') {
            content1 = content1 +` is studying in ${Data['Current Class'].split(/(?<=\d)(?=[A-Za-z])/)} Std in the academic year ${Data["Academic Year"]} in our institution.`;
        }
        if (Data['Current Class'] == 'LKG' ||Data['Current Class'] == 'PREKG' ||Data['Current Class'] == 'UKG' ||Data['Current Class'] == 'Prekg' ||Data['Current Class'] == 'Lkg' ||Data['Current Class'] == 'Ukg') {
            content1 =content1 + ` is studying in ${Data['Current Class']} in the academic year ${Data["Academic Year"]} in our institution.`;
        }
        
        let content2 = `As per our records ${Data["pronouns"][0]} date of birth is ${Data["Date of birth"]} .`
        let content3 = ` ${Data["pronouns"][0].toUpperCase()} admission number is ${Data["Admission No"]}.`
        let content4 = ` ${Data["pronouns"][0].toUpperCase()}  residential address is ${Data["Address"]}.`
        let content5 = ` ${Data["pronouns"][1].toUpperCase()} belongs to ${Data["Caste"]} Caste.`
        let content6 = ` ${Data["pronouns"][1].toUpperCase()} belongs to ${Data["Caste"]} ${Data["SC ST"]} Caste.`
        let content7 = ` ${Data["pronouns"][0].toUpperCase()} SATS number is ${Data["SATS No"]}.`
    
        let certificateContent = content1 + content2 + content3;
    
        if(Data["Address"] != undefined && Data["Address"] != null){
            certificateContent = certificateContent+ content4
        }
        if(Data["SC ST"]!= undefined){
            certificateContent = certificateContent + content6
        }
        if(Data["SC ST"]== undefined){
            certificateContent = certificateContent + content5
        }
        if(Data["SATS No"]!= undefined && Data["SATS No"] != null){
            certificateContent = certificateContent + content7
        }
    
        var certificatePurpose = `This is purely for ${Data["purpose"]}`;
        //need to achieve bold style for dynamic values
        doc.setFontSize(12);
        doc.text(certificateContent, 45, 450, { align: 'justify', maxWidth: 450 });
        doc.text(certificatePurpose, 45, 595, { align: 'justify', maxWidth: 450 });
    
        var instituteHeadFacultyName = "Hemalatha S R"
        var designation = "Principal";
        if(templateCode == 'SCGSSS1-3'){ 
            instituteHeadFacultyName = "Sumana Priyavratha";
            designation = "Headmistress";
        }
        if(templateCode == 'SCGSSS2'){ 
            instituteHeadFacultyName = "Hemalatha S R";
            designation = "Principal";
        }
        this.putText(455,700,`(${instituteHeadFacultyName})`,{ align: 'left', size: 12, style: 'bold' },doc);
        this.putText(475, 715, `${designation}`, { align: 'left', size: 12, style: 'bold' }, doc);
        
    }

    /**
     * Transfer Certificate(GSSS3)
     */
    downloadGsss3Tc(Data) {
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1});
        doc.addFileToVFS('Benne-Regular.ttf', BenneRegular);
        doc.addFileToVFS('BalooTamma2-Regular.ttf', BalooTamma2);
        doc.addFileToVFS('BalooTamma2-Bold.ttf', BalooTammaBold);
        doc.addFont('Benne-Regular.ttf', 'Benne', 'normal');
        doc.addFont('BalooTamma2-Regular.ttf', 'BalooTamma2', 'normal');
        doc.addFont('BalooTamma2-Bold.ttf', 'BalooTamma2', 'bold');
    
        doc.addFileToVFS('Kedage-normal.ttf', Kedage);
        doc.addFont('Kedage-normal.ttf', 'Kedage', 'normal');
        doc.addFileToVFS('Kedage_Bold-normal.ttf', KedageBold);
        doc.addFont('Kedage_Bold-normal.ttf', 'Kedage', 'bold');
    
        doc.addFileToVFS('Kannada.ttf', kannada);
        doc.addFont('Kannada.ttf', 'kannada', 'bold');
        this.getGSSS3TransferCertificateHeader(Data, doc);
        doc.addPage(); // adding page for Office copy
        this.getGSSS3TransferCertificateHeader(Data, doc);
        doc.save("Transfer Certificate");
    }

    getGSSS3TransferCertificateHeader(Data, doc) {
        var logo = new Image();
        doc.setLineWidth(0.5);
        doc.addImage(gsss, 'png', 35, 15, 75, 70); // logo
        //headers
        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.setDrawColor(0,0,0);
        doc.setTextColor(0, 0, 0);
        this.getAlignmentValue(doc, 20, 30, Data['institute_name'], {align: 'center',width: '595'});
    
        doc.setFontSize(16);
        this.getAlignmentValue(doc, 20, 45, Data["branch_name"], {align: 'center',width: '595'});
    
        doc.setFontSize(8);
        this.getAlignmentValue(doc, 20, 55, Data['institute_state'], {align: 'center',width: '595'});
        doc.setFontSize(12);
        this.getAlignmentValue(doc, 20, 67, Data['institue_address'], {align: 'center', width: '595'});
        doc.setFontSize(10);
        this.getAlignmentValue(doc, 20, 80, Data['contact_details'], {align: 'center', width: '595'});
    
        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.line(40, 100, 560, 100);
        this.getAlignmentValue(doc,40,110,`College Code : ${Data['institution_code']}`,{ align: 'left', width: '595' });
    
        this.getAlignmentValue(doc, -40, 110, `Dise Code : 29261203118`, {align: 'right',width: '595'});
        doc.line(40, 115, 560, 115);
    
        doc.setLineWidth(1.5);
        doc.roundedRect(100, 125, 400, 20, 3, 3, 'S'); // title  box
        doc.setFontSize(16);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        doc.setFont('kannada')
        // doc.setFont('baloo-tamma-bold-unicode-kannada-font')
        var certificateName = 'TRANSFER CERTIFICATE / ವರ್ಗಾವಣೆ ಪತ್ರ';
        if (Data['Duplicate']) {
            certificateName = 'DUPLICATE TRANSFER CERTIFICATE / ವರ್ಗಾವಣೆ ಪತ್ರ';
        }
        this.getAlignmentValue(doc, 5, 140, `${certificateName}`, {align: 'center',width: '595'});
    
        var startY = 140 + 30;
        this.putText(45,startY - 5,`T.C. No.: ${Data["Certificate No"]}/${Data["Certificate Postfix"]}`,{ align: 'left', size: 10,style:'bold' },doc);
        this.putText(465,startY - 5,`Date: ${Data["19. Today Date"]}`,{ align: 'left', size: 10, style: 'bold' },doc);
        this.getGsss3TcDataTable(Data, doc, startY);
    }
    
    getGsss3TcDataTable(Data, doc, yAxis) {
        // table
        var row = [];
        let column = ['', ''];
        doc.setFont('BalooTamma2')
        row.push(["1. Name of the College\n  ಕಾಲೇಜಿನ ಹೆಸರು",`${Data["1. Name of the College"]}\n ವರ್ ಗಾವಣೆ ಪತ್ ರ`]);
        row.push(["2. Admission No & Date of Admission\n ದಾಖಲಾತಿ ಸಂಖ್ಯೆ ಮತ್ತು ದಾಖಲಾತಿ ದಿನಾಂಕ", Data['2. Admission No & Date of Admission']]);
        row.push(["3. Name of the Student in full (in capital letter)\n  ವಿದ್ಯಾರ್ಥಿಯ ಹೆಸರು (ಪೂರ್ಣವಾಗಿ ದಪ್ಪ ಅಕ್ಷರಗಳಲ್ಲಿ)", Data["3. Name of the Student in full (in capital letter)"]]);
        row.push(["4. date of Birth\n ಹುಟ್ಟಿದ ದಿನಾಂಕ", Data["4. date of Birth"]]);
        row.push(["5. Father's Name\nತಂದೆಯ ಹೆಸರು", Data["5. Father's Name"]]);
        row.push(["6. Mother's Name\nತಾಯಿಯ ಹೆಸರು", Data["6. Mother's Name"]]);
        row.push([`7. Sex ಲಿಂಗ: ${Data["7. sex"]}`, `8. Nationality ರಾಷ್ಟ್ರೀಯತೆ: ${Data["8. Nationality"]}`]);
        row.push([`9. Religion ಧರ್ಮ: ${Data["9. Religion"]}`, `10. Caste ಜಾತಿ: ${Data["10. Caste"]}`]);
        row.push(["11. Whether the Candidate belongs to Scheduled caste/Scheduled Tribe\n ವಿದ್ಯಾರ್ಥಿಯು ಪರಿಶಿಷ್ಟ ಜಾತಿ / ಪರಿಶಿಷ್ಟ ವರ್ಗಕ್ಕೆ  ಸೇರಿದ್ದವರಾಗಿದ್ದಾರೆಯೇ?", Data["11. Whether the Candidate belongs to Scheduled caste/Scheduled Tribe"]]);
        row.push(["12. Whether qualified for promotion for higher studies\n ಮುಂದಿನ ವ್ಯಾಸಂಗಕ್ಕೆ ಅರ್ಹತೆ ಇದೆಯೇ?",Data["12. Whether qualified for promotion for higher Studies"]]);
        row.push(["13. Standard in which the student was studying at the time of leaving the college\nಕಾಲೇಜನ್ನು ಬಿಡುವಾಗ ವಿದ್ಯಾರ್ಥಿಯು ಓದುತ್ತಿದ್ದ ತರಗತಿ",Data["13.  Standard in which the student was studing at the time of leaving the College"]]);
        row.push(["14. Date of Leaving the College\nಕಾಲೇಜು ಬಿಟ್ಟ ದಿನಾಂಕ",Data["14. date of leaving the College"]]);
        var studiedSubjects1 = '';
        for (var i = 0; i < Data["15. Subject PART - I: Language"].length; i++) {
        studiedSubjects1 =studiedSubjects1 + `${i+1}.${Data["15. Subject PART - I: Language"][i]} \n`
        }
        row.push(["15. Subject ವಿಷಯಗಳು\n PART - I: Language\n  ಭಾಷಾ ವಿಷಯಗಳು", studiedSubjects1]);
    
        var studiedSubjects2 = '';
        for (var i = 0; i < Data["PART - II: Optionals"].length; i++) {
        studiedSubjects2 = studiedSubjects2 +`${i+1}.${Data["PART - II: Optionals"][i]} \n`
        }
        row.push(["PART - II: Optionals\n ಐಚ್ಛಿಕ ವಿಷಯಗಳು", studiedSubjects2]);
    
        row.push(["16. Medium of Instruction  ಶಿಕ್ಷಣ ಮಾಧ್ಯಮ", Data["16. Medium of Instruction"]]);
        row.push(["17. Fee Concession, if any (nature and period to be specified) ಶುಲ್ಕ ವಿನಾಯಿತಿ ಯಾವುದಾದರೂ ಇದ್ದಲ್ಲಿ (ಸ್ವರೂಪ ಮತ್ತು ಅವಧಿಯನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸತಕ್ಕದ್ದು )", Data["17. Fee Concession, id any ( nature and period to be specified )"]]);
        row.push(["18. Character and Conduct ಶೀಲ ಮತ್ತು ಚಾರಿತ್ರ್ಯ",Data["18. Character and Conduct"]]);
        
        var cellStyles = {
        font:'BalooTamma2',
        fontSize: 10,
        fontStyle:'normal',
        fillColor: false,
        textColor: 0,
        halign: 'left',
        valign: 'middle',
        lineWidth: 0.3,
        lineColor: 0,
        minCellHeight: 0,
        cellPadding: 3
        };
    
        var headCellStyles = {
        font:'BalooTamma2',
        fontSize: 5,
        fontStyle:'normal',
        fillColor: false,
        textColor: 0,
        halign: 'center',
        valign: 'middle',
        lineWidth: 0,
        lineColor: 0,
        minCellHeight: 0,
        cellPadding: 0
        };
        doc.autoTable(column, row, {
        margin: 45,
        startY: yAxis,
        headStyles: headCellStyles,
        bodyStyles: cellStyles,
        styles: cellStyles,
        alternateRowStyles: cellStyles,
        didParseCell: function(data) {
            var rows = data.table.body;
            rows[0].cells[1].styles.fontStyle = 'bold';
            rows[2].cells[1].styles.fontStyle = 'bold';
            rows[3].cells[1].styles.fontStyle = 'bold';
            rows[4].cells[1].styles.fontStyle = 'bold';
            rows[5].cells[1].styles.fontStyle = 'bold';
            rows[6].cells[0].styles.fontStyle = 'bold';
            rows[6].cells[1].styles.fontStyle = 'bold';
            rows[7].cells[0].styles.fontStyle = 'bold';
            rows[7].cells[1].styles.fontStyle = 'bold';
            rows[8].cells[1].styles.fontStyle = 'bold';
            rows[9].cells[1].styles.fontStyle = 'bold';
            rows[10].cells[1].styles.fontStyle = 'bold';
            rows[11].cells[1].styles.fontStyle = 'bold';
            rows[12].cells[1].styles.fontStyle = 'bold';
            rows[13].cells[1].styles.fontStyle = 'bold';
            rows[14].cells[1].styles.fontStyle = 'bold';
            rows[15].cells[1].styles.fontStyle = 'bold';
            rows[16].cells[1].styles.fontStyle = 'bold';
        },
        columnStyles: {
            0: { cellWidth: 280 },
            1: { cellWidth: 220, halign: 'left' }
        }
        });
        //tableY is 'y-position' after table is populated
        let tableY = doc.lastAutoTable.finalY;
    
        this.putText(400,tableY + 35,Data['signature'],{ align: 'left', size: 10, style: 'bold' },doc);
        var copyName = 'Student Copy';
        var pageCount = doc.internal.getNumberOfPages();
        if (pageCount == 2) { 
        // if document page is 2 then its office copy
        copyName = 'Office Copy';
        }
        this.putText(50,tableY + 35,`${copyName}`,{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(430,tableY + 35,`Seal & Signature of the Principal`,{ align: 'left', size: 10, style: 'bold' },doc);
    }

    /*
    * Silicon Degree TC certificate
    */
    downloadSiliconCollegeTransferCertificate(data) {
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.5 });
        this.getSiliconCollegeTcCertificateHeader(data, doc); 
        doc.save("Transfer certificate");
    }

    getSiliconCollegeTcCertificateHeader(Data, doc) {
        doc.addFileToVFS('Prata-Regular.ttf', prataRegular);
        doc.addFont('Prata-Regular.ttf', 'prataRegular', 'normal');
        doc.setFont('prataRegular')
        doc.setTextColor(0, 0, 0);
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(5.5);
        // Need to Chnage logo
        doc.addImage(SCCMC_logo, 'png', 25, 15, 50, 60); // base64 logo
    
        //headers
        doc.setFontSize(16);
        doc.setTextColor(25, 25, 112);
        doc.setFontType('bold');
        this.getAlignmentValue(doc, 20, 30, Data['branch_name'], {align: 'center',width: '595'});
    
        doc.setFontSize(12);
        this.getAlignmentValue(doc, 20, 45, '(Affiliated to Banglore University)', {align: 'center', width: '595'});
        this.getAlignmentValue(doc, 20, 60, Data['institue_address'], {align: 'center',width: '595'});
        this.getAlignmentValue(doc,20,75,'Phone:+91 80 2632 3044  www.siliconcityedu.org',{ align: 'center', width: '595' });
        doc.setTextColor(0, 0, 0);
    
        doc.setFontSize(11);
        this.getAlignmentValue(doc, 35, 105, 'T.C. No.....................', {align: 'left',width: '595'});
        doc.setTextColor(187, 0, 0);
        doc.setFontSize(13);
        this.getAlignmentValue(doc, 80, 102, `${Data['Certificate No']} / ${Data['Certificate Postfix']}`, {align: 'left',width: '595'});
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        this.getAlignmentValue(doc, -25, 105, 'College Code - 08', {align: 'right', width: '595'});
    
        doc.setFontSize(16);
        var certificateName = 'TRANSFER CERTIFICATE';
        if (Data['Duplicate']) {
          certificateName = 'DUPLICATE TRANSFER CERTIFICATE';
        }
        this.getAlignmentValue(doc, 5, 105, `${certificateName}`, {align: 'center',width: '595'});
    
        var startY = 105 + 15;
        this.getSiliconCollegeTcDataTable(Data, doc, startY);
        
      }
    
      getSiliconCollegeTcDataTable(Data, doc, yAxis) {
        // table
        var row = [];
        let column = ['', ''];
        doc.setFont('prataRegular')
        row.push(['Name of the student',Data['Name of the student']]);
        row.push(["Father's Name", Data["Father's Name"]]);
        row.push(["Mother's Name", Data["Mother's Name"]]);
        row.push(['Admission No. and Date',`${Data['Admission No.']}\n${ Data['Date of Admission']}`
        ]);
        // Titlesize the right side value
        var dateOfBirth = `(1) In Figures: ` + `${Data['Date of Birth according to Admission Register']}` + `  (2) In Words: ${Data['dob(words)']}`
        row.push(['Date of Birth according to Admission Register',dateOfBirth]);
        row.push(['Religion and Caste',`${Data['Religion']},  ${Data['Caste']}`]);
        row.push(['Date on which the student last attended the college',Data['Date on which the student last attended the college']]);
        row.push(['Class in which the student was studying at the time of leaving the college',`${Data['Department']} - ${Data['Class in which the student was studying at the time of leaving the college']}`]);
        row.push(['Whether qualified for promotion', Data['whether qualified for promotion']]);
        row.push(['Whether the student has paid all the fees due to the college for the term',Data['Whether the student has paid all the fees due to the college for the term']]);
        var subjects = ``;
        for (var i = 0; i < Data['Subjects studied'].length; i++) {
          subjects = subjects + `${Data['Subjects studied'][i]}\n`;
        }
        row.push(['Subjects Studied',`I LANG : ${Data['I LANG']}                             II LANG : ${Data['II LANG']}\nSUBJECTS :\n${subjects}\n`]);
        row.push(['Medium of Instruction', Data['Medium of Instruction']]);
        row.push(['Concession in Fees : Free/Half Free/Full Payment',Data['Concession in Fees: Free/Half Free/Full Payment']]);
        row.push(['Is he/she a scholarship holder?\n(if so,state the nature and the period upto which he/she is paid)\n',`${Data['Is he/she a scholarship holder ? (If so, state the nature and the period upto which he/she is paid)']}`
        ]);
        row.push(['Conduct', Data['Conduct']]);
        row.push(['Date of issue of Transfer Certificate',Data['Date of issue of Transfer Certificate']]);
    
    
        var cellStyles = {
          font:'PrataRegular',
          fontSize: 10,
          fontStyle:'normal',
          fillColor: false,
          textColor: 0,
          halign: 'left',
          valign: 'middle',
          lineWidth: 0.1,
          lineColor: 0,
          minCellHeight: 0,
          cellPadding: 6,
          
        };
    
        var headCellStyles = {
          font:'PrataRegular',
          fontSize: 5,
          fillColor: false,
          textColor: 0,
          halign: 'left',
          valign: 'middle',
          lineWidth: 0,
          lineColor: 0,
          minCellHeight: 0,
          cellPadding: 0,
        
        };
    
        doc.autoTable(column, row, {
          margin: 25,
          startY: yAxis,
          headStyles: headCellStyles,
          bodyStyles: cellStyles,
          styles: cellStyles,
          alternateRowStyles: cellStyles,
          didParseCell: function(data) {
            var rows = data.table.body;
            //style each cells
            rows[0].cells[1].styles.fontStyle = 'bold';
            rows[1].cells[1].styles.fontStyle = 'bold';
            rows[2].cells[1].styles.fontStyle = 'bold';
            rows[3].cells[1].styles.fontStyle = 'bold';
            rows[4].cells[1].styles.fontStyle = 'bold';
            rows[5].cells[1].styles.fontStyle = 'bold';
            rows[6].cells[1].styles.fontStyle = 'bold';
            rows[7].cells[1].styles.fontStyle = 'bold';
            rows[8].cells[1].styles.fontStyle = 'bold';
            rows[9].cells[1].styles.fontStyle = 'bold';
            rows[10].cells[1].styles.fontStyle = 'bold';
            rows[11].cells[1].styles.fontStyle = 'bold';
            rows[12].cells[1].styles.fontStyle = 'bold';
            rows[13].cells[1].styles.fontStyle = 'bold';
            rows[14].cells[1].styles.fontStyle = 'bold';
            rows[15].cells[1].styles.fontStyle = 'bold';
          },
          columnStyles: {
            0: { cellWidth: 200 },
            1: { cellWidth: 350, halign: 'left' }
          }
        });
    
        //tableY is 'y-position' after table is populated
        let tableY = doc.lastAutoTable.finalY;
        this.putText(460,tableY + 55,'Principal',{ align: 'left', size: 10, style: 'bold' },doc);
        this.putText(50,tableY + 35,'Verified by (Name) ..............................................',{ align: 'left', size: 10, style: 'normal' },doc);
        this.putText(50,tableY + 65,'Signature of Staff ................................................',{ align: 'left', size: 10, style: 'normal' },doc);
       
      }


      downloadStudentOnFacultyFeedback(data){
        var doc = new jsPDF('l', 'mm', 'a2');
        this.getStudentOnFacultyFeedback(doc,data)
        doc.save('student Feedback on Faculty')
      }
  
      getStudentOnFacultyFeedback(doc,students: Array<any>) {
        doc.setFont("times")
        doc.deletePage(1);
        students.forEach((student) => {
          doc.addPage();
          doc.addImage(SCDCB_logo, 'png', 35, 5, 20, 20); // base64 logo
          doc.setDrawColor(0, 0, 0);
          doc.setFontSize(22);
          doc.setFontType('bold');
          var institute_name = 'Silicon City College of Management & Commerce';
          this.getAlignmentValueAny(60,15,institute_name,{ align: 'left', width: '595' },doc);
          doc.setFontSize(18);
          this.getAlignmentValueAny(100,25, 'Feedback from Students on Teachers ',{ align: 'left', width: '595' },doc);
          doc.line(100, 27, 200, 27); // header underline
    
          const student_name = [
            { text: 'Student ID : ', size: '17', style: 'bold' },
            { text: student['student_id'], size: '17', style: 'normal' },
    
            { text: '               Gender : ', size: '17', style: 'bold' },
            { text: student['gender'], size: '17', style: 'normal' },
    
            { text: '               Programme : ', size: '17', style: 'bold' },
            { text: student['program'], size: '17', style: 'normal' },
    
            { text: '               Semester : ', size: '17', style: 'bold' },
            { text: student['semester'], size: '17', style: 'normal' },
          ];
          this.putMulti(10, 50, student_name, { align: 'left' }, doc);
          
          const ledger = [
            { text: 'Legends: ', size: '17', style: 'bold' },
            { text: '', size: '17', style: 'normal' },
          ];
          this.putMulti(10, 70, ledger, { align: 'left' }, doc);
  
          this.addFeedbacklegends(68, "times", 43, 16 , 16, doc)
          // table
          var row = [];
          var column = ['',''];
          var student_id = student['student_id'];
    
    
          //sub header for subject name
          var staff_name = ['Particulars','Teacher Name :'];
          var subject_name = ['','Subject :'];
          for (var i = 0; i < student['details'].length; i++) {
            column.push('');
     
            staff_name.push(`${student['details'][i]['staff_name']}`);
            subject_name.push(student['details'][i]['subject_name']);
            var sorted = student['details'][i]['subject_detail'].sort((a, b) =>
              a.feedback_particular_id > b.feedback_particular_id ? 1 : -1
            );
            student['details'][i]['subject_detail'] = sorted;
          }
          row.push(staff_name);
          row.push(subject_name);
    
          
          for(var i= 0; i< student["feedback_particular_record"].length;i++){
            var row_data = []
            row_data.push(`${i+1}. ${student["feedback_particular_record"][i]["name"]}`)
            row_data.push('');
            for (var j = 0; j < student['details'].length; j++) {
              for(var k =0; k< student['details'][j]["subject_detail"].length; k++){
                if(student['details'][j]["subject_detail"][k]["feedback_particular_id"] == student["feedback_particular_record"][i]["id"]){
                  row_data.push(student['details'][j]["subject_detail"][k]["answer"])
                }
              }
            }
            row.push(row_data)
          }
          if(student['suggestion'] == null){
            row.push([`Any other Suggestions : \n\n`])
          }else{
            row.push([`Any other Suggestions : \n\n${student['suggestion']}`])
          }
  
          
          var cellStyles = {
            fontSize: 13,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 5,
            cellPadding: 5,
            font: "times",
            minCellWidth: 40
          };
    
          var headCellStyles = {
            fontSize: 13,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 5,
            font: "times",
            minCellWidth: 40
          };
    
          doc.autoTable(column, row, {
            margin: 5,
            startY: 90,
            headStyles: headCellStyles,
            bodyStyles: cellStyles,
            styles: cellStyles,
            alternateRowStyles: cellStyles,
            didParseCell: function (data) {
              var rows = data.table.body;
              //colspan for headers
              if (data.row.index === 0 && data.column.index == 0) {
                data.cell.rowSpan = 2;
              }else if( data.column.index == 0 && data.row.index > 0 ){
                data.cell.styles.halign = 'left';
              }
  
              if (data.row.index > 1 && data.column.index == 0) {
                data.cell.colSpan = 2;
                
              }
              if (data.row.index <= 1 && data.column.index >= 0) {
                data.cell.styles.fontStyle = 'bold';
                
              }
              if((data.row.index == rows.length - 1) && (data.column.index == 0)){
                data.cell.colSpan = column.length;
              }
  
            },
            columnStyles: {
              0: {
                cellWidth: 100,
                fontStyle: 'bold',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              },
            },
          });
        });
      }
  
      downloadStudentOnSyllabusFeedback(data){
        var doc = new jsPDF('p', 'pt', 'a4');
        this.getStudentOnSyllabusFeedback(doc,data)
        doc.save('Student On Syllabus Feedback');
      }
        
      getStudentOnSyllabusFeedback(doc, students: Array<any>) {
        doc.setFont("times")
        doc.deletePage(1);
        students.forEach((student) => {
          doc.addPage();
          doc.addImage(SCDCB_logo, 'png', 35, 5, 50, 50); // base64 logo
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0, 0, 0);
          doc.setFontSize(18);
          doc.setFontType('bold');
          var institute_name = 'Silicon City College of Management & Commerce';
          this.getAlignmentValueAny(15,20,institute_name,{ align: 'center', width: '595' },doc);
          doc.setFontSize(16);
          this.getAlignmentValueAny( 15, 45, 'Feedback from Students on Syllabus', { align: 'center', width: '595' },doc);
          doc.line(188, 47, 437, 47); // header underline
  
          const student_name = [
            { text: 'Name of the Student: ', size: '13', style: 'bold' },
            { text: student['student_name'], size: '13', style: 'normal' },
          ];
          this.putMulti(45, 90, student_name, { align: 'left' }, doc);
    
          const Gender = [
            { text: 'Gender: ', size: '13', style: 'bold' },
            { text: student['gender'], size: '13', style: 'normal' },
          ]
          this.putMulti(45, 110, Gender, { align: 'left' }, doc);
    
          const program = [
            { text: 'Programme: ', size: '13', style: 'bold' },
            { text: student['program'], size: '13', style: 'normal' }
          ];
          this.putMulti(45, 130, program, { align: 'left' }, doc);
          const semester = [
            { text: 'Semester: ', size: '13', style: 'bold' },
            { text: student['semester'], size: '13', style: 'normal' },
          ];
          this.putMulti(45, 150, semester, { align: 'left' }, doc);
  
          const ledger = [
            { text: 'Legends: ', size: '13', style: 'bold' },
            { text: '', size: '13', style: 'normal' },
          ];
          this.putMulti(45, 170, ledger, { align: 'left' }, doc);
  
          this.addFeedbacklegends(185, "times", 43, 13 , 13, doc)
          // table
          var row = [];
          var column = ['Particulars','Feedback Answer'];    
  
          var sorted = student['details'].sort((a,b)=>
            a.feedback_particular_id > b.feedback_particular_id ? 1 : -1
          );
          student['details'] = sorted;
  
          for(var i= 0; i< student["feedback_particular_record"].length;i++){
            var row_data = [];
            row_data.push(`${i+1}.   ${student["feedback_particular_record"][i]["name"]}`);
            for(var j=0;j<student["details"].length;j++){
              if(student["details"][j]["feedback_particular_id"] == student["feedback_particular_record"][i]["id"]){
                row_data.push(student['details'][j]["answers"]);
              }
            }
            row.push(row_data);
          }
          if(student['suggestion'] == null){
            row.push([`Any Other Suggestions : \n\n`])
          }else{
            row.push([`Any Other Suggestions : \n\n${student['suggestion']}`])
          }
  
          var cellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 25,
            cellPadding: 15,
            font: 'times'
          };
  
          var headCellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 0,
            cellPadding: 5,
            font: 'times'
          };
  
          doc.autoTable(column, row, {
            margin: 39,
            startY: 230,
            headStyles: headCellStyles,
            bodyStyles: cellStyles,
            styles: cellStyles,
            alternateRowStyles: cellStyles,
            didParseCell: function (data) {
              var rows = data.table.body;
              if( data.column.index == 0 && data.row.section == 'body'){
                data.cell.styles.halign = 'left';
                data.cell.styles.fontStyle = 'normal';
  
              }
              if((data.row.index == rows.length - 1) && (data.column.index == 0)){
                data.cell.colSpan = column.length;
              }
            },
            columnStyles: {
              0: {
                cellWidth: 400,
                fontStyle: 'bold',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              },
              1: {
                // cellWidth: 80,
                fontStyle: 'normal',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              }
            },
          });
        });
      }
  
  
        
      downloadFacultyFeedback(data){
        var doc = new jsPDF('p', 'pt', 'a4');
        this.getFacultyFeedback(doc,data)
        doc.save('Faculty Feedback')
      }
      
      getFacultyFeedback(doc, students: Array<any>) {
        doc.setFont("times")
        doc.deletePage(1);
        students.forEach((staff) => {
          doc.addPage();
          doc.addImage(SCDCB_logo, 'png', 35, 5, 50, 50); // base64 logo
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0, 0, 0);
          doc.setFontSize(18);
          doc.setFontType('bold');
          var institute_name = 'Silicon City College of Management & Commerce';
          this.getAlignmentValueAny(15,20,institute_name,{ align: 'center', width: '595' },doc);
          doc.setFontSize(15);
          this.getAlignmentValueAny( 15, 45, 'Feedback from Faculty members on Curriculum', { align: 'center', width: '595' }, doc);
          doc.line(155, 47, 470, 47); // header underline
  
  
          const staff_name = [
            { text: 'Name of the Faculty: ', size: '13', style: 'bold' },
            { text: staff['staff_name'], size: '13', style: 'normal' },
          ];
          this.putMulti(45, 90, staff_name, { align: 'left' }, doc);
    
          
          const designation = [
    
            { text: 'Designation: ', size: '13', style: 'bold' },
            { text: staff['designation'], size: '13', style: 'normal' },
          ]
          this.putMulti(45, 110, designation, { align: 'left' }, doc);
  
          const ledger = [
            { text: 'Legends: ', size: '13', style: 'bold' },
            { text: '', size: '13', style: 'normal' },
          ];
          this.putMulti(45, 130, ledger, { align: 'left' }, doc);
  
          this.addFeedbacklegends(145, "times", 43, 13 , 13, doc)
          // table
          var row = [];
          var column = ['Particulars','Feedback Answer'];    
  
          var sorted = staff['details'].sort((a,b)=>
          a.feedback_particular_id > b.feedback_particular_id ? 1 : -1
          );
          staff['details'] = sorted;
  
          for(var i= 0; i< staff["feedback_particular_record"].length;i++){
            var row_data = [];
            row_data.push(`${i+1}.   ${staff["feedback_particular_record"][i]["name"]}`);
            for(var j=0;j<staff["details"].length;j++){
              if(staff["details"][j]["feedback_particular_id"] == staff["feedback_particular_record"][i]["id"]){
                row_data.push(staff['details'][j]["answer"]);
              }
            }
            row.push(row_data);
          }
          if(staff['suggestion'] == null){
            row.push([`Any other Suggestions : \n\n`])
          }else{
            row.push([`Any other Suggestions : \n\n${staff['suggestion']}`])
          }
  
          var cellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 25,
            cellPadding: 15,
            font: "times"
          };
  
          var headCellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 15,
            cellPadding: 15,
            font: "times"
          };
  
          doc.autoTable(column, row, {
            margin: 39,
            startY: 190,
            headStyles: headCellStyles,
            bodyStyles: cellStyles,
            styles: cellStyles,
            alternateRowStyles: cellStyles,
            didParseCell: function (data) {
              var rows = data.table.body;
              if( data.column.index == 0 && data.row.section == 'body'){
                data.cell.styles.halign = 'left';
                data.cell.styles.fontStyle = 'normal';
              }
              if((data.row.index == rows.length - 1) && (data.column.index == 0)){
                data.cell.colSpan = column.length;
              }
            },
            columnStyles: {
              0: {
                cellWidth: 400,
                fontStyle: 'bold',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              },
              1: {
                // cellWidth: 80,
                fontStyle: 'normal',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              }
            },
          });
        });
      }
  
      downloadAlumniFeedback(data){
        var doc = new jsPDF('p', 'pt', 'a4');
        this.getAlumniOnSyllabusFeedback(doc,data)
        doc.save('Alumni Feedback')
      }
  
      getAlumniOnSyllabusFeedback(doc, students: Array<any>) {
        doc.setFont("times")
        doc.deletePage(1);
        students.forEach((student) => {
          doc.addPage();
          doc.addImage(SCDCB_logo, 'png', 35, 5, 50, 50); // base64 logo
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0, 0, 0);
          doc.setFontSize(18);
          doc.setFontType('bold');
          var institute_name = 'Silicon City College of Management & Commerce';
          this.getAlignmentValueAny(15,20,institute_name,{ align: 'center', width: '595' },doc);
          doc.setFontSize(16);
          this.getAlignmentValueAny( 15, 45, 'Feedback from Alumnus on Curriculum', { align: 'center', width: '595' },doc);
          doc.line(175, 47, 450, 47); // header underline
    
          const alumni_name = [
            { text: 'Name of the Alumni: ', size: '13', style: 'bold' },
            { text: student['alumni_name'], size: '13', style: 'normal' },
          ];
          this.putMulti(45, 90, alumni_name, { align: 'left' }, doc);
    
          
          const course_studied = [
    
            { text: 'Course Studied: ', size: '13', style: 'bold' },
            { text: student['course_studied'], size: '13', style: 'normal' },
          ]
          this.putMulti(45, 110, course_studied, { align: 'left' }, doc);
    
    
          const graduated_year = [
    
            { text: 'Year of Graduation: ', size: '13', style: 'bold' },
            { text: student['graduated_year'], size: '13', style: 'normal' }
          ];
          this.putMulti(45, 130, graduated_year, { align: 'left' }, doc);
          const contact = [
          
    
            { text: 'Contact Number: ', size: '13', style: 'bold' },
            { text: student['contact'], size: '13', style: 'normal' },
          ];
          this.putMulti(45, 150, contact, { align: 'left' }, doc);
          
          const ledger = [
            { text: 'Legends: ', size: '13', style: 'bold' },
            { text: '', size: '13', style: 'normal' },
          ];
          this.putMulti(45, 170, ledger, { align: 'left' }, doc);
  
          this.addFeedbacklegends(185, "times", 43, 13 , 13, doc)
          // table
          var row = [];
          var column = ['Particulars','Feedback Answer'];    
    
          var sorted = student['details'].sort((a,b)=>
            a.feedback_particular_id > b.feedback_particular_id ? 1 : -1
          );
          student['details'] = sorted;
    
          for(var i= 0; i< student["feedback_particular_record"].length;i++){
            var row_data = [];
            row_data.push(`${i+1}.   ${student["feedback_particular_record"][i]["name"]}`);
            for(var j=0;j<student["details"].length;j++){
              if(student["details"][j]["feedback_particular_id"] == student["feedback_particular_record"][i]["id"]){
                row_data.push(student['details'][j]["answer"]);
              }
            }
            row.push(row_data);
          }
          if(student['suggestion'] == null){
            row.push([`Overall feedback about college : \n\n`])
          }else{
            row.push([`Overall feedback about college : \n\n${student['suggestion']}`])
          }
    
          var cellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 25,
            cellPadding: 15,
            font: "times"
          };
    
          var headCellStyles = {
            fontSize: 11,
            fillColor: false,
            textColor: 0,
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: 0,
            minCellHeight: 25,
            cellPadding: 5,
            font: "times"
          };
    
          doc.autoTable(column, row, {
            margin: 39,
            startY: 230,
            headStyles: headCellStyles,
            bodyStyles: cellStyles,
            styles: cellStyles,
            alternateRowStyles: cellStyles,
            didParseCell: function (data) {
              var rows = data.table.body;
              if( data.column.index == 0 && data.row.section == 'body'){
                data.cell.styles.halign = 'left';
                data.cell.styles.fontStyle = 'normal';
              }
              if((data.row.index == rows.length - 1) && (data.column.index == 0)){
                data.cell.colSpan = column.length;
              }
            },
            columnStyles: {
              0: {
                cellWidth: 400,
                fontStyle: 'bold',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              },
              1: {
                // cellWidth: 80,
                fontStyle: 'normal',
                cellPadding: { left: 4, right: 4, top: 2, bottom: 2 },
              }
            },
          });
        });
      }
      
      downloadSCDCBStudyCertificateHeader(data) {
        let doc = new jsPDF({ orientation: 'portrait', unit: 'pt', lineHeight: 1.5 });
        this.getSCDCBStudyCertificateHeader(data, doc);
        doc.save("Study Certificate")
      }
      getSCDCBStudyCertificateHeader(Data, doc) {
        doc.addFileToVFS('Prata-Regular.ttf', prataRegular);
        doc.addFont('Prata-Regular.ttf', 'prataRegular', 'normal');
        doc.setFont('prataRegular')
        doc.setFontSize(14);
        this.putText(45,140,`No.: SCCMC/${Data['Certificate No']}/${Data['Certificate Postfix']}`,{ align: 'left', size: 14, style: 'bold' },doc);
      
        this.getAlignmentValue(doc, -40, 140, `Date: ${Data['Today Date']}`, {align: 'right',width: '595'});
      
        doc.setFontSize(16);
        doc.setFontType('bold');
        doc.setTextColor(0, 0, 0);
        var certificateName1 = 'TO WHOMSOEVER CONCERNED';
        this.getAlignmentValue(doc, 5, 195, `${certificateName1}`, {align: 'center',width: '595'});
        
        var certificateName2 = 'STUDY CERTIFICATE';
        this.getAlignmentValue(doc, 5, 225, `${certificateName2}`, {align: 'center',width: '595'});
      
        doc.setFontType('normal');
      
        let certificateContent = `This is to certify that, **${Data["Student Name"]}** ${Data["Relationship"]}., `
        if(Data["Father Name"] !=''){
          certificateContent = certificateContent + `Mr. **${Data["Father Name"]}** is a bonafide `
        }else if(Data["Mother Name"] !=''){
          certificateContent = certificateContent + `Mrs. **${Data["Mother Name"]}** is a bonafide `
        }
        certificateContent = certificateContent + `student of this college, affiliated to Bangalore University, bearing admission number ${Data["Admission No"]} / BU Reg. No. ${Data["BU Reg"]} currently pursuing her studies in ${Data["Currently Studing"]}.`
         
        doc.setFontSize(14);
        this.multiLineWordStyle(certificateContent,15,20,60,275,510,doc);
        this.putText(490,420, 'Principal',{ align: 'left', size: 14, style: 'bold' },doc);      
      }
 
//   Common Methods

    /**
     * fuction used to align a text in the pdf doc
     * @param x x-position
     * @param y y-position
     * @param text the text to be added
     * @param options used for alignment: center | left | right
     */
    getAlignmentValue(doc: any, x: any, y: any, text: string, options: any) {
        var options = options || {};
        var defaults = { align: 'left', width: doc.internal.pageSize.width };
        var settings = $.extend({}, defaults, options);
        var fontSize = doc.internal.getFontSize();
        var text_size = (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
        if (settings.align === 'center') x += (settings.width - text_size) / 2;
        else if (settings.align === 'right') x += settings.width - text_size;
        doc.text(text, x, y);
    }
    /**
     * Puts a line of text in a document
     * @param x X-coordinate in a page
     * @param y Y-coordinate in a page
     * @param text String to be printed
     * @param options Styling options
     * @param doc The document in which the texts must be printed
     */
    putText(x: any, y: any, text: string, options: any, doc: any) {
        var options = options || {};
        var defaults = {align: "left",width: doc.internal.pageSize.width,size: 8,style: "normal"};
        var settings = $.extend({}, defaults, options);
        var fontSize = settings.size;
        doc.setFontSize(fontSize);
        doc.setFontStyle(settings.style);
        var text_size = (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
        if (settings.align === "center") {
            x += (settings.width - text_size) / 2;
        } else if (settings.align === "right") x += settings.width - text_size;
        doc.text(text, x, y);
    }

    /**
     * Puts A line of text with multiple styles
     * @param x X-coordinate in a page
     * @param y Y-coordinate in a page
     * @param textArray Array of strings to be printed
     * @param options Styling option
     * @param doc The document in which the texts must be printed
     */
    putMulti(x: any, y: any, textArray: Array<any>, options: any, doc: any) {
        // putText(x,y,doc){
        var options = options || {};
        var defaults = {
            align: "left",
            width: doc.internal.pageSize.width
        };
        var text = "";
        textArray.forEach(e => { text += e.text;});
        var settings = $.extend({}, defaults, options);
        var fontSize = doc.internal.getFontSize();
        var text_size = (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
        if (settings.align === "center") x += (settings.width - text_size) / 2;
        else if (settings.align === "right") x += settings.width - text_size;
        textArray.forEach(e => {
            doc.setFontSize(e.size);
            doc.setFontStyle(e.style);
            doc.text(e.text, x, y);
            x += (doc.getStringUnitWidth(e.text) * e.size) / doc.internal.scaleFactor;
        });
    }
    
    multiLineWordStyle(inputValue,fontSize: any,lineSpacing: Number,startX: any,startY: any,endX: any,doc: any){
      let textMap = doc.splitTextToSize(inputValue,endX);
      const isBoldOpen = (arrayLength, valueBefore = false) => {
        const isEven = arrayLength % 2 === 0;
        const result = valueBefore !== isEven;
        return result;
      }
      const startXCached = startX;
        let boldOpen = false;
        textMap.map((text, i) => {
            if (text) {
                const arrayOfNormalAndBoldText = text.split('**');
                const boldStr = 'bold';
                const normalOr = 'normal';
                arrayOfNormalAndBoldText.map((textItems, j) => {
                    doc.setFontType(boldOpen ? normalOr : boldStr);
                    if (j % 2 === 0) {
                        doc.setFontType(boldOpen ? boldStr : normalOr);
                    }
                    doc.text(textItems, startX, startY);
                    startX = startX + doc.getStringUnitWidth(textItems) * fontSize;
                });
                boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
                startX = startXCached;
                startY += lineSpacing;
            }
        });
    }
    multiLineNormalAndBoldText(fontSize: any,lineSpacing: Number,startX: any,startY: any,endX: any,inputText: any,doc: any) {
        doc.setFontSize(fontSize);
        const regex = /(\*{2})+/g; // all "**" words
        const textWithoutBoldMarks = inputText.replace(regex, '');
        let splitTextWithoutBoldMarks = doc.splitTextToSize(textWithoutBoldMarks,460);
        let charsMapLength = 0;
        let position = 0;
        let isBold = false;
    
        // <><>><><>><>><><><><><>>><><<><><><><>
        // power algorithm to determine which char is bold
        let textRows = splitTextWithoutBoldMarks.map((row, i) => {
            const charsMap = row.split('');
            const chars = charsMap.map((char, j) => {
                position = charsMapLength + j + i;
                let currentChar = inputText.charAt(position);
        
                if (currentChar === '*') {
                    const spyNextChar = inputText.charAt(position + 1);
                    if (spyNextChar === '*') {
                        // double asterix marker exist on these position's so we toggle the bold state
                        isBold = !isBold;
                        currentChar = inputText.charAt(position + 2);
            
                        // now we remove the markers, so loop jumps to the next real printable char
                        let removeMarks = inputText.split('');
                        removeMarks.splice(position, 2);
                        inputText = removeMarks.join('');
                    }
                }
    
                return { char: currentChar, bold: isBold };
            });
            charsMapLength += charsMap.length;
    
            return { ...chars };
        });
    
        this.printCharacters(doc, textRows, startY, startX, fontSize, lineSpacing);
    }
    
    printCharacters(doc, textObject, startY, startX, fontSize, lineSpacing) {
        const startXCached = startX;
        const boldStr = 'bold';
        const normalStr = 'normal';
        textObject.map(row => {
            Object.keys(row).forEach(keys=>{
                doc.setFontType(row[keys].bold ? boldStr : normalStr);
        
                doc.text(row[keys].char, startX, startY);
                startX = startX + doc.getStringUnitWidth(row[keys].char) * fontSize;
            });
    
            startX = startXCached;
            startY += lineSpacing;
        });
    }

  /**
   * fuction used to align a text in the pdf doc
   * @param x x-position
   * @param y y-position
   * @param text the text to be added
   * @param options used for alignment: center | left | right
   * @param doc the pdf variable to which the string must be appended
   */
  getAlignmentValueAny(x: any, y: any, text: string, options: any, doc: any) {
    var options = options || {};
    var defaults = {
      align: 'left',
      width: doc.internal.pageSize.width,
    };
    var settings = $.extend({}, defaults, options);
    var fontSize = doc.internal.getFontSize();
    var text_size =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    if (settings.align === 'center') x += (settings.width - text_size) / 2;
    else if (settings.align === 'right') x += settings.width - text_size;
    doc.text(text, x, y);
  }


  addFeedbacklegends(startY: Number,fontFamily: String,margin: Number,cellFontSize: Number,headCellFontSize: Number,doc: any){
    var row = [["05","04","03","02","01"],["Very Good","Good","Average","Acceptable","Poor"]];
    var column = ['','','','',''];

    var cellStyles = {
      fontSize: cellFontSize,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 0,
      font: fontFamily
    };

    var headCellStyles = {
      fontSize: headCellFontSize,
      fillColor: false,
      textColor: 0,
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: 0,
      minCellHeight: 0,
      cellPadding: 5,
      font: fontFamily
    };

    doc.autoTable(column, row, {
      margin: margin,
      startY: startY,
      headStyles: headCellStyles,
      bodyStyles: cellStyles,
      styles: cellStyles,
      alternateRowStyles: cellStyles,
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index <= 1 && data.column.index >= 0) {
          data.cell.styles.fontStyle = 'bold';
        }
      }
    });
  }
   
}