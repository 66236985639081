import {FormField} from './formService';
import {ConstantsService} from './constantsService';
import * as _ from "lodash";
import * as moment from "moment";

export class Validators {
  static isEmpty(value) {
    return (typeof value === "undefined" || value === null || (typeof value === "string" && value.length === 0));
  }
  static isNumber(value) {
    return /^[0-9]+$/.test(value);
  }
  static isEmail(value) {
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
  }
  static isValidLength(value, min, max) {
    return !Validators.isEmpty(value) && value.toString().length >= min && value.toString().length <= max;
  }
  static isGreater(value, max) {
    return !Validators.isEmpty(value) && !Validators.isNumber(value) && value >= max;
  }
  static isLess(value, min) {
    return !Validators.isEmpty(value) && !Validators.isNumber(value) && value <= min;
  }
  static isDate(value) {
    return moment(value, ConstantsService.DATE_FORMAT).isValid();
  }
  static hasSpaces(value) {
    return value.toString().includes(" ");
  }
  static hasDoubleSpace(value) {
    return value.toString().includes("  ");
  }
  static isAlphabet(value){
    return /^[a-zA-Z ]*$/.test(value);
  }
  static isAlphaNumeric(value){
    return /^[a-z0-9]+$/i.test(value);
  }
  static isAplhaSpecialchar(value){
    return /^[ A-Za-z@./'&+-]*$/.test(value);
  }

  static isMoney(value){
    return /^[0-9 .\Rs]+$/i.test(value);
  }

  static isDecimal(value){
    return /^[0-9]+([.][0-9]+)?$/i.test(value);
  }

  static isTime(value){
    return /(1[012]|0[1-9]):[0-5][0-9] (a|A|p|P)(M|m)/i.test(value);
  }
}

export class Validations {
  static EMPTY: number = 1;
  static NUMBER: number = 2;
  static EMAIL: number = 3;
  static LENGTH: number = 4;
  static MIN: number = 5;
  static MAX: number = 6;
  static DATE: number = 7;
  static SPACES: number = 8;
  static DOUBLE_SPACE: number = 9;
  static ALPHA: number = 10;
  static ALPHANUMERIC: number = 11;
  static ALPHASPECIALCHAR: number =12;
  static MONEY: number = 13;
  static DECIMAL: number = 15;
  static TIME12HOURS: number = 16;
}
