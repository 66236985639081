import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'button-component',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class ButtonComponent {
  @Input() type: string;
  @Input() size:string;
  @Input() name:string;

  constructor() {}

}
