import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, Output,EventEmitter } from '@angular/core';
import { FormField } from '../../services/formService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'image-upload-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

export class ImageUploadField implements AfterViewInit {
  @ViewChild('inputElement') inputElement: ElementRef;
  @Output() uploadedFiles = new EventEmitter<any> ();
  @Output() uploadedImageBase64 = new EventEmitter<any> ();
  @Input()
  set imageBase64(imageBase64: FormField){
    this.setImageBase64(imageBase64)
  }
  @Input() files: Array<File> = [];
  @Input() view_mode: Boolean = false;
  imgURL = '/assets/images/upload_pkoto.png'
  constructor(private translate: TranslateService) {
  }
  ngAfterViewInit() {

  }

  browers(){
    if(!this.view_mode){
      this.inputElement.nativeElement.click()
    }
  }

  setImageBase64(imageBase64: FormField){
    if(imageBase64.value != ""){
      this.imgURL = imageBase64.value
    }else{
      this.imgURL = '/assets/images/upload_pkoto.png'
    }
  }

  fileChangeEvent(input: any) {
    if (input.target.files && input.target.files.length>0) {
      this.files = []
      this.files.push(this.inputElement.nativeElement.files[0]);
      var reader = new FileReader();
      reader.onload = (e:any) =>{
        this.imgURL = e.target.result;
        this.uploadedImageBase64.emit(e.target.result)
        this.uploadedFiles.emit(this.files);
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  }
  remove(){
    this.imgURL = '/assets/images/upload_pkoto.png'
    this.files = []
    this.uploadedImageBase64.emit("")
    this.uploadedFiles.emit(this.files);
  }
}
