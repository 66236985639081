import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange, ViewChild, AfterViewInit, } from '@angular/core';
import { AppModal } from "app/custom-elements/app-modal/component";
import * as moment from 'moment';
import * as _ from 'lodash';
import { start } from 'repl';
import { FormField } from 'app/services/formService';
import { Validations, Validators } from '../../services/validations'
import { generate } from 'rxjs';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  leaveTaken?: boolean;
  leaveApplied?: boolean;
  leaveApproved?: boolean;
  leaveRejected?: boolean;
}

@Component({
  selector: 'timetable-calendar',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})
export class TimetableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('lgModal') public leaveModal: AppModal;
  currentDate = moment();
  dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  weeks: CalendarDate[][] = [];
  sortedDates: CalendarDate[] = [];
  selectedDate = ""
  selectDat = ""
  weekDay = ""
  selectedsubject = ""
  time_table: Array<any> = []
  lessons: Array<any> = []
  leaveData: Array<any> = [];
  date: CalendarDate;
  leave_type: FormField = new FormField("", true, [Validations.EMPTY])
  leaveDateFrom: FormField = new FormField("", true, [Validations.EMPTY])
  LeaveReasons: FormField = new FormField("", true, [Validations.EMPTY])
  leaveDateTo: FormField = new FormField("", true, [Validations.EMPTY])
  @Input() leaveTypes: Array<any> = [];
  // @Input() leaveData: Array<any> = [];
  @Input() timetableData: Array<any> = [];
  @Input() lessonPlaningData: Array<any> = [];
  @Input() selectedDates: CalendarDate[] = [];
  @Output() onSelectDate = new EventEmitter<CalendarDate>();
  @Output() onSelectedSubject = new EventEmitter<any>();
  @Output() onApplyingLeave = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.generateCalendar();
    this.time_table = []
    for (var i = 0; i < this.weeks.length; i++) {
      for (var j = 0; j < this.weeks[i].length; j++) {
        if (this.weeks[i][j].today === true) {
          this.weekDay = this.weeks[i][j].mDate.format("dddd")
          this.selectedDate = this.weeks[i][j].mDate.format("DD MMMM YYYY")
          this.selectDat = this.weeks[i][j].mDate.format("dddd, DD MMM")
          this.date = this.weeks[i][j]
          this.leaveDateFrom.value = this.weeks[i][j].mDate.format("DD-MM-YYYY")
          this.leaveDateFrom.disable();
          this.leaveDateTo.value = this.weeks[i][j].mDate.format("DD-MM-YYYY")
          var start_time: any
          for (var k = 0; k < 24; k++) {
            if (k == 0) {
              start_time = this.weeks[i][j].mDate.startOf("day").format("LT")
              var temp = {}
              temp[start_time] = []
              this.time_table.push(temp)
            }
            else {
              start_time = this.weeks[i][j].mDate.startOf("day").add(k, "hour").format("LT")
              var temp = {}
              temp[start_time] = []
              this.time_table.push(temp)
              this.weeks[i][j].mDate.subtract(k, "hour")
            }
          }
        }
      }
    }
    // this.generateLeaveCalendar();
    // console.log(this.date)
    this.onSelectDate.emit(this.date);
    // console.log(this.time_table)
    // for (var l = 0; l < this.time_table.length; l++) {
    //   Object.keys(this.time_table[l]).forEach(key => {
    //     var temp = []
    //     for (var m = 0; m < data.length; m++) {
    //       if (key == data[m].start_time) {
    //         temp.push(data[m])
    //         this.time_table[l][key] = temp
    //       }
    //     }
    //   })
    // }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates &&
      changes.selectedDates.currentValue &&
      changes.selectedDates.currentValue.length > 1) {
      // sort on date changes for better performance when range checking
      this.sortedDates = _.sortBy(changes.selectedDates.currentValue, (m: CalendarDate) => m.mDate.valueOf());
      this.generateCalendar();
      // console.log(this.timetableData)
    }
    if (changes.timetableData) {
      const tableData: SimpleChange = changes.timetableData;
      this.timetableData = tableData.currentValue
      this.updatetimetable(this.timetableData)
    }
  }

  ngAfterViewInit() {
    // console.log(this.timetableData)
  }


  // date checkers
  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return _.findIndex(this.selectedDates, (selectedDate) => {
      return moment(date).isSame(selectedDate.mDate, 'day');
    }) > -1;
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  selectDate(date: CalendarDate): void {
    for (var i = 0; i < this.weeks.length; i++) {
      for (var j = 0; j < this.weeks[i].length; j++) {
        this.weeks[i][j].selected = false
      }
    }
    date.selected = true
    this.selectedDate = date.mDate.format("DD MMMM YYYY")
    this.selectDat = date.mDate.format("dddd, DD MMM")
    this.weekDay = date.mDate.format("dddd")
    this.prepareTimeGrid()
    this.leaveDateFrom.value = date.mDate.format("DD-MM-YYYY")
    this.leaveDateTo.value = date.mDate.format("DD-MM-YYYY")
    this.leave_type.reset();
    this.LeaveReasons.reset();
    this.date = date
    this.onSelectDate.emit(date);
    // console.log(this.weeks)
  }

  prepareTimeGrid() {
    // console.log("Byeee")
    this.time_table = []
    for (var i = 0; i < this.weeks.length; i++) {
      for (var j = 0; j < this.weeks[i].length; j++) {
        if (this.weeks[i][j].selected == true) {
          this.weekDay = this.weeks[i][j].mDate.format("dddd")
          this.selectedDate = this.weeks[i][j].mDate.format("DD MMMM YYYY")
          var date = this.weeks[i][j].mDate
          var start_time: any
          for (var k = 0; k < 24; k++) {
            if (k == 0) {
              start_time = this.weeks[i][j].mDate.startOf("day").format("LT")

              var temp = {}
              temp[start_time] = []
              this.time_table.push(temp)
            }
            else {
              start_time = this.weeks[i][j].mDate.startOf("day").add(k, "hour").format("LT")
              var temp = {}
              temp[start_time] = []
              this.time_table.push(temp)
              this.weeks[i][j].mDate.subtract(k, "hour")
            }
          }
        }
      }
    }
  }

  selectedSubject(i, key, j) {
    var data = key.value
    var star_end = data[j].start_time + " " + "to" + " " + data[j].end_time
    this.selectedsubject = data[j].course_name
    this.onSelectedSubject.emit(data[j])
  }

  public updatetimetable(data) {
    this.timetableData = _.clone(data)
    // console.log(this.timetableData)
    for (var l = 0; l < this.time_table.length; l++) {
      Object.keys(this.time_table[l]).forEach(key => {
        var temp = []
        for (var m = 0; m < this.timetableData.length; m++) {
          if (key == this.timetableData[m].start_time) {
            temp.push(this.timetableData[m])
          }
          else if (key != this.timetableData[m].start_time && this.time_table.length != (l + 1)) {
            Object.keys(this.time_table[l + 1]).forEach(nextKey => {
              var start_time = moment(this.timetableData[m].start_time, 'LT');
              var gridTime = moment(key, 'LT');

              var nextGridTime = moment(nextKey, 'LT')
              var found = false
              if (moment(start_time).isAfter(gridTime) && moment(start_time).isBefore(nextGridTime)) {
                // for (var i = 0; i < temp.length; i++) {
                //   if (temp[i].staff_id === undefined && )
                //   if (temp[i].start_time == this.timetableData[m].start_time && temp[i].course_code == this.timetableData[m].course_code &&
                //     temp[i].class == this.timetableData[m].class && temp[i].section == this.timetableData[m].section &&
                //     temp[i].batch == this.timetableData[m].batch && temp[i].end_time == this.timetableData[m].end_time) {
                //     found = true
                //   }
                // }
                // if (!found) {
                temp.push(this.timetableData[m])
                // }
              }
            })
          }
          this.time_table[l][key] = temp
        }
      })
    }
    // if staff is logged in then check for multiple batchs if assinged.
    for (var n = 0; n < this.time_table.length; n++) {
      Object.keys(this.time_table[n]).forEach(key => {
        if (this.time_table[n][key].length > 1) {
          for (var p = 0; p < this.time_table[n][key].length; p++) {
            var batch = []
            var otherBatchs: boolean = false
            batch.push(this.time_table[n][key][p].batch)
            for (var q = 0; q < this.time_table[n][key].length; q++) {
              if (this.time_table[n][key][p].start_time === this.time_table[n][key][q].start_time &&
                this.time_table[n][key][p].course_code === this.time_table[n][key][q].course_code &&
                this.time_table[n][key][p].class === this.time_table[n][key][q].class &&
                this.time_table[n][key][p].section === this.time_table[n][key][q].section &&
                this.time_table[n][key][p].batch != this.time_table[n][key][q].batch) {
                batch.push(this.time_table[n][key][q].batch)
                otherBatchs = true
                this.time_table[n][key].splice(q, 1)
              }
            }
            if (otherBatchs) {
              this.time_table[n][key][p].batch = batch
            }
          }
        }
      });
    }
    // if students logged in then check multiple staffs for same course.
    for (var n = 0; n < this.time_table.length; n++) {
      Object.keys(this.time_table[n]).forEach(key => {
        if (this.time_table[n][key].length > 1) {
          for (var p = 0; p < this.time_table[n][key].length; p++) {
            if (this.time_table[n][key][p].staff_id != undefined) {
              var staff_id = []
              var staff_name = []
              var otherBatchs: boolean = false
              staff_id.push(this.time_table[n][key][p].staff_id)
              staff_name.push(this.time_table[n][key][p].staff_name)
              for (var q = 0; q < this.time_table[n][key].length; q++) {
                if (this.time_table[n][key][p].start_time === this.time_table[n][key][q].start_time &&
                  this.time_table[n][key][p].course_code === this.time_table[n][key][q].course_code &&
                  this.time_table[n][key][p].class === this.time_table[n][key][q].class &&
                  this.time_table[n][key][p].section === this.time_table[n][key][q].section &&
                  this.time_table[n][key][p].batch === this.time_table[n][key][q].batch &&
                  this.time_table[n][key][p].staff_id != this.time_table[n][key][q].staff_id) {
                  staff_id.push(this.time_table[n][key][q].staff_id)
                  staff_name.push(this.time_table[n][key][q].staff_name)
                  otherBatchs = true
                  this.time_table[n][key].splice(q, 1)
                }
              }
              if (otherBatchs) {
                this.time_table[n][key][p].staff_name = staff_name
                this.time_table[n][key][p].staff_name = staff_name
              }
            }
          }
        }
      });
    }
    var scroll: boolean = false
    for (var n = 0; n < this.time_table.length; n++) {
      Object.keys(this.time_table[n]).forEach(key => {
        if (!scroll) {
          if (this.time_table[n][key].length > 0) {
            var scroll_to = document.getElementById(key);
            scroll_to.scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
            scroll = true
          }
        }
      });
    }
  }

  public updateCalendarLeaveData(leaveData) {
    this.leaveData = _.cloneDeep(leaveData)
    for (var i = 0; i < this.weeks.length; i++) {
      for (var j = 0; j < this.weeks[i].length; j++) {
        for (var k = 0; k < leaveData.length; k++) {
          Object.keys(leaveData[k]).forEach(dateKey => {
            var date = moment(dateKey, "DD-MM-YYYY")
            if (this.weeks[i][j].mDate.isSame(date)) {
              for (var l = 0; l < leaveData[k][dateKey].length; l++) {

                Object.keys(this.weeks[i][j]).forEach(CalenKey => {
                  if (CalenKey === leaveData[k][dateKey][l]) {
                    this.weeks[i][j][CalenKey] = true;
                  }
                })
              }
              // console.log(dateKey)
            }
          });
        }

      }
    }

  }

  checkObjectNull(value) {
    // console.log(value)
    if (value === null || value === "" || value === undefined || value === "undefined") {
      // console.log("false")
      return false;
    } else {
      // console.log("true")
      return true;
    }
  }

  public updateLessonPlaning() {
    // console.log("HIII")
  }

  sendLeaveData() {
    if (this.leave_type.validate() && this.leaveDateFrom.validate() && this.LeaveReasons.validate()) {
      var leave_type_name = ""
      for (var i = 0; i < this.leaveTypes.length; i++) {
        if (this.leave_type.value === this.leaveTypes[i].value) {
          leave_type_name = this.leaveTypes[i].display
        }
      }
      var temp = { "leave_type_name": leave_type_name, "leave_type_code": this.leave_type.value, "leave_date_form": this.leaveDateFrom.value, "leave_date_to": this.leaveDateTo.value, "leave_reason": this.LeaveReasons.value }
      this.onApplyingLeave.emit(temp)
      this.date.leaveApplied = true;
      this.leaveModal.hide();
    }

  }

  showLeaveModel() {
    this.leave_type.reset();
    this.leaveDateTo.value = this.date.mDate.format("DD-MM-YYYY")
    this.LeaveReasons.reset();
    this.leaveModal.show();
  }

  generateLeaveCalendar(leaveData) {
    // console.log("generate")
    for (var i = 0; i < this.weeks.length; i++) {
      for (var j = 0; j < this.weeks[i].length; j++) {
        for (var k = 0; k < leaveData.length; k++) {
          Object.keys(leaveData[k]).forEach(dateKey => {
            var date = moment(dateKey, "DD-MM-YYYY")
            if (this.weeks[i][j].mDate.isSame(date)) {
              for (var l = 0; l < leaveData[k][dateKey].length; l++) {

                Object.keys(this.weeks[i][j]).forEach(CalenKey => {
                  if (CalenKey === leaveData[k][dateKey][l]) {
                    this.weeks[i][j][CalenKey] = true;
                  }
                })
              }
              // console.log(this.weeks[i][j])
              // console.log(dateKey)
            }
          });
        }

      }
    }
  }

  // actions from calendar
  prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
    this.generateLeaveCalendar(this.leaveData)
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
    this.generateLeaveCalendar(this.leaveData)
  }

  firstMonth(): void {
    this.currentDate = moment(this.currentDate).startOf('year');
    this.generateCalendar();
  }

  lastMonth(): void {
    this.currentDate = moment(this.currentDate).endOf('year');
    this.generateCalendar();
  }

  prevYear(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'year');
    this.generateCalendar();
    this.generateLeaveCalendar(this.leaveData)
  }

  nextYear(): void {
    this.currentDate = moment(this.currentDate).add(1, 'year');
    this.generateCalendar();
    this.generateLeaveCalendar(this.leaveData)
  }


  // generate the calendar grid
  generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks: CalendarDate[][] = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
    const start = firstDayOfGrid.date();
    return _.range(start, start + 42)
      .map((date: number): CalendarDate => {
        const d = moment(firstDayOfGrid).date(date);
        return {
          today: this.isToday(d),
          selected: this.isSelected(d),
          mDate: d,
          leaveTaken: false,
          leaveApplied: false,
          leaveApproved: false,
          leaveRejected: false
        };
      });
  }
}
