import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import 'rxjs/Rx';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as _ from "lodash";

const config =
  {
    bucketName: 'aakar-nie',
    accessKeyId: 'AKIAJB7QATKEBE5LHOPQ',
    secretAccessKey: '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',
    region: 'ap-south-1'
  }

 
@Injectable()
export class UploadFileService {
    
 public source = new BehaviorSubject<any>(' ');


  data = this.source.asObservable();

  fileUpload(file) {
    const bucket = new S3(
      {
        accessKeyId: 'AKIAJB7QATKEBE5LHOPQ',
        secretAccessKey: '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',
        region: 'ap-south-1'
      }
    );
 
    const params = {
      Bucket: 'aakar-nie',
      Key: file.name,
      ACL: 'public-read',
      Body: file
    };
	    // var putObjectPromise = bucket.putObject(params).promise();

    // var that = this;

    //       console.log(this.user)


    // putObjectPromise.then(function(data){
    // })

    var that = this ;

    bucket.upload(params, function (err, data) {
      console.log('Successfully uploaded file.', data);
      that.source.next(data)
    });

  }


  // fileUpload(file){

  // var that = this;
  // S3Client.uploadFile( file, config , function (err, data){
  // 	console.log('Successfully uploaded file.', data);
  // 	console.log("I have uploaded",data)
  // 	console.log(" uploaded",data)
  // 	that.source.next(data)
  // });

// }


 
}