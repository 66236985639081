import { Component, Input, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { FormField } from '../../services/formService';
import { ConstantsService } from '../../services/constantsService';
import { Validations, Validators } from '../../services/validations';
import {TranslateService} from '@ngx-translate/core';
import * as moment from "moment";

@Component({
  selector: 'datepicker-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class DatepickerField {
  @ViewChild('textInput') inputElement: ElementRef;
  @Input() form: FormField;
  @Input() label: string;
  @Input() submitted: boolean;
  @Input() name: string;
  errorMessage: string = "";
  date: any = "";
  datepickerShown: boolean = false;
  constructor(private translate: TranslateService) {

  }
  showDatepicker() {
    if (!this.form.isDisabled()) {
      if (Validators.isDate(this.form.value)) {
        this.date = moment(this.form.value, ConstantsService.DATE_FORMAT).toDate();
      } else {
        this.date = moment().toDate();
      }
      this.datepickerShown = true;
    }
  }
  pickDate() {
    this.form.value = moment(this.date).format(ConstantsService.DATE_FORMAT);
    this.form.valueChanged();
    this.hideDatepicker();
    this.inputElement.nativeElement.focus();
  }
  hideDatepicker() {
    this.datepickerShown = false;
  }
  handleTabPress() {
    this.form.tabPressHandler.next(new Date());
  }
  handleShiftTabPress(){
    this.form.shiftTabPressHandler.next(new Date());
  }
}
