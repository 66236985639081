import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router'
import { RouterService } from './services/routerService';
import { ContextService } from './services/contextService';
import { AppSpinner } from './custom-elements/app-spinner/component'
import { AlertModal } from './custom-elements/alert-modal/component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [RouterService]
})
export class AppComponent implements AfterViewInit {
  @ViewChild(AppSpinner) public appSpinner: AppSpinner;
  @ViewChild(AlertModal) public alertModal: AlertModal;
  // currentRoute: string = "";
  constructor(private router: Router,private routerService: RouterService, private contextService: ContextService) {
  }
  ngAfterViewInit() {
    // console.log(this.router.url)
    this.contextService.setSpinnerContext(this.appSpinner);
    this.contextService.setAlertContext(this.alertModal);
    this.routerService.openDefaultPage();
  }
}
