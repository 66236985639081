import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'textarea-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class TextareaField implements AfterViewInit{
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() form: FormField;
  @Input() label: string;
  @Input() readonly: any;
  @Input() disabled: any;
  @Input() tabindex: number;
  errorMessage: string = "";
  constructor(private translate: TranslateService) {

  }
  ngAfterViewInit(){
    this.form.focusObserver.asObservable().subscribe(value => {
      this.inputElement.nativeElement.focus();
    });
  }
  handleTabPress() {
    this.form.tabPressHandler.next(new Date());
  }
  handleShiftTabPress(){
    this.form.shiftTabPressHandler.next(new Date());
  }
}
