import { Component, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';



export interface StudentList{
    batches: string;
    students: Array<any>;
}

@Component({
  selector: 'drag-drop',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})



export class DragDropComponent {

    @Input() testData: any = [];

    private listofStudents: StudentList[] = [
        // {
        //     "batches": "a",
        //     "students": [['A','3asd4s'],['B','1ad23d'],['C','asd']]
        // }
        {
            "batches": "B1",
            "students": [['A','3asd4s'],['B','1ad23d'],['C','asd']]
        },
        {
            "batches": "B2",
            "students": [['D','324s'],['E','123d'],['F','345']]
        },
        {
            "batches": "B3",
            "students": [['G','32as4s'],['H','453d'],['I','989d']]
        },
        {
            "batches": "B4",
            "students": [['G','32as4s'],['H','453d'],['I','989d']]
        },
        {
            "batches": "B5",
            "students": [['G','32as4s'],['H','453d'],['I','989d']]
        }
    ]
    
    batches_created: boolean
    trackIds: any;
    constructor(){
        this.trackIds = this.listofStudents.map(res => res.batches)
        this.checkifEmpty();

    }

    ngOnInit() {
    }
    onTalkDrop(event: CdkDragDrop<StudentList[]>) {
      // In case the destination container is different from the previous container, we
      // need to transfer the given task to the target data array. This happens if
      // a task has been dropped on a different track.
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  
    onTrackDrop(event: CdkDragDrop<StudentList[]>) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    getLists(){
    }

    checkifEmpty(){
        if (this.trackIds[0] == ""){
            this.batches_created = false
        } 
        else{
            this.batches_created = true;
        }
    }

}