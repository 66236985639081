import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../../services/formService';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'checkbox-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class CheckboxField implements AfterViewInit{
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() form: FormField;
  @Input() label: string;
  @Input() submitted: boolean;
  @Input() name: string;
  @Input() tabindex: number;
  errorMessage: string = "";
  constructor(private translate: TranslateService) {

  }
  ngAfterViewInit(){
    this.form.focusObserver.asObservable().subscribe(value => {
      this.inputElement.nativeElement.focus();
    });
  }
  handleTabPress() {
    this.form.tabPressHandler.next(new Date());
  }
}
