import { Component, ViewChild, ElementRef, Output, EventEmitter, Input, TemplateRef, SimpleChange, OnChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { RouterService } from '../../services/routerService'
import { ContextService } from '../../services/contextService'
import { ConstantsService } from '../../services/constantsService'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class AppModal implements OnChanges{
  @ViewChild('modal') modal: BsModalRef;
  @Input() modalTitle: string = "";
  @Input() modalSize: string = "modal-xl";
  @Input() modalOrder: string = "1";
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();
  @Output() modalShown: boolean = false;
  @Input() OnCloseValidation: Boolean = false
  constructor(private modalService: BsModalService) {
  }
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
  }
  show = function() {
    this.modalShown = true;
    this.modal.show();
  }
  hide = function() {
    if(!this.OnCloseValidation){
      this.modal.hide()
      this.modalShown = false;
      this.modalClosed.emit();
    }else{
      this.modalClosed.emit();
    }
  }
  
  setOnCloseValidation(boolean: Boolean){
    this.OnCloseValidation = boolean
  }
}
