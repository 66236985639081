import {Injectable} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';

@Injectable()

export class i18nService {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en_US');
    this.translate.use('en_US');
  }
  setLanguage(language) {
    this.translate.use(language);
  }
}
