import {Injectable} from "@angular/core";
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpService } from './httpService';
// declare var require: any;
declare var window: any;
// require('aws-sdk/dist/aws-sdk');
@Injectable()

export class StorageService {
  constructor(private localStorageService: LocalStorageService) {
  }
  writeData(key, dataString) {
    this.localStorageService.set(key, dataString);
  }
  readData(key) {
    return this.localStorageService.get(key);
  }
  deleteData(key) {
    this.localStorageService.remove(key);
  }
  uploadToAWS(fileInput: any) {
    var bucketName = "";
    var access_key = "";
    var secret_key = "";
    var AWSService = window.AWS;
    AWSService.config.update({ accessKeyId: access_key, secretAccessKey: secret_key });
    AWSService.config.region = 'ap-south-1';
    var bucket = new AWSService.S3({ params: { Bucket: bucketName } });
    var file = fileInput.target.files[0];
    var params = { Key: "user1/" + file.name, Body: file };
    bucket.upload(params, function(err, data) {
      console.log(err, data);
    });
  }
  downloadFromAWS(keyName: string){
    var bucketName = "";
    var access_key = "";
    var secret_key = "";
    var AWSService = window.AWS;
    AWSService.config.update({ accessKeyId: access_key, secretAccessKey: secret_key });
    AWSService.config.region = 'ap-south-1';
    var s3 = new AWSService.S3();
    s3.getObject({ Bucket: bucketName, Key: keyName },
      function(error, data) {
        if (error != null) {
          alert("Failed to retrieve an object: " + error);
        } else {
          alert("Loaded " + data.ContentLength + " bytes");
          // do something with data.Body
        }
      });
  }
}
