import { NgModule } from '@angular/core';
import { BaseModule } from '../modules/base.module';

import { AppHeader } from './app-header/component';
import { AppFooter } from './app-footer/component';
import { AppModal } from './app-modal/component';
import { AppSpinner } from './app-spinner/component';
import { InputField } from './input-field/component';
import { CodeField } from './code-field/component';
import { TextareaField } from './textarea-field/component';
import { DatepickerField } from './datepicker-field/component';
import { AlertModal } from './alert-modal/component';
import { CheckboxField } from './checkbox-field/component';
import { RadioField } from './radio-field/component';
import { DropdownField } from './dropdown-field/component';
import { TableComponent } from './table/component';
import { FileUploadField } from './file-upload-field/component';
import { MatTableComponent } from './mat-table/component';
import { DragDropComponent } from './dragDrop/component';
import { TimetableComponent } from './timetable/component';
import {PageHeader} from './page-header/component';
import {MainCardComponent} from './main-card/component';
import {SubCardComponent} from './sub-card/component';
import {ButtonComponent} from './buttons/component';
import {MultiSelect} from './multi-select/component';
import {ImageUploadField} from './image-upload-field/component';

@NgModule({
  declarations: [
    AppHeader,
    AppFooter,
    AppSpinner,
    InputField,
    CheckboxField,
    RadioField,
    DatepickerField,
    TextareaField,
    DropdownField,
    AlertModal,
    TableComponent,
    MatTableComponent,
    AppModal,
    CodeField,
    DragDropComponent,
    FileUploadField,
    TimetableComponent,
    PageHeader,
    MainCardComponent,
    SubCardComponent,
    ButtonComponent,
    MultiSelect,
    ImageUploadField
  ],
  imports: [
    BaseModule
  ],
  bootstrap: [],
  exports: [
    AppHeader,
    AppFooter,
    AppSpinner,
    InputField,
    CheckboxField,
    RadioField,
    DatepickerField,
    TextareaField,
    DropdownField,
    MatTableComponent,
    AlertModal,
    TableComponent,
    AppModal,
    CodeField,
    DragDropComponent,
    FileUploadField,
    TimetableComponent,
    PageHeader,
    MainCardComponent,
    SubCardComponent,
    ButtonComponent,
    MultiSelect,
    ImageUploadField
  ]
})
export class ElementsModule {
  constructor() {
  }
}
