import { Injectable } from "@angular/core";

const gsssInstallmentData = {
        "2022-2023":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10"],
                value: 10000
            }
        ],
        "2023-2024":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2024-2025":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2025-2026":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2026-2027":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2027-2028":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2028-2029":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ],
        "2029-2030":[
            {
                isPercentage: false,
                levelCodes : ["GSSSHPSLKG","GSSSHPSUKG","GSSSHPS1","GSSSHPS2","GSSSHPS3","GSSSHPS4","GSSSHPS5","GSSSHPS6","GSSSHPS7","GSSSHS8","GSSSHS9","GSSSHS10","Commerce 1","Science 1","Commerce 2","Science 2"],
                value: 10000
            }
        ]
    }





@Injectable()
export class StoredDataService {
    constructor(){}

    gsssOnlinePaymentTutionFee(academicYear,levelCode){
        // Gets data for the installment
        let detailObject: any = this.searchInstallmentData(academicYear,levelCode); 
        return detailObject;
    }

    private findTutionFeeDetail(detailObject,feeDetail,previousFeePaid){
        console.log(detailObject,feeDetail,previousFeePaid)
        //is percentage=> send the tutionfee % value for level & AY
        //if not => send the tution fee amount (installmentAmount - sum(otherFeeTypesAmount))
        switch (detailObject.isPercentage) {
            case true:
                return (!previousFeePaid) ? {isPercentage: true,value: detailObject.values[1]} : {isPercentage: true,value: detailObject.values[2]}
            case false:
                let otherFeeTypes = feeDetail.filter(x=> x.display.trim().toLowerCase().split(' ').join('') != "tuitionfee" && x.display.trim().toLowerCase().split(' ').join('') != "tf")   
                let otherFeeAmount = 0
                otherFeeTypes.forEach(fee=> {
                    otherFeeAmount = otherFeeAmount + fee.amount;
                });
                return (!previousFeePaid) ? {isPercentage: false, value: detailObject.values[1]} : {isPercentage: false,value: detailObject.values[2]}
            default:
                break;
        }
    }

    private searchInstallmentData(academicYear,levelCode){
        let found:boolean = false;
        let detail = {}; 
        // find for level & Ay the fee amount details
        Object.keys(gsssInstallmentData).forEach(key =>{
            if(key == academicYear){
                for(var i=0; i<gsssInstallmentData[key].length; i++){
                    if(gsssInstallmentData[key][i].levelCodes.includes(levelCode)){
                        detail = gsssInstallmentData[key][i]; 
                        found = true;
                    }
                }
            }
        });
        return {found: found, detail: detail};
    }
}