
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from "./httpService"

let BRANCH_LIST_URL = "branches/list";
let DEPARTMENT_LIST_URL = "departments/list";

@Injectable()
export class UtilityService {
  constructor(private httpService: HttpService) {
  }
  getBranches() {
    return this.httpService.get(BRANCH_LIST_URL).pipe(map(res => {
      var branchList = [];
      if (res.length > 0) {
        for (var i = 0; i < res.length; i++) {
          branchList.push({
            display: res[i].branch_name,
            value: res[i].branch_code
          })
        }
      }
      return branchList;
    }));
  }
  getDepartments() {
    return this.httpService.get(DEPARTMENT_LIST_URL).pipe(map(res=>{
      var departmentList = [];
      if (res.length > 0) {
        for (var i = 0; i < res.length; i++) {
          departmentList.push({
            display: res[i].department_name,
            value: res[i].department_code,
            branch_code: res[i].branch_code
          })
        }
      }
      return departmentList;
    }));
  }

}
