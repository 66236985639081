import { Injectable } from "@angular/core";

@Injectable()

export class ConstantsService {
  public static ROUTE_NON_SESSION = "non-session";
  public static ROUTE_NON_SESSION_APPLY = "admission-page"
  public static ROUTE_SESSION = "session";
  public static ROUTE_REGISTRATION = "registration";
  public static ROUTE_STAFF_ENTRY = "staff-entry";
  public static ROUTE_STAFF_VIEW = "staff-view";
  public static ROUTE_STUDENT_ENTRY = "student-entry";
  public static ROUTE_STUDENT_TC_LIST = "student-tc-list";
  public static ROUTE_STUDENT_VIEW = "student-view";
  public static ROUTE_CLASSROOM_ENTRY = "classroom-entry";
  public static ROUTE_BRANCH_ENTRY = "branch-entry";
  public static ROUTE_REGULATION_ENTRY = "regulation-entry";
  public static ROUTE_REGULATION_MODAL = "regulation-modal";
  public static ROUTE_CONCESSION_ENTRY = "concession-entry";
  public static ROUTE_BRANCH_VIEW = "branch-view";
  public static ROUTE_BRANCH_MODAL = "branch-modal";
  public static ROUTE_FEE_MODAL = "fee-modal";
  public static ROUTE_CONCESSION_MODAL = "concession-modal";
  public static ROUTE_AUTHORIZATION = "authorization";
  public static ROUTE_DEPARTMENT = "department";
  public static ROUTE_DEPARTMENT_MODAL = "department-modal";
  public static ROUTE_STAFF_MODAL = "staff-modal";
  public static ROUTE_STUDENT_MODAL = "student-modal";
  public static ROUTE_DESIGNATION = "designation";
  public static ROUTE_DESIGNATION_MODAL = "designation-modal";
  public static ROUTE_COURSE = "course";
  public static ROUTE_COURSE_MARKS = "course-marks"
  public static ROUTE_COURSE_MODAL = "course-modal";
  public static ROUTE_SCREEN = "screen-configuration";
  public static ROUTE_FEE_ASSINGMENT = "fee-assingment";
  public static ROUTE_COURSE_REGISTRATION = "course-registration";
  public static ROUTE_COURSE_REGISTRATION_MODAL = "course-registration-modal";
  public static ROUTE_COURES_STUDENT_RESGISTRATION = "student-course-registartion"
  public static ROUTE_MARKS = "marks-entry";
  public static ROUTE_STUDENT_DASHBOARD = "student-dashboard";
  public static ROUTE_MARKS_MODAL = "marks-entry-modal";
  public static ROUTE_STAFF_DASHBOARD = 'staff-dashboard'
  public static ROUTE_COURSE_ENTRY = "course-entry"
  public static ROUTE_AUTH_STATUS = "fauthorization-status";
  public static ROUTE_CHALLAN = "challan";
  public static ROUTE_COMMUNICATION = "communication";
  public static ROUTE_FEEDUE = "fee-dues";
  public static ROUTE_SUPER_ADMIN_DASHBOARD = "super-admin-dashboard";
  public static ROUTE_SYLLABUS = "syllabus-screen";
  public static ROUTE_COURSE_TEST_CONFIG = "course-test-config"
  public static ROUTE_LESSON_PLANNING = "lesson-planning"
  public static ROUTE_ADMISSION_AUTHORIZATION = "admission-authorization"
  public static ROUTE_ADMISSION_VIEW = "admission-view"
  public static ROUTE_COURSE_VIEW = "course-view"
  public static ROUTE_COURSE_CREATE = "course-create"
  public static ROUTE_COURSE_EDIT = "course-edit"
  public static ROUTE_ELECTIVE_COURSE = "elective-subject"
  public static ROUTE_ELECTIVE_COURSE_VIEW = "elective-subject-view"
  public static ROUTE_ELECTIVE_COURSE_CREATE = "elective-subject-create"
  public static ROUTE_ELECTIVE_COURSE_REGISTRATION = "elective-subject-registration"
  public static ROUTE_MARKS_ENTRY = "marks-entry"
  public static ROUTE_MARKS_ENTRY_VIEW = "marks-entry-view"
  public static ROUTE_MARKS_ENTRY_ADD = "marks-add"
  public static ROUTE_MARKS_ENTRY_MODIFY = "marks-modify"
  public static ROUTE_BULK_MARKS_ENTRY = "bulk-marks-entry"
  public static ROUTE_BULK_MARKS_ENTRY_VIEW = "bulk-marks-entry-view"
  public static ROUTE_BULK_MARKS_ENTRY_ADD = "bulk-marks-add"
  public static ROUTE_BULK_MARKS_ENTRY_MODIFY = "bulk-marks-modify"
  public static ROUTE_ASSESSMENT_MARKS_ENTRY = "assessment-marks-entry"
  public static ROUTE_ASSESSMENT_MARKS_ENTRY_VIEW = "assessment-marks-view"
  public static ROUTE_ASSESSMENT_MARKS_ENTRY_ADD = "assessment-marks-add"
  public static ROUTE_ASSESSMENT_MARKS_ENTRY_MODIFY = "assessment-marks-modify"
  public static ROUTE_RESULT_VIEW = "result-view"
  public static ROUTE_ADMISSIONAUTHORIZATION_LIST = "admission-authorization-list"
  public static ROUTE_CUSTOMIZE_FIELD_ENTRY = "ustomize-field"
  public static ROUTE_STUDENT_MERIT_UPDATE = "student-merit-update"
  public static ROUTE_STUDENT_MERIT_UPDATE_LIST = "student-merit-update-view"
  public static ROUTE_STUDENT_MERIT_UPDATE_ADD = 'student-merit-update-add'
  public static ROUTE_CIE_MARK_MODIFY = 'cie-mark-modify'
  public static ROUTE_DISCONTINUED_STUDENTS = 'students-discontinued'
  public static APPROVE_PARTIAL_PAYMENT = 'partial-payment'
  public static ROUTE_TEST_TYPE = "test-types"
  public static ROUTE_COURSE_TEST_SCHEMA = "test-schema"
  public static ROUTE_RESULT = "result-list"
  public static ROUTE_MICRO_ANALYSIS = "micro-analysis"
  public static ROUTE_COE_RESULT = "coe-result"
  public static ROUTE_STUDENT_RESULT = "student-result"
  public static ROUTE_CERTIFICATE = "certificate"
  public static ROUTE_TIMETABLE = "time-table"
  public static ROUTE_TIMETABLECONFIG = "time-table-config"
  public static ROUTE_TEACHER_DASHBOARD = "teacher-dashboard"

  public static ROUTE_COURSE_ALLOTMENT = "course-allotment";
  public static ROUTE_ATTENDANCE_CONFIG = "attendance-config";
  public static ROUTE_NEWCONFIG_CLASSROOM = "new-config-classroom";
  public static ROUTE_NEWCONFIG_STUDENT = "new-config-student";
  public static ROUTE_DEMOTION = "demotion-student";
  public static ROUTE_COURSE_MANAGEMENT = "course-management";
  public static ROUTE_SUBJECT_ATTENDANCE = "subjectwise-attendance";
  public static ROUTE_FEE_TYPE = "fee-type";
  public static ROUTE_FEE_TYPE_VIEW = "fee-type-view";
  public static ROUTE_FEE_TYPE_ADD = "fee-type-add";
  public static ROUTE_FEE_TYPE_MODIFY = "fee-type-modify";
  public static ROUTE_FEE_ASSIGN = "fee-assign";
  public static ROUTE_CONCESSION_VIEW = "concession-view"
  public static ROUTE_CONCESSION_CREATE = "concession-add"
  public static ROUTE_CONCESSION_MODIFY = "concession-modify"
  public static ROUTE_CONCESSION_ASSIGNMENT = "concession-assignment"
  public static ROUTE_FEE_REPORT = "fee-report";
  public static ROUTE_FEE_PAYMENT_UPLOAD = "fee-payment-upload";
  public static ROUTE_FEE_TYPE_BREAKUP_ENTRY = "fee-type-breakup";
  public static ROUTE_FEE_TYPE_BREAKUP_LIST = "fee-type-breakup-list"
  public static ROUTE_FEE_TYPE_BREAKUP_ADD = "fee-type-breakup-add"
  public static ROUTE_CLASS_WISE_REPORT = "class-wise-report"
  public static ROUTE_INTRA_TRUST_ADMISSION = "trust-admission";
  public static ROUTE_SILICON_ADMISSION = "silicon-admission-page";
  public static ROUTE_FEE_STRUCTURE = "fee-structure"
  public static ROUTE_FEE_STRUCTURE_LIST = "fee-structure-list"
  public static ROUTE_FEE_STRUCTURE_ADD = "fee-structure-add"
  public static ROUTE_FEE_STRUCTURE_VIEW = "fee-structure-view"
  public static ROUTE_FEE_SERVICE = "fee-service"
  public static ROUTE_FEE_SERVICE_LIST = "fee-service-list"
  public static ROUTE_FEE_SERVICE_ADD = "fee-service-add"
  public static ROUTE_NEW_CONCESSION_ENTRY = "new-concession-entry";
  public static ROUTE_NEW_CONCESSION_VIEW = "new-concession-view"
  public static ROUTE_NEW_CONCESSION_CREATE = "new-concession-add"
  public static ROUTE_NEW_CONCESSION_MODIFY = "new-concession-modify"
  public static ROUTE_NEW_FEE_PAYMENT = "fee-payment"
  public static ROUTE_NEW_FEE_ASSIGNED = "fee-assigned"
  public static ROUTE_NEW_CONCESSION_ASSIGNMENT = "new-concession-assignment"
  public static ROUTE_NEW_FEE_REPORT = "new-fee-report"
  public static ROUTE_SUBJECT = "subject"
  public static ROUTE_SUBJECT_LIST = "subject-list"
  public static ROUTE_SUBJECT_ADD = "subject-add"
  public static ROUTE_SUBJECT_OFFER = "group-subject-offer"
  public static ROUTE_SUBJECT_OFFER_LIST = "subject-offer-list"
  public static ROUTE_SUBJECT_OFFER_ADD = "subject-offer-add"
  public static ROUTE_ATTENDANCE_REGISTER = "attendance-register"
  public static ROUTE_ATTENDANCE_REGISTER_LIST = "attendance-register-list"
  public static ROUTE_ATTENDANCE_REGISTER_ADD = "attendance-register-add"
  public static ROUTE_ATTENDANCE_REGISTER_VIEW = "attendance-register-view"
  public static ROUTE_TEACHER_ASSIGNMENT = "teacher-assign"
  public static ROUTE_TEACHER_ASSIGNMENT_LIST = "teacher-assign-list"
  public static ROUTE_TEACHER_ASSIGNMENT_ADD = "teacher-assign-add"
  public static ROUTE_GROUP = "group";
  public static ROUTE_GROUP_ADD = "group-add";
  public static ROUTE_GROUP_LIST = "group-list";
  public static ROUTE_ATTENDANCE = "attendance";
  public static ROUTE_RECEIPT_REPRINT = "receipt-reprint"
  public static ROUTE_ATTENDANCE_REPORT = "attendance-report";
  public static ROUTE_STUDENT_PHOTO_UPLOAD = "student-photo-upload";
  public static ROUTE_TEST = "test";
  public static ROUTE_TEST_LIST = "test-list"
  public static ROUTE_TEST_ADD = "test-add"
  public static ROUTE_TEST_ALLOTMENT = "test-allotment"
  public static ROUTE_TEST_ALLOTMENT_LIST = "test-allotment-list"
  public static ROUTE_TEST_ALLOTMENT_ADD = "test-allotment-add"
  public static ROUTE_TEST_ALLOTMENT_VIEW = "test-allotment-view"
  public static ROUTE_MARKS_ENTRY_NEW = "marks-entry-new"
  public static ROUTE_MARKS_ENTRY_NEW_LIST = "marks-entry-new-list"
  public static ROUTE_MARKS_ENTRY_NEW_ADD = "marks-entry-new-add"
  public static ROUTE_MARKS_ENTRY_NEW_EDIT = "marks-entry-new-edit"
  public static ROUTE_ATTENDANCE_CONSOLIDATE_REPORT = "attendance-consolidate-report";
  public static ROUTE_TIME_TABLE_UPLOAD = "time-table-upload";

  public static ROUTE_TIME_TABLE_NEW = "time-table-new";
  public static ROUTE_TIME_TABLE_NEW_ADD= "time-table-new-add";
  public static ROUTE_TIME_TABLE_NEW_EDIT = "time-table-new-edit";
  public static ROUTE_TIME_TABLE_NEW_VIEW = "time-table-new-view";
  public static ROUTE_TIME_TABLE_NEW_LIST = "time-table-new-list";
  public static ROUTE_TIME_TABLE_CONFIG = "time-table-config";
  public static ROUTE_TIME_TABLE_CONFIG_ADD= "time-table-new-add";
  public static ROUTE_TIME_TABLE_CONFIG_EDIT = "time-table-config-edit";
  public static ROUTE_TIME_TABLE_CONFIG_VIEW = "time-table-config-view";
  public static ROUTE_TIME_TABLE_CONFIG_LIST = "time-table-config-list";
  public static ROUTE_FORGET_PASSWORD = "forgot-password";
  public static ROUTE_MARKS_ENTRY_ALL_SUBJECT = "marks-entry-all-subject"
  public static ROUTE_MARKS_ENTRY_ALL_SUBJECT_LIST = "marks-entry-all-subject-list"
  public static ROUTE_MARKS_ENTRY_ALL_SUBJECT_ADD = "marks-entry-all-subject-add"
  public static ROUTE_MARKS_ENTRY_ALL_SUBJECT_EDIT = "marks-entry-all-subject-edit"
  public static ROUTE_ATTENDANCE_ALL_SUBJECT = "attendance-all-subject";
  public static ROUTE_BU_INTERNAL_REPORT = "BU-internal-report";
  public static ROUTE_PARENT_LOGIN = "parent-login"
  public static ROUTE_ONLINE_PAYMENT = "online-payment"
  public static ROUTE_PREVIOUS_ACADEMIC_YEAR_PAYMENT = 'previous-academic-year-payment'
  public static ROUTE_PREVIOUS_ACADEMIC_YEAR_PAYMENT_NEW_FLOW = 'previous-year-fee-payment-new-flow'
  public static ROUTE_STUDENT_FEEDBACK_ON_TEACHERS = "student-feedback-teachers";
  public static ROUTE_FACULTY_FEEDBACK_ON_CURRICULUM = "faculty-feedback-curriculum";
  public static ROUTE_ALUMNI_FEEDBACK_ON_CURRICULUM = "alumni-feedback-curriculum";
  public static ROUTE_STUDENT_FEEDBACK_ON_SYLLABUS = "feedback-on-syllabus"
  public static ROUTE_FEEDBACK_DOWNLOAD = "feedback-download"
  public static ROUTE_CONSOLIDATED_FEE_DETAILS = "consolidated-fee-details" 
  public static ROUTE_APPRAISEE_ENTRY = "appraisee-entry"
  public static ROUTE_APPRAISER_ENTRY = "appraiser-entry"
  public static ROUTE_OTHER_FEE_PAYMENT = "other-fee-payment"
  public static ROLE_SUPERADMIN = "superadmin";
  public static ROLE_STAFF = "staff";
  public static ROLE_STUDENT = "student";

  public static LOGIN_TOKEN = "login_token";
  public static DATE_FORMAT = "DD-MM-YYYY";
  public static DESGN_SUPERADMIN = "SUPERADMIN";
  public static DESGN_ADMIN = "ADMIN";
  public static DESGN_TEACHER = "TEACHER";
  public static MODE_ADD = "ADD";
  public static MODE_UPDATE = "UPDATE";
  public static MODE_VIEW = "VIEW";
  public static MODE_AUTHORIZATION = "AUTHORIZATION";
  public static MODE_STUDENT_TC_MODIFY = "student-tc-modify";
  public static MODE_STUDENT_TC_VIEW = "student-tc-view";
  public static ALERT_SUCCESS = "SUCCESS";
  public static ALERT_ERROR = "ERROR";
  public static ALERT_NEUTRAL = "NEUTRAL";
  public static DUPLICATE_RECORD = 409;
  public static MODAL_ALERT = "ALERT";
  public static MODAL_CONFIRM = "CONFIRM";
  
}
