import { Component, OnInit, Input, Directive, ViewChild, OnChanges } from '@angular/core';
import { Router } from '@angular/router'
import { RouterService } from '../../services/routerService'
import { ContextService } from '../../services/contextService'
import { ConstantsService } from '../../services/constantsService'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

export class AppHeader implements OnInit {
  currentRoute: string = "";
  constructor(private router: Router, private routerService: RouterService, private contextService: ContextService, private translateService: TranslateService) {
  }
  ngOnInit() {
    this.routerService.routeChangeListener.subscribe(currentRoute => {
      this.currentRoute = currentRoute;
    })
  }
  goHome = function() {
    if (this.currentRoute === ConstantsService.ROUTE_REGISTRATION) {
      this.routerService.openLandingPage();
    }
  }
  clickHeaderButton = function() {
    this.contextService.headerButtonClicked();
  }
  isHeaderButtonHidden = function() {
    return this.currentRoute === ConstantsService.ROUTE_REGISTRATION;
  }
}
