import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})

export class AppFooter {
  constructor() {}
}
