import { Validators, Validations } from './validations';
import { Observable, ReplaySubject } from 'rxjs';

export class FormField {
  public value: any;
  private valueStack: ReplaySubject<any> = new ReplaySubject(2);
  private changeObservable: ReplaySubject<any> = new ReplaySubject(1);
  private valueObservable: any;
  private defaultValue: any;
  private mandatory: boolean = false;
  public valid: boolean = true;
  private shown: boolean = true;
  public errorMessage: string = "";
  public validations: Array<any> = [];
  public conditionalValidation: any;
  private disabled: boolean = false;
  private readonly: boolean = false;
  constructor(...args: any[]) {
    if (typeof args[0] !== "undefined") {
      this.defaultValue = args[0];
    }
    if (typeof args[1] !== "undefined") {
      this.mandatory = args[1];
    }
    if (typeof args[2] !== "undefined") {
      this.validations = args[2];
    }
    if (typeof args[3] !== "undefined") {
      this.conditionalValidation = args[3];
    } else {
      this.conditionalValidation = function () {
        return true;
      }
    }
  }
  valueChanged() {
    this.valueStack.next(this.value);
    var values = [];
    this.valueStack.forEach(next => {
      values.push(next);
    });
    if (typeof values[1] === "undefined") {
      values[1] = values[0];
      values[0] = undefined;
    }
    this.changeObservable.next({ oldValue: values[0], newValue: values[1] });
    this.clearError();
  }
  valueChangeListener() {
    this.changeObservable = new ReplaySubject(1);
    return this.changeObservable.asObservable();
  }
  setMandatory() {
    this.mandatory = true;
  }
  setOptional() {
    this.mandatory = false;
  }
  isMandatory() {
    return this.mandatory;
  }
  reset() {
    this.value = this.defaultValue;
    this.valueStack.next("");
    this.valueStack.next(this.value);
    this.disabled = false;
    this.clearError();
  }
  setError(errorMessage: string) {
    this.valid = false;
    this.errorMessage = errorMessage;
  }
  clearError() {
    this.valid = true;
    this.errorMessage = "";
  }
  disable() {
    this.disabled = true;
  }
  enable() {
    this.disabled = false
  }
  isDisabled() {
    return this.disabled;
  }
  show() {
    this.shown = true;
  }
  hide() {
    this.shown = false;
  }
  setReadonly(readonly) {
    this.readonly = readonly;
  }
  isReadonly() {
    return this.readonly;
  }
  focus() {
    this.focusObserver.next(new Date());
  }
  validate(formFields?: any) {
    return FormService.validateField(this, true, formFields);
  }
  isValid(formFields?: any) {
    return FormService.validateField(this, false, formFields);
  }
  public focusObserver: ReplaySubject<any> = new ReplaySubject(1);
  public tabPressHandler: ReplaySubject<any> = new ReplaySubject(1);
  public shiftTabPressHandler: ReplaySubject<any> = new ReplaySubject(1);
}

export class FormService {
  static isValid(formFields: any) {
    var errors = 0;
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        if (!field.valid) {
          errors++;
        }
      }
    };
    return errors == 0;
  }
  static getData(formFields: any) {
    var dataObject = {};
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        dataObject[fieldName] = field.value;
      }
    };
    return dataObject;
  }
  static reset(formFields: any) {
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        field.reset();
      } else if (field instanceof Array) {
        formFields[fieldName] = [];
      }
    };
  }
  static validate(formFields: any) {
    var formErrors = 0;
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        if (!FormService.validateField(field, true, formFields)) {
          formErrors++;
        }
      }
    }
    return formErrors === 0;
  }
  static validateField(field: FormField, errorRequired: boolean, formFields?: any) {
    var errors = 0;
    field.clearError();
    var validations = field.validations;
    if (typeof formFields === "undefined" || field.conditionalValidation.apply(formFields)) {
      for (var i = 0; i < validations.length; i++) {
        var validation;
        if (validations[i] instanceof Array) {
          validation = validations[i][0];
        } else {
          validation = validations[i];
        }
        var errorMessage = "";
        switch (validation) {
          case Validations.EMPTY:
            if (Validators.isEmpty(field.value)) {
              errorMessage = "Field Cannot be empty.";
              errors++;
            }
            break;
          case Validations.NUMBER:
            if (!Validators.isNumber(field.value)) {
              errorMessage = "Field should be numeric.";
              errors++;
            }
            break;
          case Validations.EMAIL:
            if (!Validators.isEmail(field.value)) {
              errorMessage = "Invalid Email.<br>Format should be 'name@example.com'.";
              errors++;
            }
            break;
          case Validations.LENGTH:
            if (!Validators.isValidLength(field.value, validations[i][1], validations[i][2])) {
              var fieldTypeCount = 0;
              for (var j = 0; j < validations.length; j++) {
                let validation;
                if (!(validations[j] instanceof Array)) {
                  if (validations[j] === Validations.NUMBER) {
                    fieldTypeCount++;
                    break;
                  }
                }
              }
              var fieldType = fieldTypeCount === 0 ? "characters" : "digits";
              if (validations[i][1] === validations[i][2]) {
                errorMessage = "Length should be " + validations[i][1] + " " + fieldType + ".";
              } else {
                errorMessage = "Length should be between " + validations[i][1] + " and " + validations[i][2] + " " + fieldType + ".";
              }
              errors++;
            }
            break;
          case Validations.MIN:
            if (!Validators.isLess(field.value, validations[i][1])) {
              errorMessage = "Minimum value should be " + validations[i][1] + ".";
              errors++;
            }
            break;
          case Validations.MAX:
            if (!Validators.isGreater(field.value, validations[i][1])) {
              errorMessage = "Maximum value should be " + validations[i][1] + ".";
              errors++;
            }
            break;
          case Validations.DATE:
            if (!Validators.isDate(field.value)) {
              errorMessage = "Invalid date.<br>Format should be 'DD-MM-YYYY'.";
              errors++;
            }
            break;
          case Validations.SPACES:
            if (Validators.hasSpaces(field.value)) {
              errorMessage = "Spaces are not allowed.";
              errors++;
            }
            break;
          case Validations.DOUBLE_SPACE:
            if (Validators.hasDoubleSpace(field.value)) {
              errorMessage = "Double spaces are not allowed.";
              errors++;
            }
            break;
          case Validations.ALPHA:
            if (!Validators.isAlphabet(field.value)) {
              errorMessage = "Only alphabet characters are allowed.";
              errors++;
            }
            break;
          case Validations.ALPHANUMERIC:
            if (!Validators.isAlphaNumeric(field.value)) {
              errorMessage = "Only alphabets and numbers are allowed.";
              errors++;
            }
            break;
          case Validations.ALPHASPECIALCHAR:
            if (!Validators.isAplhaSpecialchar(field.value)) {
              errorMessage = "Only alphabets and Special Char are allowed.";
              errors++;
            }
            break;
          case Validations.MONEY:
            if (!Validators.isMoney(field.value)) {
              errorMessage = "Only alphabets and Special Char are allowed.";
              errors++;
            }
            break;
          case Validations.DECIMAL:
            if (!Validators.isDecimal(field.value)) {
              errorMessage = "Field should be numeric";
              errors++;
            }
            break;
          case Validations.TIME12HOURS:
              if (!Validators.isTime(field.value)) {
                errorMessage = "Invalid Time (Format: HH:MM AM/PM)";
                errors++;
              }
              break;

        }
        if (errors > 0) {
          if (errorRequired) {
            field.setError(errorMessage);
          }
          break;
        }
      }
    }
    return errors == 0;
  }
  static disable(formFields: any) {
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        field.disable();
      }
    };
  }
  static enable(formFields: any) {
    for (var fieldName in formFields) {
      var field = formFields[fieldName];
      if (field instanceof FormField) {
        field.enable();
      }
    };
  }
}
