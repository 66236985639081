import { Component, Input, OnInit } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'radio-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class RadioField {
  @Input() form: FormField;
  @Input() radioFields: Array<any>;
  @Input() label: string;
  @Input() submitted: boolean;
  @Input() name: string;
  errorMessage: string = "";
  constructor(private translate: TranslateService) {
  }
}
