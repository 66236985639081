import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'file-upload-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class FileUploadField implements AfterViewInit {
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() form: FormField;
  @Input() label: string;
  @Input() type: string;
  @Input() tabindex: number;
  @Input() mask: any;
  errorMessage: string = "";
  public files: Array<File> = [];
  constructor(private translate: TranslateService) {
  }
  ngAfterViewInit() {
    this.form.focusObserver.asObservable().subscribe(value => {
      this.inputElement.nativeElement.focus();
    });
  }
  handleTabPress() {
    this.form.tabPressHandler.next(new Date());
  }
  handleShiftTabPress() {
    this.form.shiftTabPressHandler.next(new Date());
  }
  getMask() {
    if (typeof this.mask == "undefined") {
      return false;
    }
    return this.mask
  }


  reset() {
    this.inputElement.nativeElement.value = ""
    this.files = []
  }



  onFilesAdded() {
    this.files = []
    this.files.push(this.inputElement.nativeElement.files[0]);
  }
}
