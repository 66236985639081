import { Injectable } from "@angular/core";
import { HttpService } from "./httpService";
// import * as XLSX from 'xlsx';
@Injectable()
export class ReportGenerateService {
  constructor(private httpService: HttpService) { }

  // downloadExcel(json_array, filename: string, extra_data?: {}) {
  //   const workBook = XLSX.utils.book_new();
  //   for (var j = 0; j < json_array.length; j++) {
  //     if (Array.isArray(json_array[j])) {
  //       var data = json_array[j]
  //       if (data.length > 0 && data[0].hasOwnProperty('$$index')) {
  //         for (var i = 0; i < data.length; i++) {
  //           delete data[i]['$$index']
  //         }
  //       }
  //       var workSheet = XLSX.utils.json_to_sheet(data);
  //       if (j >= 1 && typeof extra_data != "undefined" && Object.keys(extra_data).length > 0) {
  //         for (var i = 0; i < extra_data[1].length; i++) {
  //           var range = XLSX.utils.decode_range(workSheet['!ref']);
  //           let origin = "A" + String(range.e.r + 3)
  //           workSheet = XLSX.utils.sheet_add_json(workSheet, extra_data[j][i], { origin: origin });
  //         }
  //       }
  //       // for (var C = range.s.r; C <= range.e.r; ++C) {
  //       //   var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
  //       //   console.log(address)
  //       //   if (!workSheet[address]) continue;
  //       //   workSheet[address].v = workSheet[address].v.toUpperCase();
  //       // }
  //       XLSX.utils.book_append_sheet(workBook, workSheet);
  //     } else if (typeof (json_array[j]) === "object") {
  //       var key = Object.keys(json_array[j])[0]
  //       var data = json_array[j][key]
  //       if ('$$index' in data[0]) {
  //         for (var i = 0; i < data.length; i++) {
  //           delete data[i]['$$index']
  //         }
  //       }
  //       var workSheet = XLSX.utils.json_to_sheet(data);
  //       var range = XLSX.utils.decode_range(workSheet['!ref']);
  //       for (var C = range.s.r; C <= range.e.r; ++C) {
  //         var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
  //         if (!workSheet[address]) continue;
  //         workSheet[address].v = workSheet[address].v.toUpperCase();
  //       }
  //       XLSX.utils.book_append_sheet(workBook, workSheet, key);
  //     }
  //   }
  //   XLSX.writeFile(workBook, filename + '.xlsx');
  // }
}
