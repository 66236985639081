import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit,ViewEncapsulation } from '@angular/core';
import { FormField } from '../../services/formService';
import { Validations } from '../../services/validations';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dropdown-field',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})


export class DropdownField implements AfterViewInit {
  @ViewChild('inputElement') inputElement: ElementRef;
  @Input() form: FormField;
  @Input() label: string;
  @Input() options: Array<any>;
  @Input() tabindex: number;
  errorMessage: string = "";
  dropdown: any;
  constructor(private translate: TranslateService) {
    
  }
  ngAfterViewInit(){

    this.form.focusObserver.asObservable().subscribe(value => {
      this.inputElement.nativeElement.focus();
    });
  }
  handleTabPress() {
    this.form.tabPressHandler.next(new Date());
  }
  handleShiftTabPress(){
    this.form.shiftTabPressHandler.next(new Date());
  }
}
