import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';
import { FormField } from 'app/services/formService';
import { StorageService } from './storageService';
import { ConstantsService } from './constantsService';
import { combineAll } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Injectable()

export class ContextService {
  spinnerContext: any = {};
  alertContext: any = {};
  isOpened: boolean = true;
  designation: string = "";
  role: string = "";
  user: any;
  departmentList: any;
  branches: Array<any> = [];
  departments: Array<any> = [];
  syllabusScreenData: Array<any> = [];
  screenConfiguration: any;
  prev_screen_data: any;
  admission_branches: any;
  admission: any;
  prev_screen_field_data: Array<any> = [];
  public headerButtonListener: BehaviorSubject<boolean>
  constructor(private storageService: StorageService,private translateService: TranslateService) {
    this.headerButtonListener = new BehaviorSubject(this.isOpened);
  }
  setAlertContext(alertContext) {
    this.alertContext = alertContext;
  }

  setAdmission(value){
    this.admission = value; 
  }

  getAdmission(){
    return this.admission;
  }
  getAlertContext() {
    return this.alertContext;
  }

  getAdmissionBranches() {
    return this.admission_branches
  }

  setSyllabusScreenData(ScreenData) {
    this.syllabusScreenData = ScreenData;
  }

  getSyllabusScreenData() {
    return this.syllabusScreenData;
  }
  setuser(res) {
    this.user = res;
  }

  getUser() {
    return this.user
  }
  setSpinnerContext(spinnerContext) {
    this.spinnerContext = spinnerContext;
  }
  getSpinnerContext() {
    return this.spinnerContext;
  }
  logIn(token) {
    this.storageService.writeData(ConstantsService.LOGIN_TOKEN, token);
  }
  logOut() {
    this.storageService.deleteData(ConstantsService.LOGIN_TOKEN);
    this.branches = [];
    this.departments = [];
    this.screenConfiguration = [];
    this.role = "";
  }
  isLoggedIn() {
    var token = this.storageService.readData(ConstantsService.LOGIN_TOKEN);
    return (typeof token !== "undefined" && token !== null && token !== "");
  }
  getLoginToken() {
    return this.storageService.readData(ConstantsService.LOGIN_TOKEN);
  }
  headerButtonClicked() {
    this.isOpened = !this.isOpened;
    this.headerButtonListener.next(this.isOpened)
  }
  setLoginDesignation(designation) {
    this.designation = designation;
  }
  getLoginDesignation() {
    return this.designation;
  }
  setLoginRole(role) {
    this.role = role;
  }
  getLoginRole() {
    return this.role;
  }
  setBranches(branches) {
    this.branches = branches;
  }
  getBranches() {
    return this.branches;
  }

  loadBranchConfiguration(branchList) {
    for (var i = 0; i < branchList.length; i++) {
      this.branches.push({
        "value": branchList[i].branch_code,
        "display": branchList[i].branch_name,
        "sem_required": branchList[i].semester_required,
        "higher_education": branchList[i].higher_degree_offered,
        "due_days": branchList[i].due_days,
        "address": branchList[i].address,
        "departments": []
      });
    }
  }

  get_branch_config(branch_code) {
    for (var i = 0; i < this.branches.length; i++) {
      if (branch_code == this.branches[i].value) {
        var a = [this.branches[i].higher_education, this.branches[i].sem_required]
        return a
      }
    }
  }

  loadConfiguration(departmentList, branchList) {
    this.branches = []
    for (var i = 0; i < branchList.length; i++) {
      this.branches.push({
        "value": branchList[i].branch_code,
        "display": branchList[i].branch_name,
        "sem_required": branchList[i].semester_required,
        "higher_education": branchList[i].higher_degree_offered,
        "due_days": branchList[i].due_days,
        "address": branchList[i].address,
        "departments": []
      });
    }


    for (var i = 0; i < departmentList.length; i++) {
      var branchAvailable = false;
      for (var j = 0; j < this.branches.length; j++) {
        if (departmentList[i].branch_code === this.branches[j].value) {
          branchAvailable = true;
        }
        if (branchAvailable) {
          break;
        }
      }

      if (!branchAvailable) {
        this.branches.push({
          "value": departmentList[i].branch_code,
          "display": departmentList[i].branch_name,
          // "sem_required":this.branches[j].semester_required,
          // "higher_education": this.branches[j].higher_degree_offered,
          "departments": []
        });
      }


      this.branches[j].departments.push({
        "value": departmentList[i].department_code,
        "display": departmentList[i].department_name,
        // "sem_required":this.branches[j].semester_required,
        // "higher_education": this.branches[j].higher_degree_offered,
        "branch_code": this.branches[j].value
      });
    }
  }
  validateConfiguration() {
    var isValid = false;
    if (this.branches.length > 0) {
      isValid = true;
      for (var i = 0; i < this.branches.length; i++) {
        if (this.branches[i].departments.length == 0) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
  getBranchDepartments(branch_code: FormField) {
    var departmentList = [];
    if (branch_code.isValid()) {
      for (var i = 0; i < this.branches.length; i++) {
        if (branch_code.value == this.branches[i].value) {
          departmentList = this.branches[i].departments;
          break;
        }
      }
    }
    return departmentList;
  }
  getDepartmentDesignations(department_code: FormField, designations) {
    var designationList = [];
    if (department_code.isValid()) {
      for (var i = 0; i < designations.length; i++) {
        if (designations[i].department_code == department_code.value) {
          designationList.push({
            "value": designations[i].designation_code,
            "display": designations[i].designation_desc,
            "department_code": designations[i].department_code,
            "branch_code": designations[i].branch_code
          });
        }
      }
    }
    return designationList;
  }

  getBranchAcademicYear(branch_code: FormField) {
    var academicYearList = [];
    if (branch_code.isValid()) {
      for (var i = 0; i < this.branches.length; i++) {
        if (String(branch_code.value) == String(this.branches[i].value)) {
          if(this.branches[i].sem_required){
            this.translateService.get('options.academicYearsForSemester').subscribe(
              res => {
                academicYearList = res;
              }
            );
          }else{
            this.translateService.get('options.academicYears').subscribe(
              res => {
                academicYearList = res;
              }
            );
          }
        }
      }
    }
    return academicYearList;
  }

  setdepartmentList(res) {
    this.departmentList = res.departments;
  }

  setData(res) {
    this.prev_screen_data = res
  }

  getData() {
    return this.prev_screen_data
  }

  getdepartmentList() {
    return this.departmentList;
  }
  setScreenConfiguration(screenConfiguration) {
    this.screenConfiguration = screenConfiguration;
  }

  getScreenConfiguration() {
    return this.screenConfiguration;
  }

  getAmountInPaisa(amount){
    return (Number(Number(amount).toFixed(2)) * 100)
  }

  getAmountFromPaisa(amount){
    return ((Number(amount) / 100).toFixed(2))
  }
  
  setFieldObjectData(arrayData){
    this.prev_screen_field_data = arrayData
  }

  getFieldObjectData(){
    return this.prev_screen_field_data
  }

  getAcademicYear(branch_code: FormField) {
    var academicYearList = [];
    if (branch_code.isValid()) {
      this.translateService.get('options.academicYears').subscribe(
        res => {
          academicYearList = res;
        }
      );
    }
    return academicYearList;
  }

  getBranchAdmittedYear(branch_code: FormField) {
    var admittedYearList = [];
    if (branch_code.isValid()) {
      for (var i = 0; i < this.branches.length; i++) {
        if (String(branch_code.value) == String(this.branches[i].value)) {
          if(this.branches[i].sem_required){
            this.translateService.get('options.admittedYearsForSemester').subscribe(
              res => {
                admittedYearList = res;
              }
            );
          }else{
            this.translateService.get('options.admittedYears').subscribe(
              res => {
                admittedYearList = res;
              }
            );
          }
        }
      }
    }
    return admittedYearList;
  }
}
