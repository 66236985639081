import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from '@angular/router'
import { Observable, BehaviorSubject } from 'rxjs';
import { trigger, state, animate, style, transition } from '@angular/animations';
import { ConstantsService } from './constantsService';
import { ContextService } from './contextService';
import { Location } from '@angular/common';
import { constants } from "os";

@Injectable()

export class RouterService {
  private currentRoute = new BehaviorSubject<string>("");
  constructor(private router: Router, private contextService: ContextService, private location: Location) {
  }
  
  openDefaultPage() {
    if (window.location.href.includes('silicon')){
      this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + "silicon-admission-page");
    } else if (this.contextService.isLoggedIn()) {
      this.openStaffPage();
    } else if (window.location.href.includes('parent-login')){
      this.showParentLogin();
    }
    else {
      this.openLandingPage();
    }
  }

  openSiliconAdmissionPage(){
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + "silicon-admission-page");
  }
  getCurrentRoute = function () {
    return this.currentRoute.getValue();
  }
  openLandingPage = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + "landing-page");
  }
  showRegistrationPage = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + "registration");
  }

  showAdmissionPage = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + ConstantsService.ROUTE_NON_SESSION_APPLY);
  }
  openStaffPage = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION);
  }
  showStaffEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STAFF_ENTRY);
  }

  ShowStaffDashboard = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STAFF_DASHBOARD);
  }

  ShowMarksEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS);
  }

  showStudentEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_ENTRY);
  }
  showClassroomEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CLASSROOM_ENTRY);
  }
  showBranchEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BRANCH_ENTRY);
  }

  showCourseEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ENTRY);
  }
  showChallan = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CHALLAN);
  }

  showLessonPlanning = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_LESSON_PLANNING);
  }

  showConcessionEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ENTRY);
  }

  showAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION);
  }
  showBranchModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BRANCH_ENTRY + "/" + ConstantsService.ROUTE_BRANCH_MODAL);
  }

  showConcessionModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_CONCESSION_MODAL);
  }

  showBranchModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_BRANCH_MODAL);
  }

  showDepartment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DEPARTMENT);
  }
  showDepartmentModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DEPARTMENT + "/" + ConstantsService.ROUTE_DEPARTMENT_MODAL);
  }
  showDepartmentModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_DEPARTMENT_MODAL);
  }
  showStaffModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STAFF_ENTRY + "/" + ConstantsService.ROUTE_STAFF_MODAL);
  }
  showStaffModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_STAFF_MODAL);
  }
  showStudentModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_ENTRY + "/" + ConstantsService.ROUTE_STUDENT_MODAL);
  }

  showStudentModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_STUDENT_MODAL);
  }

  showCourseEnterRegistration = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_REGISTRATION);
  }

  showDesignation = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DESIGNATION);
  }
  showDesignationModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DESIGNATION + "/" + ConstantsService.ROUTE_DESIGNATION_MODAL);
  }

  showDesignationModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_DESIGNATION_MODAL);
  }
  showCourse = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ENTRY);
  }
  showCourseModalEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE + "/" + ConstantsService.ROUTE_COURSE_MODAL);
  }

  showCourseModalAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTHORIZATION + "/" + ConstantsService.ROUTE_COURSE_MODAL);
  }
  showScreenConfiguration = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SCREEN);
  }

  showAuthStatus = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_AUTH_STATUS);
  }

  showRegulationEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_REGULATION_ENTRY);
  }

  showCommunication = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COMMUNICATION);
  }
  showCourseAllotment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ALLOTMENT);
  }
  showAttendanceConfig = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_CONFIG);
  }
  showFeeDue = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEEDUE);
  }
  showSuperAdminDashboard = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUPER_ADMIN_DASHBOARD);
  }

  showStudentDashboard = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_DASHBOARD);
  }

  showStudentRegistration = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURES_STUDENT_RESGISTRATION);
  }

  showSyllabusSreen = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SYLLABUS);
  }

  showTestType = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST_TYPE);
  }

  showCourseTestConfig = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_TEST_CONFIG);
  }

  showTestSchema = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_TEST_SCHEMA);
  }

  showResult = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_RESULT);
  }

  showMicroAnalysis = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MICRO_ANALYSIS);
  }

  showStudentResult = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_RESULT);
  }

  showCertificate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CERTIFICATE);
  }

  showNewConfigClass = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEWCONFIG_CLASSROOM);
  }

  showNewConfigStudent = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEWCONFIG_STUDENT);
  }

  showDemotion = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DEMOTION);
  }

  showTimeTable = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIMETABLE);
  }

  showCourseManagement = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_MANAGEMENT);
  }
  
  showTeacherDashboard = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEACHER_DASHBOARD);
  }
  showSubjectAttendance = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT_ATTENDANCE);
  }

  showAdmissionAuthorization = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ADMISSION_AUTHORIZATION);
  }

  showAdmissionView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION  + "/" + ConstantsService.ROUTE_ADMISSION_AUTHORIZATION + "/" + ConstantsService.ROUTE_ADMISSION_VIEW);
  }

  showCourseView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ENTRY + "/" + ConstantsService.ROUTE_COURSE_VIEW);
  }

  showCourseCreate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ENTRY + "/" + ConstantsService.ROUTE_COURSE_CREATE);
  }
  
  showCourseEdit = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_COURSE_ENTRY + "/" + ConstantsService.ROUTE_COURSE_EDIT);
  }

  showElectiveCourse = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE);
  }

  showElectiveCourseView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE_VIEW);
  }

  showElectiveCourseCreate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE_CREATE);
  }

  showElectiveCourseRegistration = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ELECTIVE_COURSE_REGISTRATION);
  }

  showMarksEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY);
  }

  showMarksEntryView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_MARKS_ENTRY_VIEW);
  }

  showMarksEntryAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_MARKS_ENTRY_ADD);
  }

  showMarksEntryModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_MARKS_ENTRY_MODIFY);
  }

  showBulkMarksEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY);
  }

  showBulkMarksEntryView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY_VIEW);
  }

  showBulkMarksEntryAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY_ADD);
  }

  showBulkMarksEntryModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY+ "/" + ConstantsService.ROUTE_BULK_MARKS_ENTRY_MODIFY);
  }

  showAssessmentMarksEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY);
  }

  showAssessmentMarksView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY_VIEW);
  }

  showAssessmentMarksAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY_ADD);
  }

  showAssessmentMarksModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY + "/" + ConstantsService.ROUTE_ASSESSMENT_MARKS_ENTRY_MODIFY);
  }

  showResultView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_RESULT_VIEW);
  }

  showFeeType = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE);
  }

  showFeeTypeView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE + "/" + ConstantsService.ROUTE_FEE_TYPE_VIEW);
  }

  showFeeTypeAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE + "/" + ConstantsService.ROUTE_FEE_TYPE_ADD);
  }

  showFeeAssign = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_ASSIGN);
  }
  
  showFeeAssingment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_ASSINGMENT);
  }

  showConcessionView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_CONCESSION_VIEW);
  }

  showConcessionCreate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_CONCESSION_CREATE);
  }

  showConcessionModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_CONCESSION_MODIFY);
  }

  showConcessionAssignment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONCESSION_ASSIGNMENT);
  }
  
  showAdmissionList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION  + "/" + ConstantsService.ROUTE_ADMISSION_AUTHORIZATION + "/" + ConstantsService.ROUTE_ADMISSIONAUTHORIZATION_LIST);
  }

  showCustomizeFiledEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION  + "/" + ConstantsService.ROUTE_CUSTOMIZE_FIELD_ENTRY);
  }

  showFeeReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_REPORT);
  }

  showStudentMeritUpdate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_MERIT_UPDATE);
  }

  showStudentMeritUpdateList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_MERIT_UPDATE + "/" + ConstantsService.ROUTE_STUDENT_MERIT_UPDATE_LIST);
  }

  showStudentMeritUpdateAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_MERIT_UPDATE + "/" + ConstantsService.ROUTE_STUDENT_MERIT_UPDATE_ADD);
  }

  showFeePaymentUpload = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_PAYMENT_UPLOAD);
  }

  showFeeTypeBreakup = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE_BREAKUP_ENTRY);
  }

  showFeeTypeBreakupList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE_BREAKUP_ENTRY + "/" + ConstantsService.ROUTE_FEE_TYPE_BREAKUP_LIST);
  }

  showFeeTypeBreakupAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_TYPE_BREAKUP_ENTRY + "/" + ConstantsService.ROUTE_FEE_TYPE_BREAKUP_ADD);
  }

  showClassWiseReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CLASS_WISE_REPORT);
  }
  
  showIntraAdmission = function (){
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/"+ ConstantsService.ROUTE_INTRA_TRUST_ADMISSION);
  }

  showSiliconAdmissionPage = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + ConstantsService.ROUTE_SILICON_ADMISSION);
  }

  showFeeStructure = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_STRUCTURE);
  }

  showFeeStructureList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_STRUCTURE + "/" + ConstantsService.ROUTE_FEE_STRUCTURE_LIST);
  }

  showFeeStructureAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_STRUCTURE + "/" + ConstantsService.ROUTE_FEE_STRUCTURE_ADD);
  }

  showFeeStructureView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_STRUCTURE + "/" + ConstantsService.ROUTE_FEE_STRUCTURE_VIEW);
  }

  showFeeService = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_SERVICE);
  }

  showFeeServiceList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_SERVICE + "/" + ConstantsService.ROUTE_FEE_SERVICE_LIST);
  }

  showFeeServiceAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEE_SERVICE + "/" + ConstantsService.ROUTE_FEE_SERVICE_ADD);
  }

  showNewConcessionEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_CONCESSION_ENTRY);
  }

  showNewConcessionView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_NEW_CONCESSION_VIEW);
  }

  showNewConcessionCreate = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_NEW_CONCESSION_CREATE);
  }

  showNewConcessionModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_CONCESSION_ENTRY + "/" + ConstantsService.ROUTE_NEW_CONCESSION_MODIFY);
  }

  showFeePayment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_FEE_PAYMENT);
  }

  showFeeAssigned = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_FEE_ASSIGNED);
  }

  showNewConcessionAssignment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_CONCESSION_ASSIGNMENT);
  }

  showNewFeeReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_NEW_FEE_REPORT);
  }

  showSubject = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT);
  }

  showSubjectAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT + "/" + ConstantsService.ROUTE_SUBJECT_ADD);
  }

  showSubjectList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT + "/" + ConstantsService.ROUTE_SUBJECT_LIST);
  }

  showSubjectOffer = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT_OFFER);
  }

  showSubjectOfferList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT_OFFER + "/" + ConstantsService.ROUTE_SUBJECT_OFFER_LIST);
  }

  showSubjectOfferAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_SUBJECT_OFFER + "/" + ConstantsService.ROUTE_SUBJECT_OFFER_ADD);
  }

  showAttendanceRegister = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER);
  }

  showAttendanceRegisterList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER_LIST);
  }

  showAttendanceRegisterAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER_ADD);
  }

  showAttendanceRegisterView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER + "/" + ConstantsService.ROUTE_ATTENDANCE_REGISTER_VIEW);
  }

  showTeacherAssignment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEACHER_ASSIGNMENT);
  }

  showTeacherAssignmentList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEACHER_ASSIGNMENT + "/" + ConstantsService.ROUTE_TEACHER_ASSIGNMENT_LIST);
  }

  showTeacherAssignmentAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEACHER_ASSIGNMENT + "/" + ConstantsService.ROUTE_TEACHER_ASSIGNMENT_ADD);
  }

  showGroup = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_GROUP );
  }

  showGroupAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_GROUP + "/" + ConstantsService.ROUTE_GROUP_ADD );
  }
  showGroupList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_GROUP + "/" + ConstantsService.ROUTE_GROUP_LIST );
  }

  showAttendance = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE );
  }
  showReceiptReprint = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_RECEIPT_REPRINT );
  }
  showAttendanceReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_REPORT );
  }
  showStudentPhotoUpload = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_PHOTO_UPLOAD );
  }
  showTest = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST);
  }
  showTestAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST + "/" + ConstantsService.ROUTE_TEST_ADD);
  }
  showTestList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST+ "/" + ConstantsService.ROUTE_TEST_LIST);
  }
  showTestAllotment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT );
  }
  showTestAllotmentList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT_LIST);
  }

  showTestAllotmentAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT_ADD);
  }
  showTestAllotmentView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT + "/" + ConstantsService.ROUTE_TEST_ALLOTMENT_VIEW);
  }
  showMarksEntryNew = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW );
  }
  showMarksEntryNewList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW_LIST);
  }
  showMarksEntryNewAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW_ADD);
  }
  showMarksEntryNewEdit = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW + "/" + ConstantsService.ROUTE_MARKS_ENTRY_NEW_EDIT);
  }
  showTimeTableNew = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIME_TABLE_NEW);
  }
  showTimeTableConfig = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG);
  }
  showTimeTableConfigAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG+ "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG_ADD );
  }
  showTimeTableConfigEdit = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG+ "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG_EDIT );
  }
  showTimeTableConfigView = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION  + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG_VIEW );
  }
  showTimeTableConfigList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION  + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG + "/" + ConstantsService.ROUTE_TIME_TABLE_CONFIG_LIST );
  }
  showForgotPassword = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + ConstantsService.ROUTE_FORGET_PASSWORD );
  }
  ShowMarksEntryAllSubject = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT );
  }
  showMarksEntryAllSubjectList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT_LIST);
  }
  ShowMarksEntryAllSubjectAdd = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT_ADD);
  }
  ShowMarksEntryAllSubjectEdit = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT + "/" + ConstantsService.ROUTE_MARKS_ENTRY_ALL_SUBJECT_EDIT);
  }
  showAttendanceAllSubject = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_ALL_SUBJECT );
  }
  showBUInternalReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_BU_INTERNAL_REPORT );
  }
  showAttendanceConsolidateReport = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ATTENDANCE_CONSOLIDATE_REPORT );
  }
  showStudentTcList = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_TC_LIST);
  }
  showStudentTcModal = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_TC_LIST + "/" + ConstantsService.ROUTE_STUDENT_MODAL);
  }
  showCieMarkModify = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CIE_MARK_MODIFY );
  }
  showStudentsDiscontinued = function (){
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_DISCONTINUED_STUDENTS)
  }
  showParentLogin = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + ConstantsService.ROUTE_PARENT_LOGIN );
  }
  showApprovePartialPayment = function (){
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.APPROVE_PARTIAL_PAYMENT)
  }

  showOnlinePayment = function () {
    this.doNavigation(ConstantsService.ROUTE_NON_SESSION + "/" + ConstantsService.ROUTE_ONLINE_PAYMENT );
  }

  showPreviousAcademicYearPayment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_PREVIOUS_ACADEMIC_YEAR_PAYMENT );
  }

  showPreviousAcademicYearPaymentNewFlow = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_PREVIOUS_ACADEMIC_YEAR_PAYMENT_NEW_FLOW );
  }
  
  showStudentFeedBackOnTeacher = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_FEEDBACK_ON_TEACHERS );
  }

  showFacultyFeedBackOnCurriculum = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FACULTY_FEEDBACK_ON_CURRICULUM );
  }

  showAlumniFeedBackOnCurriculum = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_ALUMNI_FEEDBACK_ON_CURRICULUM );
  }

  showStudentFeedBackOnSyllabus = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_STUDENT_FEEDBACK_ON_SYLLABUS );
  }

  showFeedBackDownload = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_FEEDBACK_DOWNLOAD );
  }

  showTimeTableUpload = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_TIME_TABLE_UPLOAD );
  }

  showConsolidatedFeeDetails = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_CONSOLIDATED_FEE_DETAILS );
  }

  showAppraiseeEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_APPRAISEE_ENTRY );
  }

  showAppraiserEntry = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_APPRAISER_ENTRY );
  }

  showOtherFeePayment = function () {
    this.doNavigation(ConstantsService.ROUTE_SESSION + "/" + ConstantsService.ROUTE_OTHER_FEE_PAYMENT );
  }

  goBack = function () {
    this.location.back();
  }


  private doNavigation = function (route) {
    this.currentRoute.next(route)
    this.router.navigate([route]);
  }
  public routeChangeListener = this.currentRoute.asObservable();
};

// export function RouterAnimate(){
//   return fadeAnimation();
// }
// const slideToLeft = function() {
//   return trigger('routerTransition', [
//     state('void', style({ position: 'absolute', width: '100%' })),
//     state('*', style({ position: 'absolute', width: '100%' })),
//     transition(':enter', [  // before 2.1: transition('void => *', [
//       style({ transform: 'translateX(100%)'}),
//       animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
//     ]),
//     transition(':leave', [  // before 2.1: transition('* => void', [
//       style({ transform: 'translateX(0%)' }),
//       animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
//     ])
//   ]);
// }
// const fadeAnimation = function() {
//   return trigger('routerTransition', [
//     state('void', style({ position: 'absolute', width: '100%' })),
//     state('*', style({ position: 'absolute', width: '100%' })),
//     transition(':enter', [  // before 2.1: transition('void => *', [
//       style({ opacity: '0' }),
//       animate('1s ease-in-out', style({ opacity: '1' }))
//     ]),
//     transition(':leave', [  // before 2.1: transition('* => void', [
//       style({ opacity: '1' }),
//       animate('1s ease-in-out', style({ opacity: '0' }))
//     ])
//   ]);
// }
