import { Component, Input,OnChanges, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormField } from 'app/services/formService';

import * as _ from "lodash";

@Component({
  selector: 'multi-select-field',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})

export class MultiSelect  implements OnChanges  {
  @Input() label: string;
  @Input() options: Array<any>;
  selectedVal: Array<any> = [];
  checkForm: FormField = new FormField(false);
  
  @Input() form: FormField;
  @Output() selected: Array<any>  = [];
  @Output() valueEmitter = new EventEmitter<any> ();
  labelShow: Boolean = false
  @Input() showTooltip: Boolean = false
  selectedMasterCheckBox: Boolean = false
  constructor() {
    this.checkForm.value = false
    this.options = [];
    this.valueChangeListner()
  }

  valueChangeListner(){
    this.checkForm.valueChangeListener().subscribe(values =>{
      if(values.oldValue != values.newValue || values.oldValue == values.newValue){
        this.setValues(values.newValue)
      }
    })
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if('label' in changes){
      this.label = changes["label"]["currentValue"]
      if(this.label.length > 0){
        this.labelShow = true
      }else{
        this.labelShow = false
      }
    }

    this.options = changes["options"]["currentValue"]
    this.selectedVal = []
    this.setSelectedValue();
  }

  private setSelectedValue(){
    if(typeof this.options != "undefined"){
      this.options.forEach( res=> {
        if(res.selected) {
          this.selectedVal.push(String(res.value))
        }   
      })
      this.onValChange(_.cloneDeep(this.selectedVal))
    }
  }

  public onValChange(val) {
    this.selectedMasterCheckBox = false
    this.form.reset()
    this.selectedVal = _.cloneDeep(val)
    var options = _.cloneDeep(this.options)
    for( var i = 0 ; i < options.length ; i ++){
      if( this.selectedVal.indexOf(String(options[i].value))  != -1 )
      {
        options[i].selected = true
      }else {
        options[i].selected = false
      }
    }
    if(options.some(x => x.selected == false)){
      this.checkForm.value = false
      this.checkForm.valueChanged();
    }else{
      this.checkForm.value = true
      this.checkForm.valueChanged();
    }
  }

  setValues(value){
    if(this.selectedMasterCheckBox){
      this.selectedVal = []
      for( var i = 0 ; i < this.options.length ; i ++){
        if(value){
          this.selectedVal.push(String(this.options[i].value))
          this.options[i].selected = true
        }else{
          this.options[i].selected = false
        }
      }
      this.valueEmitter.emit(this.options);
    }else{
      this.selectedMasterCheckBox = true
      for( var i = 0 ; i < this.options.length ; i ++){
        if( this.selectedVal.indexOf(String(this.options[i].value))  != -1 )
        {
          this.options[i].selected = true
        }else {
          this.options[i].selected = false
        }
      }
      this.valueEmitter.emit(this.options);
    }
    
  }

  getToolTipContent(i){
    if(this.showTooltip){
      return this.options[i].tooltipcontent
    }
    return ''
  }
}