import { Component, Input, ElementRef, ViewChild , OnInit, TemplateRef, OnChanges, HostListener, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import {BehaviorSubject, ReplaySubject, Observable} from 'rxjs';
import { FormField } from 'app/services/formService';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { Validators as V } from "app/services/validations";

import * as _ from "lodash";
declare var $: any;


@Component({
  selector: 'mat-table-field',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})




export class MatTableComponent implements OnInit {
 
  @Input() tableGridData: any = {};
  displayedColumns: string[] = ['id'];
 
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    // Assign the data to the data source for the table to render
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableGridData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}